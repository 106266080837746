import { ResetPasswordLinRequest } from './../models/request/ResetPasswordLinkRequest';
import { ResetPasswordVerificarResponse } from './../models/response/ResetPasswordVerificarResponse';
import { PersonaRegistroRequest } from './../models/request/PersonaRegistroRequest';
import { ResetPasswordRequest } from './../models/request/ResetPasswordRequest';
import { PersonaValidaResponse } from './../models/response/PersonaValidaResponse';
import { StatusResponse } from './../models/StatusResponse';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PersonaValidaRequest } from '../models/PersonaValidaRequest';
import { Observable } from 'rxjs';
import { ResetPasswordVerificarRequest } from '../models/request/ResetPasswordVerificarRequest';
import { ResetPasswordValidarIdentidadResponse } from '../models/response/ResetPasswordValidarIdentidadResponse';
import { AccesoApp } from '../models/response/AccesoApp';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  baseUrl = environment.baseUrl;
  validarIdentidadUri = '/usuario/validar';
  resetPasswordUri = '/usuario/resetpassword';
  registrarPersonaUri = '/usuario/postulante';
  verifyResetPassword = '/usuario/resetpassword/verificar';
  generarLinkResetPasswordUri = '/usuario/resetpassword/enlace';
  resetPasswordValidarUri = '/usuario/resetpassword/validar';
  resetPasswordConfirmarUri = '/usuario/resetpassword/confirmar';
  consultaDatosUri = '/usuario/consulta?ssid=';
  actualizarPerfilUri = '/usuario/actualizaPerfil';
  validarUsuarioConsultorURL = '/usuario/validaconsultor';

  constructor(private http: HttpClient) { }

  postUsuarioValidarIdentidad(request: PersonaValidaRequest): Observable<StatusResponse<PersonaValidaResponse>>{
    return this.http.post<StatusResponse<PersonaValidaResponse>>(this.baseUrl + this.validarIdentidadUri, request);
  }
  getVerifyResetPassword(request: ResetPasswordVerificarRequest): Observable<StatusResponse<ResetPasswordVerificarResponse>>{
    // tslint:disable-next-line: max-line-length
    return this.http.get<StatusResponse<ResetPasswordVerificarResponse>>(this.baseUrl + this.verifyResetPassword + '?id-documento=' + request.sIdDocumento.toString() + '&nu-documento=' + request.sNumeroDocumento.toString());
  }
  postResetPassword(request: ResetPasswordRequest): Observable<StatusResponse<string>>{
    return this.http.post<StatusResponse<string>>(this.baseUrl + this.resetPasswordUri, request);
  }
  postGenerarLinkResetPassword(request: ResetPasswordLinRequest, sToken: any): Observable<StatusResponse<any>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sToken.token
    });
    return this.http.post<StatusResponse<any>>(this.baseUrl + this.generarLinkResetPasswordUri, request, { headers: reqHeader });
  }
  postResetPasswordValidar(request: PersonaValidaRequest, sToken: any): Observable<StatusResponse<ResetPasswordValidarIdentidadResponse>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sToken
    });
    // tslint:disable-next-line: max-line-length
    return this.http.post<StatusResponse<ResetPasswordValidarIdentidadResponse>>(this.baseUrl + this.resetPasswordValidarUri, request, { headers: reqHeader });
  }
  postResetPasswordConfirmar(request: any, sToken: string): Observable<StatusResponse<string>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sToken
    });
    return this.http.post<StatusResponse<string>>(this.baseUrl + this.resetPasswordConfirmarUri, request, { headers: reqHeader });
  }
  postRegistrarPersona(request: PersonaRegistroRequest): Observable<StatusResponse<any>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sessionStorage.getItem('tokenIdentidad')
    });
    return this.http.post<StatusResponse<any>>(this.baseUrl + this.registrarPersonaUri, request, { headers: reqHeader });
  }

  getConsultaDatos(ssid: string): Observable<StatusResponse<any>> {
    return this.http.get<StatusResponse<any>>(this.baseUrl + this.consultaDatosUri + ssid);
  }

  postActualizarPerfil(request: any): Observable<StatusResponse<any>> {
    return this.http.post<StatusResponse<any>>(this.baseUrl + this.actualizarPerfilUri, request);
  }

  getValidarUsuarioConsultor(sSid: any): Observable<StatusResponse<AccesoApp>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sSid
    });
    return this.http.get<StatusResponse<AccesoApp>>(this.baseUrl + this.validarUsuarioConsultorURL,{ headers: reqHeader });
  }

}
