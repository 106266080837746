import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trabaja-extranjero',
  templateUrl: './trabaja-extranjero.component.html',
  styleUrls: ['./trabaja-extranjero.component.scss']
})
export class TrabajaExtranjeroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  fnclickBolsaTrabajo(): void{
    window.location.href = environment.baseWcc;
  }

  fnclickCul(): void {
    window.location.href = environment.baseCUL + 'irIndex.html';
  }

  fnclickCapacitate(): void {
    window.location.href = 'https://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }

  fngoTrabajExtranjero(): void {
    window.location.href = 'https://www.quebecentete.com/es/trabajar/misiones-de-reclutamiento/jqameriquelatine-2022/';
  }

}
