import { IdentidadEmpresaValidaRequest } from './../models/request/IdentidadEmpresaValidaRequest';
import { EmpresaRegistroRequest } from './../models/request/EmpresaRegistroRequest';
import { EmpresaValidaResponse } from './../models/response/EmpresaValidaResponse';
import { StatusResponse } from './../models/StatusResponse';
import { Observable } from 'rxjs';
import { EmpresaValidaRequest } from './../models/request/EmpresaValidaRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  baseUrl = environment.baseUrl;
  validarEmpresaUri = '/empresa/validar';
  validarIdentidadEmpresaUri = '/empresa/validar-nl';
  registrarEmpresaUri = '/empresa';
  registrarEmpresaNLUri = '/empresa/nl';

  constructor(private http: HttpClient) { }

  postValidarEmpresa(request: EmpresaValidaRequest): Observable<StatusResponse<EmpresaValidaResponse>> {
    return this.http.post<StatusResponse<EmpresaValidaResponse>>(this.baseUrl + this.validarEmpresaUri, request);
  }
  postValidarIdentidad_Empresa(request: IdentidadEmpresaValidaRequest): Observable<StatusResponse<EmpresaValidaResponse>> {
    return this.http.post<StatusResponse<EmpresaValidaResponse>>(this.baseUrl + this.validarIdentidadEmpresaUri, request);
  }
  postRegistrarEmpresa(request: EmpresaRegistroRequest): Observable<StatusResponse<any>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sessionStorage.getItem('tokenEmpresa')
    });
    return this.http.post<StatusResponse<any>>(this.baseUrl + this.registrarEmpresaUri, request, { headers: reqHeader });
  }
  postRegistrarEmpresaNL(request: any): Observable<StatusResponse<any>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Validate-Identity': sessionStorage.getItem('tokenEmpresa')
    });
    return this.http.post<StatusResponse<any>>(this.baseUrl + this.registrarEmpresaNLUri, request, { headers: reqHeader });
  }
}
