import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  construct() {}

  // tslint:disable-next-line: typedef
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      timeout(50000),
      catchError(error => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          if(error.error == undefined){
            console.log(error);
            if(error.name == 'TimeoutError'){
              errorMessage = 'El tiempo de espera se agotó, por favor vuelva a intentarlo.';
            }else{
              errorMessage = `Server-side error: Status: ${error}.`;
            }
          }else{
            if (error.error.httpStatusCode === 500){
              errorMessage = `Server-side error: Status: ${error.error.code} ${error.error.description}.`;
            }else{
              errorMessage = `Server-side error: Status: ${error.status} ${error.message}.`;
            }
          }
        }

        return throwError(errorMessage);
      })
    );
  }
}
