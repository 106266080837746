<ng-template #login let-modal>
  <div class="modal-body">
    <a href="#" style="border: none" target="_blank">
      <img src="./assets/images/banner/flyer37.png" style="width: 100%;" />
    </a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
<h3 class="accesibility-theme accesibility title-nuevo-usuario">Bienvenido/a a Empleos Perú</h3>

<div class="text-help">
    <img src="./assets/images/banner/alerta2.jpg" style="width: 160%; margin-top: 40px;"/>
  </div>

<div class="login-MTPE">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <div class="form-group">
            <label for="cboTipoDocumento" class="accesibility texto-bold label-formulario">Tipo Documento<span
                    class="accesibility-theme asterik-required">*</span></label>
            <select id="cboTipoDocumento" class="form-control" [(ngModel)]="loginModel.nIdTipoDocumentoIdentidad"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let tipoDocumento of tiposDocumento" [value]="+(tipoDocumento.value)">
                    {{tipoDocumento.descripcion}}</option>
            </select>
        </div>
        <div class="form-group">
            <label class="accesibility" for="sNumeroDocumentoIdentidad">Documento de Identidad Registrado <span
                    class="accesibility-theme asterik-required">*</span></label>
            <input type="text" class="accesibility form-control" id="sNumeroDocumentoIdentidad"
                name="sNumeroDocumentoIdentidad" required pattern="[A-Za-z0-9]{4,9}" [disabled]="isLoading"
                maxlength="25" [(ngModel)]="loginModel.sNumeroDocumentoIdentidad"
                placeholder="Ingrese su número documento" #sNumeroDocumentoIdentidad="ngModel">
            <div *ngIf="!sNumeroDocumentoIdentidad.valid && sNumeroDocumentoIdentidad.touched && !isLoading"
                class="accesibility alert alert-danger alert-danger-MTPE">
                El número documento es obligatorio, alfanumérico mínimo 4 y maximo de 9 caracteres.
            </div>
        </div>
        <div class="form-group">
            <label class="accesibility" for="password">Contraseña <span
                    class="accesibility-theme asterik-required">*</span></label>
            <input type="password" name="password" id="password" minlength="6" class="accesibility form-control"
                required [disabled]="isLoading" [(ngModel)]="loginModel.password" #password="ngModel">
            <div *ngIf="!password.valid && password.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
                La contraseña es requerida, debe ser mínimo de 6 caracteres.
            </div>
        </div>
        <!--<br>
        <div>
            <p style="font-size: 14px;">Marque en el recuadro</p>
            <re-captcha [(ngModel)]="captcha" name="captcha" required (resolved)="resolved($event)" siteKey="6LciLsAZAAAAAAQWKuNfxpKXTwDc-rQurxOujvWf"></re-captcha>
        </div>
        <br/>-->
        <div class="form-group">
            <a class="accesibility-theme accesibility" [routerLink]="['/restablece-contrasenia']"
                style="font-size: 14px; font-weight: bold;">¿Olvidaste tu contraseña?</a>
        </div>
        <button type="submit" class="accesibility-theme accesibility col-lg-12 btn btn-success"
            [disabled]="loginForm.form.invalid || isLoading" style="background-color: #219653;">
            Acceder
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
        </button>
        <div style="text-align: center;">
            <h5 class="accesibility-theme accesibility"
                style="font-size: 15px; text-align: center;margin-top: 20px;font-weight: 600;">¿No tienes una cuenta?
            </h5>
            <a class="accesibility-theme accesibility" [routerLink]="['/nuevo-usuario']"
                style="font-size: 14px; font-weight: bold;text-align: center;">Crear una cuenta</a>
        </div>
        <div>
            <h5 class="accesibility-theme accesibility" style="font-size: 15px;margin-top: 20px;text-align: center;">
                <span class="asterik-required">*</span> Campos obligatorios</h5>
        </div>
    </form>
</div>
<footer class="footer-principal footer-principal-verify">
    <!--<div class="footer-callcenter">
        <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
    </div>-->
    <div class="container" style="margin-bottom: 10px;">
        <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
                <img class="accesibility-theme" style="width: 50%;"
                    src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
                    alt="Logo Ministerio de Trabajo y Promoción del Empleo">
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
                <img class="accesibility-theme" style="width: 50%;"
                    src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
                    alt="Logo Ministerio de Trabajo y Promoción del Empleo">
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
                <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
                    src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
                    alt="Logo Ministerio de Trabajo y Promoción del Empleo">
            </div>
        </div>
        <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
    </div>
</footer>
