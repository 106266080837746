import { environment } from './../../../../environments/environment';
import { LoginResponse } from './../../../models/response/LoginResponse';
import { SessionService } from '../../../services/session.service';
import { HelperService } from '../../../services/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { LoginRequest } from '../../../models/request/LoginRequest';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('login') private contenido;
  loginModel: LoginRequest =
    {
      sNumeroDocumentoIdentidad: '',
      password: '',
      nIdTipoDocumentoIdentidad: 10080399,
      params: {
        clientId: environment.clientId,
        redirectUri: environment.basePortal,
        responseType: '',
        scope: '',
        state: '',
        nonce: '',
        uiLocales: '',
        consultorReq: false
      }
    };
  tiposDocumento: any[];
  loginresponse: LoginResponse;
  mensajeError: string;
  isError = false;
  isLoading = false;
  captcha: any;
  isNullUndefinesOrEmpty(value: any): boolean {
    return value == null || value === undefined || value === '';
  }
  resolved(captchaResponse: string): void {
    console.log(captchaResponse);
  }
  // tslint:disable-next-line: max-line-length
  constructor(private modal: NgbModal, private loginService: LoginService, private routes: Router, private helper: HelperService, private currentRoute: ActivatedRoute, private sessionService: SessionService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    setTimeout(() => {
      //this.modal.open(this.contenido, { size: 'lg'});
      //this.modal.open(this.contenido2, { size: 'lg'});
    }, 1000);
    this.tiposDocumento = [
      {
        value: 0,
        descripcion: '-- Seleccione un tipo de documento --'
      },
      {
        value: 10080399,
        descripcion: 'DNI'
      },
      {
        value: 10080305,
        descripcion: 'CE'
      },
      {
        value: 10080306,
        descripcion: 'PTP'
      },
      {
        value: 10080307,
        descripcion: 'CPP'
      }
    ];
    if (!this.isNullUndefinesOrEmpty(this.currentRoute.snapshot.queryParamMap.get('client_id'))) {
      this.loginModel.params.responseType = this.currentRoute.snapshot.queryParamMap.get('response_type');
      this.loginModel.params.redirectUri = this.currentRoute.snapshot.queryParamMap.get('redirect_uri');
      this.loginModel.params.clientId = this.currentRoute.snapshot.queryParamMap.get('client_id');
      this.loginModel.params.scope = this.currentRoute.snapshot.queryParamMap.get('scope');
      this.loginModel.params.state = this.currentRoute.snapshot.queryParamMap.get('state');
      this.loginModel.params.nonce = this.currentRoute.snapshot.queryParamMap.get('nonce');
      this.loginModel.params.uiLocales = this.currentRoute.snapshot.queryParamMap.get('ui_locales');
      this.loginModel.params.consultorReq = this.currentRoute.snapshot.queryParamMap.get('consultor_req');
    }

    const useConsultor = this.currentRoute.snapshot.queryParamMap.get('consultor_req');
    const userIVR = this.currentRoute.snapshot.queryParamMap.get('ope');
    const crcIVR = this.currentRoute.snapshot.queryParamMap.get('crc');
    const sessionIdToken = this.currentRoute.snapshot.queryParamMap.get('user');

    if (!this.isNullUndefinesOrEmpty(useConsultor)) {
      if (useConsultor) {
        sessionStorage.setItem('consultor', useConsultor);
        console.log('Consultor:' + useConsultor.toString());
      }
    }

    if (!this.isNullUndefinesOrEmpty(sessionIdToken)) {
      if (!this.isNullUndefinesOrEmpty(userIVR) && !this.isNullUndefinesOrEmpty(crcIVR)) {
        const request = {
          sessionId: sessionIdToken,
          userIvr: userIVR,
          crc: crcIVR
        };
        this.sessionService.postVerifySessionId(request).subscribe(
          response => {
            if (response.data !== null) {
              this.helper.UserName.next(response.data.datosPostulante.name);
              this.helper.isAuthenticated.next(true);
              this.sessionService.setCookie('SID', sessionIdToken);
              this.sessionService.setCookie('SID', sessionIdToken, '/CertificadoUnicoLaboral');
              this.sessionService.setSessionLocalStorage('SID', sessionIdToken);
              this.routes.navigate(['']);
            } else {
              let session = this.sessionService.getCookie('SID');
              if (!this.isNullUndefinesOrEmpty(session)) {
                this.routes.navigate(['']);
              }
              let sessionLocal = this.sessionService.getSessionLocalSotrage('SID');
              if (!this.isNullUndefinesOrEmpty(sessionLocal)) {
                this.routes.navigate(['']);
              }
            }
          }
        );
      }
    } else {
      const cookie = this.sessionService.getCookie('SID');
      if (!this.isNullUndefinesOrEmpty(cookie)) {
        if (this.loginModel.params.clientId === 'portal-mtpe') {
          window.location.href = this.loginModel.params.redirectUri;
        } else {
          // tslint:disable-next-line: max-line-length
          this.sessionService.getOperationSessionOauthRedirect(cookie, 'check', this.loginModel.params.nonce, this.loginModel.params.clientId).subscribe(
            response => {
              if (response.status === 'valid') {
                // tslint:disable-next-line: max-line-length
                window.location.href = this.loginModel.params.redirectUri + '?code=' + response.code + '&nonce=' + this.loginModel.params.nonce + '&state=' + this.loginModel.params.state;
              }
            }
          );
        }
      }
    }

  }
  onSubmit(): void {
    this.isLoading = true;
    this.loginService.postLogin(this.loginModel).subscribe(
      response => {
        if (response.succes) {
          this.isLoading = false;
          this.isError = false;
          this.loginresponse = response.data;
          if (this.loginresponse.bRequiereActualizacion) {
            Swal.fire(
              {
                title: 'Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            ).then(() => {
              window.location.href = environment.basePortal + 'nuevo-usuario';
            });
          } else {
            this.sessionService.setCookie('SID', this.loginresponse.sSid.toString());
            this.sessionService.setCookie('SID', this.loginresponse.sSid.toString(), '/CertificadoUnicoLaboral');
            Swal.fire(
              {
                title: '¡Bienvenido!',
                text: 'Inicio de sesión exitoso.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            ).then(() => {
              localStorage.setItem('userName', this.loginresponse.sFullName);
              this.helper.UserName.next(this.loginresponse.sFullName);
              this.helper.isAuthenticated.next(this.loginresponse.bLogeado);
              sessionStorage.setItem(
                'esExtranjero',
                this.loginresponse.bExtranjero ? '1' : '0'
              );
              this.sessionService.setCookie(
                'SID',
                this.loginresponse.sSid.toString(),
                '/',
                environment.sidBaseUrl
              );
              if (this.loginModel.params.clientId === 'portal-mtpe') {
                window.location.href = environment.basePortal;
              } else {
                window.location.href = this.loginresponse.urlRedirect;
              }
              this.usuarioService.getValidarUsuarioConsultor(this.loginresponse.sSid).subscribe(
                response => {
                  if (response.succes) {
                    const accesosUser = {
                      autorizaBolsa: response.data.autorizaBolsa,
                      autorizaCrm: response.data.autorizaCrm,
                      autorizaRenupse: response.data.autorizaRenupse,
                      autorizaPj: response.data.autorizaPj,
                    }
                    localStorage.setItem('aU', JSON.stringify(accesosUser));
                  }
                }, error => {
                  console.log(error);
                }
              );
            });
          }
        } else {
          this.isLoading = false;
          this.isError = false;
          Swal.fire(
            {
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
      },
      error => {
        Swal.fire({
          title: '¡Error!',
          text: error,
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.value) {
            console.log(error);
            this.mensajeError = error;
            this.isLoading = false;
            this.isError = true;
          }
        });
      });
  }
}
