import { environment } from './../../../../environments/environment';
import { SessionService } from './../../../services/session.service';
import { LoginService } from './../../../services/login.service';
import { HelperService } from './../../../services/helper.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  isAuthenticated = false;
  isAuthenticatedEmpresa = false;
  UserName = '';
  @ViewChild('modalCul') private modalCul;
  // tslint:disable-next-line: typedef
  isNullUndefinesOrEmpty(value: string): boolean {
    return value == null || value === undefined || value === '';
  }
  // tslint:disable-next-line: max-line-length
  constructor(private modal: NgbModal, private helper: HelperService, private loginService: LoginService, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.helper.isAuthenticated.subscribe(res => {
      this.isAuthenticated = res;
    });
    this.helper.isAuthenticatedEmpresa.subscribe(res => {
      this.isAuthenticatedEmpresa = res;
    });
    this.helper.UserName.subscribe(res => {
      this.UserName = res;
    });
    this.verificarSession();
    setInterval(() => {
      this.verificarSession();
    }, 120000);

  }
  verificarSession(): void {
    /*VERIFICAR SESION EMPRESA*/
    const cookieE = this.sessionService.getCookie('SIDe');
    if (!this.isNullUndefinesOrEmpty(cookieE)) {
      this.isAuthenticatedEmpresa = true;
    } else {
      this.isAuthenticatedEmpresa = false;
    }

    /*VERIFICAR SESION CIUDADANO*/
    let session = this.sessionService.getCookie('SID');
    const sessionLocal = this.sessionService.getSessionLocalSotrage('SID');

    if (this.isNullUndefinesOrEmpty(session)) {
      if (!this.isNullUndefinesOrEmpty(sessionLocal)) {
        session = sessionLocal;
        this.sessionService.setCookie('SID', sessionLocal);
      } else {
        this.isAuthenticated = false;
        localStorage.setItem('userName', '');
        this.sessionService.setCookie('SID', '');
        this.sessionService.setSessionLocalStorage('SID', '');
        localStorage.setItem('uO', '');
        localStorage.setItem('aU', '');
        return;
      }
    }
    this.sessionService.getOperationSessionOauth(session, 'check').subscribe(
      response => {
        if (response.status === 'expired') {
          this.isAuthenticated = false;
          localStorage.setItem('userName', '');
          this.sessionService.setCookie('SID', '');
          this.sessionService.setSessionLocalStorage('SID', '');
          localStorage.setItem('uO', '');
          localStorage.setItem('aU', '');
        }
        else {
          const userOauth = {
            sNumeroDocumento: response.numDoc,
            sTipoDocumento: response.tipoDoc,
            sNombres: response.nombres,
            sEmail: response.email,
            bExistEmail: response.email !== '' ? true : false
          }
          localStorage.setItem('uO', JSON.stringify(userOauth));
          this.isAuthenticated = true;
          let usuario = localStorage.getItem('userName');
          this.UserName = this.isNullUndefinesOrEmpty(usuario) ? userOauth.sNombres : usuario;
        }
      }
    );
  }
  cerrarSesion(): void {
    const cookie = this.sessionService.getCookie('SID');
    if (!this.isNullUndefinesOrEmpty(cookie)) {
      this.sessionService.getOperationSessionOauth(cookie, 'revoke').subscribe(
        response => {
          if (response.status === 'ok') {
            this.isAuthenticated = false;
            localStorage.setItem('userName', '');
            this.sessionService.setCookie('SID', '');
            this.sessionService.setSessionLocalStorage('SID', '');
            localStorage.setItem('uO', '');
            localStorage.setItem('aU', '');
            sessionStorage.setItem('consultor', 'false');
          }
        }
      );
    }
  }

  fnGoPerfil(): void {
    window.location.href = environment.baseWcc + '/profile';
  }

  fnGoEmpresa(): void {
    window.location.href = environment.baseEmpresa;
  }

  fnGoIntranet(): void {
  }

  fnclickBolsaTrabajo(): void{
    window.location.href = environment.baseWcc;
  }

  fnclickCul(): void {
    window.location.href = environment.baseCUL + 'irIndex.html';
    /*this.modal.open(this.modalCul, { size: 'lg'}).result.then(
      (result) => {
       // window.location.href = environment.baseCUL + 'irIndex.html';
      }
    );*/
  }

  fnclickCapacitate(): void {
    window.location.href = 'https://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }
}
