import { LaborumService } from '../../../services/laborum.service';
import { Capacitacion } from '../../../models/Capacitacion';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-virtuales',
  templateUrl: './cursos-virtuales.component.html',
  styleUrls: ['./cursos-virtuales.component.css']
})
export class CursosVirtualesComponent implements OnInit {

  cursosCapacitacion: Capacitacion[];
  constructor(private laborumService: LaborumService) { }

  ngOnInit() {
    this.cursosCapacitacion = this.laborumService.getCapacitaciones();
  }

}
