import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OfertaLaboral } from './../models/OfertaLaboral';

@Injectable({
  providedIn: 'root'
})
export class LaborumService {
  Url = 'https://empleate.pe/export/special/json';
  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
  getOfertasLaborales() {
    return this.http.get<OfertaLaboral[]>(this.Url);
  }

  getCapacitaciones() {
    return [
      {
        IdCapacitacion: '697874',
        TituloCapacitacion: '¿Cómo elaboro mi curriculum vitae?',
        DescripcionCapacitacion: 'Te enseñamos a crear un curriculum que destaque en tu proceso de postulación.',
        InicioCapacitacion: 'Empieza 18 Jul.',
        UrlCapacitacion: 'https://laborum.digital/ofertas/69787',
        ImgCapacitacion: '/assets/images/capacitacion/cv-1.jpg'
      },
      {
        IdCapacitacion: '697874',
        TituloCapacitacion: '¿Cómo elaboro mi curriculum vitae?',
        DescripcionCapacitacion: 'Te enseñamos a crear un curriculum que destaque en tu proceso de postulación.',
        InicioCapacitacion: 'Empieza 18 Jul.',
        UrlCapacitacion: 'https://laborum.digital/ofertas/69787',
        ImgCapacitacion: '/assets/images/capacitacion/cv-2.jpg'
      }, {
        IdCapacitacion: '697874',
        TituloCapacitacion: '¿Cómo elaboro mi curriculum vitae?',
        DescripcionCapacitacion: 'Te enseñamos a crear un curriculum que destaque en tu proceso de postulación.',
        InicioCapacitacion: 'Empieza 18 Jul.',
        UrlCapacitacion: 'https://laborum.digital/ofertas/69787',
        ImgCapacitacion: '/assets/images/capacitacion/cv-3.jpg'
      }];
  }
}
