import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.css']
})
export class TerminosCondicionesComponent implements OnInit {

  constructor(private routes: Router) { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }
  // tslint:disable-next-line: typedef
  fnIrRegistro(){
    const empresa = JSON.parse(sessionStorage.getItem('empresaModel'));
    if (empresa === null || empresa === '' || empresa === undefined){
      this.routes.navigate(['nuevo-usuario']);
    }else {
      this.routes.navigate(['registro-empresa-info']);
    }

  }
}
