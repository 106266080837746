<div class="row" style="margin: 120px 0px 0px 80px;">
  <p class="accesibility" style="font-size: 24px; font-weight: 600; color: #3d3d3d;">Accede a nuestras aplicaciones</p>
</div>
<div class="accesibility-theme row container-empresa" style="flex-wrap: inherit;">
  <div *ngIf="autorizaCrm" class="col-lg-4 col-md-4 col-sm-12 m-2"
    style="border: 1px #dbdbdb solid; padding: 15px; cursor: pointer; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);"
    (click)="fnGoCRM()">
    <p class="accesibility">
      <span style="color: #932222; font-weight: bold;">Ingresar al CRM</span>
    </p>
  </div>
  <div *ngIf="autorizaBolsa" class="col-lg-4 col-md-4 col-sm-12 m-2"
    style="border: 1px #dbdbdb solid; padding: 15px; cursor: pointer; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);"
    (click)="fnGoBolsa()">
    <p class="accesibility">
      <span style="color: #932222; font-weight: bold;">Ingresar a la Bolsa</span>
    </p>
  </div>
  <div *ngIf="autorizaRenupse" class="col-lg-4 col-md-4 col-sm-12 m-2"
    style="border: 1px #dbdbdb solid; padding: 15px; cursor: pointer; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);"
    (click)="fnGoRenupse()">
    <p class="accesibility">
      <span style="color: #932222; font-weight: bold;">Ingresar a RENUPSE</span>
    </p>
  </div>
  <div *ngIf="autorizaPj" class="col-lg-4 col-md-4 col-sm-12 m-2"
    style="border: 1px #dbdbdb solid; padding: 15px; cursor: pointer; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);"
    (click)="fnGoPj()">
    <p class="accesibility">
      <span style="color: #932222; font-weight: bold;">Ingresar al M&oacute;dulo de Atenci&oacute;n de Observados</span>
    </p>
  </div>
</div>
