<h3 class="accesibility-theme title-nuevo-usuario">Verificación de identidad</h3>
<div [style.width]="maxWidth" class="nuevo-usuario-MTPE">
  <form (ngSubmit)="onSubmit()" #nuevoUsuarioForm="ngForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <label for="cboTipoDocumento" class="texto-bold label-formulario">Tipo Documento<span
              class="accesibility-theme asterik-required">*</span></label>
          <select id="cboTipoDocumento" class="form-control" [(ngModel)]="usuarioModel.nInDocIdentidad"
            (change)="onSelectedChangedTipoDocumento($event.target.value)" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let tipoDocumento of tiposDocumento" [value]="+(tipoDocumento.value)">
              {{tipoDocumento.descripcion}}</option>
          </select>
        </div>
        <div *ngIf="isExtranjero">
          <div class="form-group">
            <label for="documentNumber">Número Documento <span class="accesibility-theme asterik-required">*</span></label>
            <input type="text" id="documentNumberAlterno" name="documentNumberAlterno" class="form-control"
              [disabled]="isLoading" [(ngModel)]="usuarioModel.sNuDocIdentidad" required pattern="[A-Za-z0-9]{6,9}"
              placeholder="Ingrese su número documento" maxlength="9" #sNuDocIdentidad="ngModel"
              (keypress)="notCaracteresEspeciales($event)">
            <div *ngIf="!sNuDocIdentidad.valid && sNuDocIdentidad.touched && !isLoading"
              class="alert alert-danger alert-danger-MTPE">
              El Número de documento debe tener entre 6 y 9 dígitos.
            </div>
          </div>
          <div class="form-group">
            <label for="sFechaCaducidad">Fecha de caducidad <span class="accesibility-theme asterik-required">*</span></label>
            <input type="date" class="form-control" [(ngModel)]="usuarioModel.sFechaCaducidad" min="2015-01-01"
              max="2040-01-01" [disabled]="isLoading || usuarioModel.bIndicadorNoCaducidad" name="sFechaCaducidad" id="sFechaCaducidad"
              #sFechaCaducidad="ngModel" (focus)="changedFotoCaducidad()" (blur)="changedFoto()">
          </div>
          <div class="form-group">
            <label for="sFechaNacimiento">Fecha de nacimiento <span class="accesibility-theme asterik-required">*</span></label>
            <input type="date" class="form-control" [(ngModel)]="usuarioModel.sFechaNacimiento" min="1930-01-01"
              max="2020-01-01" [disabled]="isLoading" required name="sFechaNacimiento" id="sFechaNacimiento"
              #sFechaNacimiento="ngModel" (focus)="changedFotoNacimiento()" (blur)="changedFoto()">
            <div *ngIf="!sFechaNacimiento.valid && sFechaNacimiento.touched && !isLoading"
              class="alert alert-danger alert-danger-MTPE">
              Por favor ingrese su fecha de nacimiento.
            </div>
          </div>
          <div *ngIf="isError" class="alert alert-danger alert-danger-MTPE" style="font-size: 11px;" role="alert">
            {{ mensajeError }}
          </div>
          <div class="form-group">
            <a class="accesibility-theme" [routerLink]="['/terminos-condiciones']" (click)="fnsaveDatos()"
              style="font-size: 14px; font-weight: bold;">Ver términos y condiciones</a>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" [(ngModel)]="isAcceptTerminos" class="form-check-input" id="isAcceptTerminos"
              name="isAcceptTerminos">
            <label class="form-check-label" for="sesionCheck">Acepto los términos y condiciones</label>
          </div>
          <button type="submit" class="accesibility-theme col-lg-12 btn btn-success"
            [disabled]="nuevoUsuarioForm.form.invalid || isLoading || !isAcceptTerminos"
            style="background-color: #219653;">
            Continuar
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div *ngIf="!isExtranjero">
          <div class="form-group">
            <label for="sNuDocIdentidad">DNI <span class="accesibility-theme asterik-required">*</span></label>
            <input type="text" id="sNuDocIdentidad" name="sNuDocIdentidad" class="form-control" [disabled]="isLoading"
              [(ngModel)]="usuarioModel.sNuDocIdentidad" required pattern="[0-9]{8}" placeholder="Ingrese su DNI"
              maxlength="8" #sNuDocIdentidad="ngModel" (focus)="changedFotoDNI()" (blur)="changedFoto()"
              (keypress)="notCaracteresEspeciales($event)">
            <div *ngIf="!sNuDocIdentidad.valid && sNuDocIdentidad.touched && !isLoading"
              class="alert alert-danger alert-danger-MTPE">
              El Número de documento DNI debe tener 8 dígitos
            </div>
          </div>
          <div class="form-group">
            <label for="sUbigeo">Ubigeo <span class="accesibility-theme asterik-required">*</span></label>
            <input type="text" id="sUbigeo" name="sUbigeo" class="form-control" [disabled]="isLoading"
              [(ngModel)]="usuarioModel.sUbigeo" required pattern="[0-9]{6}" placeholder="Ingrese su código de ubigeo"
              maxlength="6" #sUbigeo="ngModel" (focus)="changedFotoUbigeo()" (blur)="changedFoto()"
              (keypress)="notCaracteresEspeciales($event)">
            <div *ngIf="!sUbigeo.valid && sUbigeo.touched && !isLoading"
              class="alert alert-danger alert-danger-MTPE">
              El ubigeo es obligatorio, debe ser un número de 6 dígitos.
            </div>
          </div>
          <div class="form-group">
            <label for="sFechaCaducidad">Fecha de caducidad <span class="accesibility-theme asterik-required">*</span></label>
            <input type="date" class="form-control" [(ngModel)]="usuarioModel.sFechaCaducidad" min="2015-01-01"
              max="2040-01-01" [disabled]="isLoading || usuarioModel.bIndicadorNoCaducidad" name="sFechaCaducidad" id="sFechaCaducidad"
              #sFechaCaducidad="ngModel" (focus)="changedFotoCaducidad()" (blur)="changedFoto()">
            <div class="form-group form-check">
              <input type="checkbox" [(ngModel)]="usuarioModel.bIndicadorNoCaducidad" class="form-check-input" id="bIndicadorNoCaducidad"
                name="bIndicadorNoCaducidad" #bIndicadorNoCaducidad="ngModel">
              <label class="form-check-label" for="sesionCheck">No caduca</label>
            </div>
          </div>
          <div class="form-group">
            <label for="sFechaNacimiento">Fecha de nacimiento <span class="accesibility-theme asterik-required">*</span></label>
            <input type="date" class="form-control" [(ngModel)]="usuarioModel.sFechaNacimiento" min="1930-01-01"
              max="2020-01-01" [disabled]="isLoading" required name="sFechaNacimiento" id="sFechaNacimiento"
              #sFechaNacimiento="ngModel" (focus)="changedFotoNacimiento()" (blur)="changedFoto()">
            <div *ngIf="!sFechaNacimiento.valid && sFechaNacimiento.touched && !isLoading"
              class="alert alert-danger alert-danger-MTPE">
              Por favor ingrese su fecha de nacimiento.
            </div>
          </div>
          <div *ngIf="isError" class="alert alert-danger alert-danger-MTPE" style="font-size: 11px;" role="alert">
            {{ mensajeError }}
          </div>
          <div class="form-group">
            <a class="accesibility-theme" [routerLink]="['/terminos-condiciones']" (click)="fnsaveDatos()"
              style="font-size: 14px; font-weight: bold;">Ver términos y condiciones</a>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" [(ngModel)]="isAcceptTerminos" class="form-check-input" id="isAcceptTerminos"
              name="isAcceptTerminos">
            <label class="form-check-label" for="sesionCheck">Acepto los términos y condiciones</label>
          </div>
          <button type="submit" class="accesibility-theme col-lg-12 btn btn-success"
            [disabled]="nuevoUsuarioForm.form.invalid || isLoading || !isAcceptTerminos"
            style="background-color: #219653;">
            Continuar
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-6 dni-web" *ngIf="!isExtranjero">
        <div class="card-header tab-card-header">
          <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="accesibility-theme nav-link" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One"
                aria-selected="true">DNI Azul</a>
            </li>
            <li class="nav-item">
              <a class="accesibility-theme nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two"
                aria-selected="false">DNI Electrónico</a>
            </li>
          </ul>
        </div>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
            <img class="accesibility-theme" *ngIf="isDni" src="./assets/images/dni/dni.png" />
            <img class="accesibility-theme" *ngIf="isCaducidad" src="./assets/images/dni/dniFCADUCIDAD.png" />
            <img class="accesibility-theme" *ngIf="isNacimiento" src="./assets/images/dni/dniFNACIMIENTO.png" />
            <img class="accesibility-theme" *ngIf="isNro" src="./assets/images/dni/dniNRO.png" />
            <img class="accesibility-theme" *ngIf="isUbigeo" src="./assets/images/dni/dniUBIGEO.png" />
          </div>
          <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
            <img class="accesibility-theme" *ngIf="isDni" src="./assets/images/dni/dnie.png" />
            <img class="accesibility-theme" *ngIf="isCaducidad" src="./assets/images/dni/dnieFCADUCIDAD.png" />
            <img class="accesibility-theme" *ngIf="isNacimiento" src="./assets/images/dni/dnieFNACIMIENTO.png" />
            <img class="accesibility-theme" *ngIf="isNro" src="./assets/images/dni/dnieNRO.png" />
            <img class="accesibility-theme" *ngIf="isUbigeo" src="./assets/images/dni/dnieUBIGEO.png" />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<footer class="footer-principal footer-principal-verify">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
