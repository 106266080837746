<h3 class="accesibility-theme title-nuevo-usuario">Restablece tu contraseña</h3>
<div class="login-MTPE">
  <form (ngSubmit)="onSubmit()" #restableceForm="ngForm">
    <h5 class="subtitle-nueva-contrasenia">Te enviaremos un mensaje de texto o correo electrónico para que puedas
      restablecer tu contraseña</h5>
    <div class="form-group">
      <label for="cboTipoDocumento" class="texto-bold label-formulario">Tipo Documento<span
          class="accesibility-theme asterik-required">*</span></label>
      <select id="cboTipoDocumento" class="form-control" [(ngModel)]="reestablecerPasswordModel.sIdDocumento"
        [ngModelOptions]="{standalone: true}" (change)="changeTipoDocumento()">
        <option *ngFor="let tipoDocumento of tiposDocumento" [value]="+(tipoDocumento.value)">
          {{tipoDocumento.descripcion}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="sNumeroDocumento">Documento de Identidad Registrado <span class="accesibility-theme asterik-required">*</span></label>
      <input type="text" class="form-control" id="sNumeroDocumento" name="sNumeroDocumento" required
        pattern="[A-Za-z0-9]{4,9}" maxlength="25" [disabled]="isLoading"
        [(ngModel)]="reestablecerPasswordModel.sNumeroDocumento" placeholder="Ingrese su número documento de registro"
        #sNumeroDocumento="ngModel">
      <div *ngIf="!sNumeroDocumento.valid && sNumeroDocumento.touched && !isLoading"
        class="alert alert-danger alert-danger-MTPE">
        El número documento es obligatorio, alfanumérico mínimo 4 y maximo de 9 caracteres.
      </div>
    </div>
    <button type="submit" class="accesibility-theme col-lg-12 btn btn-success" [disabled]="restableceForm.form.invalid || isLoading"
      style="background-color: #219653;">
      Verificar Datos
    </button>
  </form>
  <div class="form-group" *ngIf="isVerifyUsuario" style="margin-top: 20px;">
    <label for="a_codnumdni">Escoja el medio a donde se le enviará el link para reestablecer su contraseña</label>
    <!--<div class="form-group col-lg-12" *ngIf="isExisteCelular">
      <input type="checkbox" class="form-check-input" id="celularCheck" name="celularCheck" [(ngModel)]="selectedCelular" (change)="changeCheckBoxCelular($event.target.checked)">
      <label class="form-check-label" for="sesionCheck">Celular: {{ verifyResetPasswordResponse.sNumeroCelular }}</label>
    </div>-->
    <div class="form-group col-lg-12" *ngIf="isExisteEmail">
      <input type="checkbox" class="form-check-input" id="emailCheck" name="emailCheck" [(ngModel)]="selectedEmail" (change)="changeCheckBoxEmail($event.target.checked)">
      <label class="form-check-label" for="emailCheck">Correo: {{ verifyResetPasswordResponse.sEmail }} </label>
    </div>
    <div class="form-group col-lg-12">
      <input type="checkbox" class="form-check-input" id="noneCheck" name="noneCheck" [(ngModel)]="selectedNone" (change)="changeCheckBoxNone($event.target.checked)">
      <label class="form-check-label" for="noneCheck">No tengo acceso a mi correo.</label>
    </div>
    <a class="accesibility-theme col-lg-12 btn btn-success" style="background-color: #219653; color: #fff;" [disabled]="!enviarValido || isLoading" (click)="enviarSolicitud()">
      Enviar Solicitud
    </a>
  </div>
</div>
<footer class="footer-principal">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
