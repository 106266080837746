import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-centro-empleo',
  templateUrl: './centro-empleo.component.html',
  styleUrls: ['./centro-empleo.component.scss']
})
export class CentroEmpleoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  fnclickBolsaTrabajo(): void{
    window.location.href = environment.baseWcc;
  }

  fnclickCul(): void {
    window.location.href = environment.baseCUL + 'irIndex.html';
  }

  fnclickCapacitate(): void {
    window.location.href = 'https://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }

}
