import { OauthResponse } from './../models/response/OauthResponse';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  baseUri = environment.baseOauth;
  baseIVR = environment.baseIVR;
  operationSessionUri = '/r/token?';
  postverifySessionId = 'autenticacion/sesion/validateIVR';
  ttl = 1800000;
  constructor(private cookieService: CookieService, private http: HttpClient) { }

  setCookie(key: string, value: string, pathValue: string = null, domainValue: string = null): void {
    this.cookieService.set(key, value, { path: pathValue, domain: domainValue });
  }

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  checkCookie(key: string): boolean {
    return this.cookieService.check(key);
  }

  getOperationSessionOauth(sSid: string, operation: string): Observable<OauthResponse> {
    return this.http.get<OauthResponse>(this.baseUri + this.operationSessionUri + 'SID=' + sSid + '&operation=' + operation);
  }

  getOperationSessionOauthRedirect(sSid: string, operation: string, nonce: string, clientId: string): Observable<OauthResponse> {
    return this.http.get<OauthResponse>(this.baseUri + this.operationSessionUri + 'SID=' + sSid + '&operation=' + operation +
      '&clientId=' + clientId + '&nonce=' + nonce);
  }
  postVerifySessionId(request: any) {
    return this.http.post<any>(this.baseIVR + this.postverifySessionId, request);
  }

  setSessionLocalStorage(key: string, value: string) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + this.ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getSessionLocalSotrage(key: string) {
    const itemStr = localStorage.getItem(key);
    const item = JSON.parse(itemStr);
    if(itemStr === null || itemStr === '' || itemStr === undefined){
      return null;
    }
    
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
    }
    return item.value;
  }
}

