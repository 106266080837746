<div class="container-cursos">
    <h5 class="title-cursos">Cursos virtuales de capacitación</h5>
    <div class="row">
        <div class="col-lg-12" *ngFor="let curso of cursosCapacitacion">
            <div class="card card-MTPE">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <img [src]="curso.ImgCapacitacion" class="card-img img-fluid img-hide" alt="Cursos virtuales">
                    </div>
                    <div class="col-md-9">
                        <div class="card-body">
                            <h5 class="card-title card-title-cursos-MTPE">{{ curso.TituloCapacitacion }}</h5>
                            <p class="card-text" style="font-size: 15px;">{{ curso.DescripcionCapacitacion }}</p>
                        </div>
                        <div class="card-footer" style="background-color: white;border-top: none;">
                            <p>
                                <small class="text-muted">{{ curso.InicioCapacitacion }}</small>
                                <a href="#" class="card-text pull-right" style="font-size: 15px;font-weight: 600;">Ver Oferta</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" style="text-align: right;">
            <button type="button" class="btn btn-primary btn-cursos">Ver todas las ofertas
        <img src="./assets/images/icons/flecha-azul/arrow_back-24px.png" alt="">
      </button>
        </div>
    </div>
</div>
