import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {

  active = 1;
  
  constructor() { }

  ngOnInit() {
  }

  fnclickBolsaTrabajo(): void{
    window.location.href = environment.baseWcc;
  }

  fnclickCul(): void {
    window.location.href = environment.baseCUL + 'irIndex.html';
  }

  fnclickCapacitate(): void {
    window.location.href = 'https://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }

}
