<div class="container-servicios">
    <h5 class="accesibility title-servicios-MTPE">Más Servicios</h5>
    <div class="row" style="margin-left: -30px; margin-right: -30px;">
        <!--<div class="col-lg-4">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/icons/servicios/oferta-search.png" alt="">
                    <h5 class="accesibility card-title card-title-servicios-MTPE">Busca un nuevo trabajo aquí</h5>
                    <p class="accesibility card-text card-text-servicios-MTPE">Tenemos la mejores ofertas para que puedas postular.
                    </p>
                    <button target="_blank" class="accesibility-theme accesibility col-lg-12 btn btn-sm btn-success" (click)="fngoOferta()">Ver Ofertas
                        <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                    </button>
                </div>
            </div>
        </div>-->
        <div class="col-lg-4">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/banner/CUL.png" style="width: 100%;">
                    <h5 class="accesibility card-title card-title-servicios-MTPE">Solicita tu Certificado Único Laboral</h5>
                    <p class="accesibility card-text card-text-servicios-MTPE">Obtén gratis tu certificado con toda la información que necesitas para postular a un empleo.
                    </p>
                    <button target="_blank" class="accesibility-theme accesibility col-lg-12 btn btn-sm btn-success" (click)="fngoCul()">Obtener Certificado
                        <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                    </button>
                </div>
            </div>
        </div>
        <!--<div class="col-lg-12" *ngIf="!isForeign">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/icons/servicios/cast_for_education-24px.png" alt="">
                    <h5 class="accesibility card-title card-title-servicios-MTPE">Verifica el Certificado Único Laboral</h5>
                    <p class="accesibility card-text card-text-servicios-MTPE">Como empleador puedes validar en línea la autenticidad del Certificado Único Laboral.
                    </p>
                    <button type="button" class="accesibility-theme accesibility col-lg-12 btn btn-sm btn-success" (click)="fngoVerifica()">Verifica los certificados aqui
                        <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                    </button>
                </div>
            </div>
        </div>-->
        <div class="col-lg-4">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/banner/capacitacion.png" alt="" style="width: 100%;">
                    <h5 class="accesibility card-title card-title-servicios-MTPE">CAPACITA-T</h5>
                    <p class="accesibility card-text card-text-servicios-MTPE" style="margin-top: -15px;margin-bottom: 0px;">Preparándote para tu proximo empleo.
                    </p>
                    <p class="accesibility card-text card-text-servicios-MTPE">Mejora tu empleabilidad, de forma gratiuta, capacitándote en cursos con alta demanda laboral.</p>
                    <button type="button" class="accesibility-theme accesibility col-lg-12 btn btn-sm btn-success" (click)="fngoCapacitacion()">Ver capacitaciones
                        <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/banner/carrera.jpg" alt="" style="width: 100%;">
                    <h5 class="accesibility card-title card-title-servicios-MTPE">Plataforma Mi Carrera</h5>
                    <p class="accesibility card-text card-text-servicios-MTPE" >Accede a información sobre las carreras mejor pagadas y más solicitadas en el mercado laboral. Además, conoce las opciones de financiamiento de estudios superiores.</p>
                    <button type="button" class="accesibility-theme accesibility col-lg-12 btn btn-sm btn-success" (click)="fngoBeca()">Más información
                        <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                    </button>
                </div>
            </div>
        </div>

        <!--<div class="col-lg-12">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/icons/servicios/cast_for_education-24px.png" alt="">
                    <h5 class="card-title card-title-servicios-MTPE">Es usted trabajador independiente</h5>
                    <p class="card-text card-text-servicios-MTPE">Inscriba sus servicios aquí para que sea contactado.
                    </p>
                    <button type="button" class="col-lg-12 btn btn-sm btn-success" (click)="fngoDirectorio()">Regístrese aquí
                      <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                  </button>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card card-servicios-MTPE">
                <div class="card-body" style="align-items: center;">
                    <img src="./assets/images/icons/servicios/cast_for_education-24px.png" alt="">
                    <h5 class="card-title card-title-servicios-MTPE">Contacte a un trabajador independiente </h5>
                    <p class="card-text card-text-servicios-MTPE">Los servicios que necesite, encuéntrelos aquí.
                    </p>
                    <button type="button" class="col-lg-12 btn btn-sm btn-success" (click)="fngoDirectorioBusqueda()">Ir al directorio
                    <img src="./assets/images/icons/flecha-blanca/arrow_back-24px.png" alt="">
                </button>
                </div>
            </div>
        </div>-->
    </div>
</div>
<ng-template #modalCul let-modal>
  <div class="modal-body">
    <a href="#" style="border: none" target="_blank">
      <img src="./assets/images/banner/flyer03.png" style="width: 100%;" />
    </a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
