import { Component, OnDestroy, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { SessionService } from 'src/app/services/session.service';
import { SmsService } from 'src/app/services/sms.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizar-usuario',
  templateUrl: './actualizar-usuario.component.html',
  styleUrls: ['./actualizar-usuario.component.scss']
})
export class ActualizarUsuarioComponent implements OnInit, OnDestroy {
  maxWidth = '800px';
  isLoading = false;
  oldUsuarioModel = null;
  usuarioModel = {
    sTipoDocumento: '',
    sDocumentoIdentidad: '',
    sNombresApellidos: '',
    sFechaNacimiento: '',
    sCelular: '',
    sCodeSms: '',
    sCorreo: '',
    sCodeCorreo: '',
    sActualizaPassword: false,
    sContraseniaActual: '',
    sContraseniaNueva: '',
    sContraseniaRepite: ''
  };
  cookie = '';
  validaSession = null;

  isNullUndefinesOrEmpty(value: any): boolean {
    return value == null || value === undefined || value === '';
  }

  constructor(private sessionService: SessionService, private usuarioServiice: UsuarioService,
    private smsService: SmsService, private mailService: MailService) { }

  ngOnInit() {
    this.verificaSession();
    this.validaSession = setInterval(() => {
      this.verificaSessionActiva();
    },2000);
  }

  ngOnDestroy() {
    clearInterval(this.validaSession);
  }

  verificaSessionActiva(){
    this.cookie = this.sessionService.getCookie('SID');
    if (this.isNullUndefinesOrEmpty(this.cookie)) {
      clearInterval(this.validaSession);
      Swal.fire(
        {
          title: 'Alerta!',
          text: 'Debe iniciar sesión para ingresar a esta sección',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      ).then(() => {
        window.location.href = environment.basePortal;
      });
    }
  }

  verificaSession(){
    this.cookie = this.sessionService.getCookie('SID');
    if (this.isNullUndefinesOrEmpty(this.cookie)) {
      Swal.fire(
        {
          title: 'Alerta!',
          text: 'Debe iniciar sesión para ingresar a esta sección',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      ).then(() => {
        window.location.href = environment.basePortal;
      });
    } else {
      this.usuarioServiice.getConsultaDatos(this.cookie).subscribe(
        response => {
          if (response.succes) {
            this.mappingDataUsuario(response.data);
          }
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  mappingDataUsuario(data) {
    this.oldUsuarioModel = data;
    this.usuarioModel.sTipoDocumento = data.nInDocIdentidad == 10080399 ? 'DNI' : data.nInDocIdentidad == 10080305 ? 'CE' : data.nInDocIdentidad == 10080306 ? 'PTP' : 'CPP';
    this.usuarioModel.sCorreo = data.sEmail;
    this.usuarioModel.sFechaNacimiento = data.sFeNacimiento;
    this.usuarioModel.sDocumentoIdentidad = data.sNuDocIdentidad;
    this.usuarioModel.sCelular = data.sNumeroCelular;
    this.usuarioModel.sNombresApellidos = data.vPrenomInscrito + ' ' + data.vApPrimer + ' ' + data.vApSegundo
  }

  onSubmit() {
    console.log(this.oldUsuarioModel);
    if(this.oldUsuarioModel.sEmail !== this.usuarioModel.sCorreo){
      if(this.isNullUndefinesOrEmpty(this.usuarioModel.sCodeCorreo)){
        Swal.fire(
          {
            title: 'Alerta!',
            text: 'Su email ha cambiado por favor ingrese el código enviado por correo.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }
        );
        return;
      }
    }
    let requestUsuario = {
      bActualizaPassword: this.usuarioModel.sActualizaPassword,
      sCodigoCelular: this.usuarioModel.sCodeSms,
      sCodigoEmail: this.usuarioModel.sCodeCorreo,
      sEmail: this.usuarioModel.sCorreo,
      sNewPassword: this.usuarioModel.sContraseniaNueva,
      sNumeroCelular: this.usuarioModel.sCelular,
      sOldPassword: this.usuarioModel.sContraseniaActual,
      sReNewPassword: this.usuarioModel.sContraseniaRepite,
      sSid: this.cookie
    };
    if (requestUsuario.bActualizaPassword) {
      if(this.isNullUndefinesOrEmpty(requestUsuario.sOldPassword)){
        Swal.fire(
          {
            title: 'Alerta!',
            text: 'Debe ingresar su contraseña actual.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }
        );
        return;
      }
      if(this.isNullUndefinesOrEmpty(requestUsuario.sNewPassword)){
        Swal.fire(
          {
            title: 'Alerta!',
            text: 'Debe ingresar la nueva contraseña.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }
        );
        return;
      }
      if(this.isNullUndefinesOrEmpty(requestUsuario.sReNewPassword)){
        Swal.fire(
          {
            title: 'Alerta!',
            text: 'Debe ingresar la nueva contraseña.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }
        );
        return;
      }
    }
    this.usuarioServiice.postActualizarPerfil(requestUsuario).subscribe(
      response => {
        if (response.succes) {
          Swal.fire(
            {
              title: '¡Éxito!',
              text: 'Datos actualizados correctamente.',
              icon: 'success',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          ).then(() => {
            clearInterval(this.validaSession);
            this.isLoading = false;
            window.location.href = environment.basePortal;
          });
        } else {
          Swal.fire(
            {
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          ).then(() => {
            this.isLoading = false;
          });
        }
      }, error => {
        Swal.fire(
          {
            title: '¡Error!',
            text: 'Ha ocurrido un error al actualizar los datos, inténtelo nuevamente.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            console.log(error);
          });
      });
  }
  sendSMS() {
    let request = {
      sNumeroCelular: this.usuarioModel.sCelular,
      sSid: this.cookie
    }
    this.smsService.postSendSmsSid(request).subscribe(
      response => {
        if (response.succes) {
          Swal.fire(
            {
              title: '¡Éxito!',
              text: 'Código enviado correctamente.',
              icon: 'success',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
        else {
          Swal.fire(
            {
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
      },
      error => {
        Swal.fire(
          {
            title: '¡Error!',
            text: 'Ha ocurrido un error al enviar el sms, inténtelo nuevamente.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            console.log(error);
          });
      });
  }
  sendEmail() {
    let request = {
      nInMotivo: 10140202,
      sEmailTo: this.usuarioModel.sCorreo,
      sSid: this.cookie
    };
    this.mailService.postMailSid(request).subscribe(
      response => {
        if (response.succes) {
          Swal.fire(
            {
              title: '¡Éxito!',
              text: 'Código enviado correctamente.',
              icon: 'success',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
        else {
          Swal.fire(
            {
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
      }, error => {
        Swal.fire(
          {
            title: '¡Error!',
            text: 'Ha ocurrido un error al enviar el mail, inténtelo nuevamente.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            console.log(error);
          });
      });
  }
  notCaracteresEspeciales(event) {
    let k;
    k = event.charCode;
    // tslint:disable-next-line: max-line-length
    return ((k >= 64 && k < 91) || (k >= 95 && k <= 123) || k == 8 || k == 46 || k == 193 || k >= 200 && k <= 250 || (k >= 44 && k <= 57));
  }
}
