import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empresa-home',
  templateUrl: './empresa-home.component.html',
  styleUrls: ['./empresa-home.component.css']
})
export class EmpresaHomeComponent implements OnInit {

  verCerrar = false;

  constructor(private routes: Router) { }

  ngOnInit() {
  }

  fngoCul() {
    window.location.href = environment.baseCUL + "Verificacion.html";
  }

  fngoLoginPortal() {
    window.location.href = environment.basePortal + "login-empresa";
  }

  fngoCapacitacion(){
    window.location.href = "https://mtpe.trabajo.gob.pe/capacitacionlaboral/";
  }

  fngoCitas() {
    window.location.href = "https://extranet.trabajo.gob.pe/extranet/web/citas";
  }
  fngoCentroEmpleo(){
    this.routes.navigate(['./centro-empleo']);
  }

  clickHelpContact() {
    this.verCerrar = !this.verCerrar;
  }

  fngoDiscapacidad() {
    window.location.href = "https://discapacidad.trabajo.gob.pe/";
  }

}
