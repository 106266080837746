import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment'; 
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  @ViewChild('contenido') private contenido;
  videos: any[];
  videospublicitarios: any[];
  page = 1;
  pageSize = 2;
  verCerrar = false;
  verAplicaciones = false;
  constructor(private modal: NgbModal, public sanitizer: DomSanitizer, private sessionService: SessionService) { }

  ngOnInit() {
    setTimeout(() => {
      this.modal.open(this.contenido, { size: 'lg'});
      // this.modal.open(this.contenido2, { size: 'lg'});
    }, 1000);
    this.videos = [
      {
        id: 1,
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/lLYdsPB_4nM'),
      },
      {
        id: 2,
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/kxd-kSwi1SE')
      },
      {
        id: 1,
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/JzGNpG_3Z1Q'),
      },
      {
        id: 2,
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/yfgywhf6t5w'),
      }
    ];

    this.videospublicitarios = [

    ];
    setTimeout(() => {
      let stringaccesosU = localStorage.getItem('aU');
      if (stringaccesosU !== null && stringaccesosU !== '' && stringaccesosU !== undefined){
        let aU = JSON.parse(stringaccesosU);
        if(aU.autorizaBolsa || aU.autorizaCrm || aU.autorizaRenupse || aU.autorizaPj){
          this.verAplicaciones = true;
        }
      }
    }, 1500);
  }
  clickHelpContact() {
    this.verCerrar = !this.verCerrar;
  }
  fngoConsultor() {
    window.location.href = environment.baseOrientadores;
  }

  fnGoEmpleabilidad(){
    const cookie = this.sessionService.getCookie('SID');
    window.location.href = 'https://sisenep-dev.pe/empleabilidad-mtpe/#/?var=' + cookie;
  }

  fnGoCRM(){
    const cookie = this.sessionService.getCookie('SID');
    window.location.href = environment.baseCRM + 'home?var=' + cookie;
  }
}
