<div class="row margin-container container-responsive accesibility-theme" style="margin-top: 70px;">
  <div class="col-lg-6">
    <p class="header-titel-mtpe header-container accesibility">Empleos Perú</p>
    <p style="margin-top: -10px;">
      <a href="https://www.facebook.com/MTPEPERU" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/FB.png" />
      </a>
      <a href="https://instagram.com/mtpe_peru?igshid=YmMyMTA2M2Y=" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IG.png" />
      </a>
      <a href="https://twitter.com/MTPE_Peru?t=ucOn7RjBVRUvDKO9hBqnKQ&s=08" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/TW.png" />
      </a>
      <a href="https://www.linkedin.com/company/mtpe-peru/" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IN.png" />
      </a>
      <a href="https://www.tiktok.com/@mtpeperu?lang=es" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/Tiktok.png" />
      </a>
    </p>
  </div>
  <div class="col-lg-6">
    <div class="row header-container">
      <div class="col-lg-12">
        <!--<img src="./assets/images/banner/central-numero.png" style="width: auto;" class="logo-telefono pull-right"
          alt="Comunícate con nosotros llamando al 0800 71707" />-->
      </div>
    </div>
    <div class="row header-container">
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12 accesibility"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickBolsaTrabajo()">Bolsa de
          Trabajo</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12 accesibility"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCul()">Certificado
          Único Laboral</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12 accesibility"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCapacitate()">Capacítate</button>
      </div>
    </div>
  </div>
</div>
<section class="jumbotron-MTPE accesibility-theme header-container accesibility-theme"
  style="background-color: #fff !important;">
</section>
<div class="container-MTPE accesibility-theme" style="margin-top: 20px;">
  <p class="accesibility" style="color: #CC0000; font-size: 26px; font-weight: bold;">El Centro de Empleo</p>
  <p class="accesibility" style="color: #000; font-size: 16px; text-align: justify;">El Centro de Empleo ofrece
    servicios gratuitos de promoción del
    empleo para promover la inserción laboral, propiciando el encuentro entre empleadores y personas en búsqueda de
    empleo, mejorando la empleabilidad con el desarrollo de capacidades laborales y para el desarrollo de
    emprendimientos de manera sostenible.</p>
  <p class="accesibility" style="color: #000; font-size: 16px; text-align: justify;"><span
      style="color: #CC0000; font-weight: bold;">Empleos
      Perú</span> es el portal web del Servicio Nacional del Empleo, a través del cual se ofrecen tres servicios
    virtuales:
    <b>Bolsa de Trabajo, Certificado Unico Laboral (CUL) y Capacitación Laboral.</b>
  </p>
  <p class="accesibility" style="color: #000; font-size: 16px; text-align: justify;">Nuestra misión es acompañar a las
    personas a lo largo de
    su vida laboral, con servicios que promuevan el empleo
    decente y contribuyan a la dinamización del mercado de trabajo y a la productividad del país.</p>
  <br>
  <p class="accesibility" style="color: #CC0000; font-size: 26px; font-weight: bold;">Servicios que ofrece el Centro de
    Empleo</p>
  <div class="tabs">
    <div class="tab">
      <input type="checkbox" id="chck1">
      <label class="tab-label accesibility" for="chck1" style="margin-bottom: 0;">Bolsa de Trabajo</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>Ponemos
            a tu disposición diversas ofertas
            laborales a nivel nacional.</b>
          Para acceder a ellas, te ayudamos a registrarte, buscar las ofertas que sean acordes con tu perfil y
          <b>postular
            dentro del portal Empleos
            Perú.</b> Contamos con vacantes exclusivas para personas con discapacidad con la finalidad de promover su
          inserción en el mercado laboral.
        </p>
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>Los
            empleadores pueden acceder y publicar de manera gratuita e ilimitada </b>sus ofertas laborales a nivel
          nacional.
        </p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck2">
      <label class="tab-label accesibility" for="chck2" style="margin-bottom: 0;">Asesoría para búsqueda de
        empleo</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>Te
            asesoramos para que tengas mayor visibilidad para los empleadores</b>, fortaleciendo tus capacidades para
          una
          efectiva búsqueda de empleo que incremente tus posibilidades de inserción laboral. Te ayudamos a mejorar tu CV
          y a estar preparado para afrontar una entrevista de trabajo.
        </p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck3">
      <label class="tab-label accesibility" for="chck3" style="margin-bottom: 0;">Certificado Único Laboral
        (CUL)</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>Te
            ayudamos
            a generar tu CUL desde el portal Empleos Perú.</b> El CUL es un documento oficial del Estado peruano que
          <b>contiene
            los antecedentes policiales, judiciales, penales,</b> la validación de identidad de RENIEC, así como la
          trayectoria educativa y experiencia laboral formal de los ciudadanos.
        </p>
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>El
            empleador tiene la posibilidad de validar la autenticidad del CUL</b> a través del portal, facilitando con
          ello
          sus procesos de contratación e intermediación laboral.
        </p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck4">
      <label class="tab-label accesibility" for="chck4" style="margin-bottom: 0;">Capacitación Laboral</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
          <b>Orientamos
            a las personas para que accedan, desde el portal Empleos Perú,</b> a rutas formativas de capacitación <b>y
            más de 50
            cursos con certificación gratuita,</b> para potenciar su perfil profesional, mejorando así sus oportunidades
          de
          empleo.
        </p>
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;"><b>Los
            empleadores pueden capacitar a sus postulantes o colaboradores en diversos cursos,</b> certificados por el
          Ministerio de Trabajo y Promoción del Empleo y por otras instituciones aliadas de prestigio.</p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck5">
      <label class="tab-label accesibility" for="chck5" style="margin-bottom: 0;">Orientación para el migrante</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
          <b>Brindamos
            información y orientación a potenciales emigrantes, peruanos emigrantes, retornantes e inmigrantes,</b>
          sobre el
          proceso migratorio laboral y los derechos laborales, para facilitar una decisión consciente e informada que
          contribuya a una migración segura, ordenada y regular.
        </p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck6">
      <label class="tab-label accesibility" for="chck6" style="margin-bottom: 0;">Orientación vocacional e información
        ocupacional</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
          <b>Orientamos
            a jóvenes entre los 16 y 24 años, o estudiantes de 4to y 5to de secundaria,</b> para que puedan identificar
          su
          perfil vocacional y contar con información oportuna del mercado laboral y las diversas opciones formativas, a
          fin de elegir una carrera profesional, técnica u ocupacional.
        </p>
      </div>
    </div>
    <div class="tab">
      <input type="checkbox" id="chck7">
      <label class="tab-label accesibility" for="chck7" style="margin-bottom: 0;">Acercamiento empresarial</label>
      <div class="tab-content">
        <p class="accesibility"
          style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
          <b>Acompañamos
            a las empresas en sus procesos de demanda de personal,</b> a fin de dotarlas de candidatos que cubran la
          posición
          vacante.
        </p>
        <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">Y mucho
          más. Consulta en cada Centro de Empleo los servicios, horarios y disponibilidad.</p>
      </div>
    </div>
  </div>
  <br>
  <p class="accesibility" style="color: #CC0000; font-size: 26px; font-weight: bold; margin-top: 30px;">Ubicación de los
    Centros de Empleo a
    nivel nacional</p>
  <p class="accesibility" style="color: #000; font-size: 16px; text-align: justify;">Centros de Empleo y otras unidades
    prestadoras de servicios de empleo.</p>
  <div class="row">
    <div class="col-lg-4">
      <div class="tabs">
        <div class="tab">
          <input type="checkbox" id="chck8">
          <label class="tab-label accesibility" for="chck8" style="margin-bottom: 0;">Amazonas</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro
                de Empleo Amazonas:</b> Jr. Santa Ana 1259. Distrito y provincia de Chachapoyas
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/NzbJTQra488HkSVz5">https://goo.gl/maps/NzbJTQra488HkSVz5</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;"><b>Zona de
                Trabajo y Promoción del Empleo de Bagua - Utcubamba:</b> Jr. José Gálvez Nº 225 - 2do piso. Distrito
              Bagua
              Grande y provincia Utcubamba
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/x1V8KQYHktcwiGNj8">https://goo.gl/maps/x1V8KQYHktcwiGNj8</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck9">
          <label class="tab-label accesibility" for="chck9" style="margin-bottom: 0;">Áncash</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Áncash:</b> Av. Francisco Bolognesi 183, Santa – Chimbote - Áncash
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/FcGdroqBZxBKz7Yu9">https://goo.gl/maps/FcGdroqBZxBKz7Yu9</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck10">
          <label class="tab-label accesibility" for="chck10" style="margin-bottom: 0;">Apurímac</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Apurímac:</b> Jr. Huancavelica N° 537 - esquina con Av. Parado Bajo, Abancay -
              Apurímac
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/1JcqSk7HuvHV3eUQ9">https://goo.gl/maps/1JcqSk7HuvHV3eUQ9</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Andahuaylas:</b> Jr. Ayacucho Nº 615 - Andahuaylas - Apurímac
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/rievUMV74Qhb3b628">https://goo.gl/maps/rievUMV74Qhb3b628</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck11">
          <label class="tab-label accesibility" for="chck11" style="margin-bottom: 0;">Arequipa</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo de Arequipa:</b> Calle Gómez De La Torre N°103 - Urb. Victoria Cercado (a espaldas de
              la Gerencia de Trabajo)
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/bQjs86RAu3dzeuMB7">https://goo.gl/maps/bQjs86RAu3dzeuMB7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck12">
          <label class="tab-label accesibility" for="chck12" style="margin-bottom: 0;">Ayacucho</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo:</b> Jr. Roma N° 299 - Distrito Ayacucho - Provincia Huamanga (referencia a una cuadra
              del mercado Magdalena)
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/vEYvQ5bPBYt8UyMb9">https://goo.gl/maps/vEYvQ5bPBYt8UyMb9</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck13">
          <label class="tab-label accesibility" for="chck13" style="margin-bottom: 0;">Cajamarca</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Cajamarca:</b> Jr. Baños del Inca 290, Cajamarca
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/ZG1wqw5t8vQU96Mc8">https://goo.gl/maps/ZG1wqw5t8vQU96Mc8</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Jaén: </b>Av. Villanueva Pinillos 941, Jaén -Cajamarca
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/pmLhfNvo4uHw8c5S7">https://goo.gl/maps/pmLhfNvo4uHw8c5S7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck14">
          <label class="tab-label accesibility" for="chck14" style="margin-bottom: 0;">Callao</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Callao:</b> Av. Sáenz Peña N° 150. Distrito y provincia de Callao
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/epmdkmAzd6BTEQgE9">https://goo.gl/maps/epmdkmAzd6BTEQgE9</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck15">
          <label class="tab-label accesibility" for="chck15" style="margin-bottom: 0;">Cusco</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Cusco:</b> Av. Micaela Bastidas esquina con Alcides Vigo N° 301 Distrito Wanchaq -
              Cusco
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/cYikp2PQMfF2SWzL7">https://goo.gl/maps/cYikp2PQMfF2SWzL7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck16">
          <label class="tab-label accesibility" for="chck16" style="margin-bottom: 0;">Huancavelica</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Huancavelica:</b> Av. 28 de Abril N°465 - Barrio de San Cristóbal - Huancavelica (a
              media cuadra de 3 esquinas)
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/5JNgnSWWhpngNxBE8">https://goo.gl/maps/5JNgnSWWhpngNxBE8</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="tabs">
        <div class="tab">
          <input type="checkbox" id="chck17">
          <label class="tab-label accesibility" for="chck17" style="margin-bottom: 0;">Huánuco</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Huánuco:</b> Jr. Mayro 379. Distrito y provincia Huánuco
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/iP1va6A9qFjkN7uH7">https://goo.gl/maps/iP1va6A9qFjkN7uH7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck18">
          <label class="tab-label accesibility" for="chck18" style="margin-bottom: 0;">Ica</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Ica:</b> Jr. Huánuco 145 – Ica
              <br><span>T: (056) 234709 anexo 608 | Cel: 988006518 - 988006107</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/NpoFmnrzBvumwQVo7">https://goo.gl/maps/NpoFmnrzBvumwQVo7</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Pisco:</b> Avenida San Martín N°240
              <br><span>Cel: 976 679 169</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/Mnoa8H5s1pTRXErr6">https://goo.gl/maps/Mnoa8H5s1pTRXErr6</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Chincha:</b> Calle Pedro Moreno Nº 299. Chincha Alta
              <br><span>Cel: 922 452 112</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/WofH4PuFDxM2VZWb8">https://goo.gl/maps/WofH4PuFDxM2VZWb8</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Nazca:</b> Av. la Cultura s/n frente a la Fac. Ing. de Minas
              <br><span>Cel: 984 326 347</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/2RbHfsLZKtpYn1889">https://goo.gl/maps/2RbHfsLZKtpYn1889</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Marcona:</b> Av. Andrés Avelino Cáceres N°101 - Mz y lote 02
              piso 4to
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/3A4J5abHJ9GDYAmZ6">https://goo.gl/maps/3A4J5abHJ9GDYAmZ6</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck19">
          <label class="tab-label accesibility" for="chck19" style="margin-bottom: 0;">Junín</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Junín:</b> Jr. Arequipa N° 520, El Tambo - Huancayo – Junín
              <a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/o6SWAMn8ithEjSzW8">https://goo.gl/maps/o6SWAMn8ithEjSzW8</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck20">
          <label class="tab-label accesibility" for="chck20" style="margin-bottom: 0;">La Libertad</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo La Libertad:</b> Pasaje Las Torcazas 1215 - Urb. Los Pinos I Etapa Mz. A Sub lote A -
              Trujillo
              <br><span>T: 044 729880</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/ZGTz7zDMizars6oT8">https://goo.gl/maps/ZGTz7zDMizars6oT8</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck21">
          <label class="tab-label accesibility" for="chck21" style="margin-bottom: 0;">Lambayeque</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Lambayeque:</b> Calle Los Laureles #131 - 137 Urb. Libertadores, Chiclayo
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/jTzJ2DFL42cQgNL2A">https://goo.gl/maps/jTzJ2DFL42cQgNL2A</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck22">
          <label class="tab-label accesibility" for="chck22" style="margin-bottom: 0;">Lima Metropolitana</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo:</b> Av. Salaverry 655 Jesús María, Lima
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/rseFjVngCCVGmBEx8">https://goo.gl/maps/rseFjVngCCVGmBEx8</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo San Juan de Lurigancho: </b> Urb. San Hilarión, cruce Av. Germanio y Jr.
              Vanadio (alt. del paradero 17 av. Las Flores).
              <br>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Villa El Salvador:</b> Av. La Solidaridad, parcela II, Mz. F, lote 11,
              Parque Industrial (a una cuadra de la Avenida El Sol).
              <br>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck23">
          <label class="tab-label accesibility" for="chck23" style="margin-bottom: 0;">Lima Provincias</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Lima Provincias:</b> Av. Túpac Amaru 403 – 405, Santa María – Huacho
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/xUUmfa9euvm1mnR1A">https://goo.gl/maps/xUUmfa9euvm1mnR1A</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Cañete:</b> Jr. Víctor Andrés Belaunde N° 297 - Urb. Las
              Casuarinas - San Vicente de Cañete
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/dttxFQb4WdwcV11L6">https://goo.gl/maps/dttxFQb4WdwcV11L6</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Barranca:</b> Av. El Lino s/n a espalda del Estadio Barranca
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/KXGqiXHvLrq3XPz19">https://goo.gl/maps/KXGqiXHvLrq3XPz19</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck24">
          <label class="tab-label accesibility" for="chck24" style="margin-bottom: 0;">Loreto</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Loreto:</b> Av. del Ejército N° 206 - 208 Curva de Moronacocha - Iquitos – Maynas
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/enQT9zRX48ZvvsWP7">https://goo.gl/maps/enQT9zRX48ZvvsWP7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck25">
          <label class="tab-label accesibility" for="chck25" style="margin-bottom: 0;">Madre de Dios</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Madre de Dios:</b> Jr. Ucayali cuadra 1 (Ex Local del PRONAA)
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/manjJAjUyFp19ZNH7">https://goo.gl/maps/manjJAjUyFp19ZNH7</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="tabs">
        <div class="tab">
          <input type="checkbox" id="chck26">
          <label class="tab-label accesibility" for="chck26" style="margin-bottom: 0;">Moquegua</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Moquegua:</b> Calle Ayacucho N° 959, esquina con Amazonas N° 370
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/nm7RnaNVLz7Z6gRp7">https://goo.gl/maps/nm7RnaNVLz7Z6gRp7</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Ilo:</b> Jr. Abtao N° 665 3er Piso
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/7gXDafJvqtMkUMky7">https://goo.gl/maps/7gXDafJvqtMkUMky7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck27">
          <label class="tab-label accesibility" for="chck27" style="margin-bottom: 0;">Pasco</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Pasco:</b> Av. Los Próceres N° 707 - Urb. San Juan. Distrito Yanacancha y provincia de
              Pasco
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/aUNcJVC8fTfiNmRY7">https://goo.gl/maps/aUNcJVC8fTfiNmRY7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck28">
          <label class="tab-label accesibility" for="chck28" style="margin-bottom: 0;">Piura</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo de Piura:</b> Cruce de la Av. Andrés Cáceres con la Av. Guillermo Irazola centro
              comercial Open Plaza 2do nivel
              <br><span>T: 073 613405</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/xcevUbzrCcEUdY7m9">https://goo.gl/maps/xcevUbzrCcEUdY7m9</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Agencia local de empleo de la Municipalidad Provincial de Sullana:</b> Pasaje Cardo s/n Sullana
              <br><span>T: 963 6987 09</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/i4QTtD7u8tPW56ST6">https://goo.gl/maps/i4QTtD7u8tPW56ST6</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Sullana:</b> Calle El Carmen Nº 418. Urb. Santa Rosa
              <br><span>T: 073-502190</span>
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/j5mHKPVEJcBmPvjz6">https://goo.gl/maps/j5mHKPVEJcBmPvjz6</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Talara:</b> Av. Mártires Petroleros 2do piso oficina 221
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/ZQTxfgBQzjf1W4zV9">https://goo.gl/maps/ZQTxfgBQzjf1W4zV9</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Paita:</b> Jr. La Merced 382 - 2do piso frente a la plazuela
              Grau
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/cLkHi6MyxE5sj1eh8">https://goo.gl/maps/cLkHi6MyxE5sj1eh8</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck29">
          <label class="tab-label accesibility" for="chck29" style="margin-bottom: 0;">Puno</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Puno:</b> Jr. Ayacucho N°658 - Puno
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/2A4ry9Y16GmjcV6N6">https://goo.gl/maps/2A4ry9Y16GmjcV6N6</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck30">
          <label class="tab-label accesibility" for="chck30" style="margin-bottom: 0;">San Martín</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Moyobamba:</b> Calle Aeropuerto Nro. 150 Barrio Lluyllucucha (Sede central del
              Gobierno Regional San Martín)
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/Ucvf6Sb115BL4Ksb9">https://goo.gl/maps/Ucvf6Sb115BL4Ksb9</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Tarapoto:</b> Jr. Ángel Delgado Morey N° 435 - 1er piso
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/Qg8gnsyQEharmmFD6">https://goo.gl/maps/Qg8gnsyQEharmmFD6</a>
            </p>
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Zona de Trabajo y Promoción del Empleo Tocache:</b> Jr. Carlos Arévalo s/n. Ref. Goresam. Sub Región
              Alto Huallaga
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/jFXHRtUJjVqEh5tk7">https://goo.gl/maps/jFXHRtUJjVqEh5tk7</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck31">
          <label class="tab-label accesibility" for="chck31" style="margin-bottom: 0;">Tacna</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Tacna:</b> A.H.M. Villa Cristo Rey y Pueblo Libre Lt. 13 y 14 - C.P. Augusto B. Leguía
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/VjXaFaGzj81VUXf58">https://goo.gl/maps/VjXaFaGzj81VUXf58</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck32">
          <label class="tab-label accesibility" for="chck32" style="margin-bottom: 0;">Tumbes</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Tumbes:</b> Calle Arica Nº 353, Barrio San José - Tumbes
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/S4a4P4PZe3ms5o898">https://goo.gl/maps/S4a4P4PZe3ms5o898</a>
            </p>
          </div>
        </div>
        <div class="tab">
          <input type="checkbox" id="chck33">
          <label class="tab-label accesibility" for="chck33" style="margin-bottom: 0;">Ucayali</label>
          <div class="tab-content">
            <p class="accesibility" style="color: #000; font-size: 16px;">
              <b>Centro de Empleo Ucayali:</b> Jr. Inmaculada 999 – Callería - Coronel Portillo - Ucayali
              <br><a style="cursor: pointer;" target="_blank"
                href="https://goo.gl/maps/LTJQQ6ihweTxMZFR6">https://goo.gl/maps/LTJQQ6ihweTxMZFR6</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer-principal accesibility-theme" style="margin-top: 5px;">
  <!--<div class="footer-callcenter">
    <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
        <img class="accesibility-theme" style="width: 50%;"
          src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
          src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
    </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
