import { MailRequest } from './../models/request/MailRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StatusResponse } from '../models/StatusResponse';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  baseUrl = environment.baseUrl;
  sendMailUri = '/email/enviar';
  sendMailEmpresaUri = '/email/enviar-empresa';
  sendMailSidUri = '/email/enviar-sid';
  constructor(private http: HttpClient) { }

  postMailSend(request: MailRequest): Observable<StatusResponse<boolean>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: max-line-length
      'X-Validate-Identity': sessionStorage.getItem('tokenIdentidad') === null ? sessionStorage.getItem('sTokenRP') : sessionStorage.getItem('tokenIdentidad')
    });
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendMailUri, request, { headers: reqHeader });
  }
  postMailEmpresaSend(request: MailRequest): Observable<StatusResponse<boolean>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: max-line-length
      'X-Validate-Identity': sessionStorage.getItem('tokenEmpresa')
    });
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendMailEmpresaUri, request, { headers: reqHeader });
  }
  postMailSid(request: any): Observable<StatusResponse<boolean>> {
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendMailSidUri, request);
  }
}
