import { environment } from 'src/environments/environment';
import { WccService } from './../../../services/wcc.service';
import { SessionService } from './../../../services/session.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ofertas-laborales',
  templateUrl: './ofertas-laborales.component.html',
  styleUrls: ['./ofertas-laborales.component.css']
})
export class OfertasLaboralesComponent implements OnInit {

  ofertasLaborales: any;
  urlOferta: string;
  urlTodaOferta: string;
  existData = false;
  constructor(private wccService: WccService) { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    setTimeout(() => {
      this.wccService.getOfertasLaborales(6).subscribe(
        response => {
          if (response.succes){
            this.ofertasLaborales = response.data.data;
            if (this.ofertasLaborales.length > 0){
              this.existData = true;
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }, 1000);
    this.urlOferta = environment.baseWcc + '/search-jobs?jobId=';
    this.urlTodaOferta = environment.baseWcc + '/search-jobs';
  }

  fngoOferta(idOferta) {
    window.location.href = this.urlOferta + idOferta;
  }

}
