import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoginEmpresaComponent } from './components/usuario/login-empresa/login-empresa.component';
import { ActualizarUsuarioComponent } from './components/usuario/actualizar-usuario/actualizar-usuario.component';
import { RegistroEmpresaInfoComponent } from './components/usuario/registro-empresa-info/registro-empresa-info.component';
import { RegistroEmpresaComponent } from './components/usuario/registro-empresa/registro-empresa.component';
import { PagoNotFoundComponent } from './components/portal/pago-not-found/pago-not-found.component';
import { LaborumService } from './services/laborum.service';
import { TopBarComponent } from './components/portal/top-bar/top-bar.component';
import { BannerComponent } from './components/portal/banner/banner.component';
import { OfertasLaboralesComponent } from './components/portal/ofertas-laborales/ofertas-laborales.component';
import { ServiciosComponent } from './components/portal/servicios/servicios.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CursosVirtualesComponent } from './components/portal/cursos-virtuales/cursos-virtuales.component';
import { ContainerComponent } from './components/portal/container/container.component';
import { LoginComponent } from './components/usuario/login/login.component';
import { NuevoUsuarioComponent } from './components/usuario/nuevo-usuario/nuevo-usuario.component';
import { NuevaContraseniaComponent } from './components/usuario/nueva-contrasenia/nueva-contrasenia.component';
import { HttpErrorInterceptor } from './services/httpError.interceptor';
import { RestablecerContraseniaComponent } from './components/usuario/restablecer-contrasenia/restablecer-contrasenia.component';
import { TerminosCondicionesComponent } from './components/portal/terminos-condiciones/terminos-condiciones.component';
import { TerminosCondicionesEComponent } from './components/portal/terminos-condiciones-e/terminos-condiciones-e.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { EmpresaHomeComponent } from './components/portal/empresa-home/empresa-home.component';
import { CentroEmpleoComponent } from './components/portal/centro-empleo/centro-empleo.component';
import { PreguntasFrecuentesComponent } from './components/portal/preguntas-frecuentes/preguntas-frecuentes.component';
import { TrabajaExtranjeroComponent } from './components/portal/trabaja-extranjero/trabaja-extranjero.component';
import { AplicacionesPortalComponent } from './components/portal/aplicaciones-portal/aplicaciones-portal.component';

const routesMTPE: Routes = [
   { path: '', component: ContainerComponent },
   { path: 'login', component: LoginComponent },
   { path: 'login-empresa', component: LoginEmpresaComponent },
   { path: 'empleateAuth', component: LoginComponent },
   { path: 'nuevo-usuario', component: NuevoUsuarioComponent },
   { path: 'nuevo-usuario/:sToken', component: NuevoUsuarioComponent },
   { path: 'nueva-contrasenia', component: NuevaContraseniaComponent },
   { path: 'nueva-contrasenia/:sCodigo', component: NuevaContraseniaComponent },
   { path: 'nueva-contrasenia/:sCodigo/:sNuDocIdentidad/:nInDocIdentidad/:nIdAppvalIdentidad', component: NuevaContraseniaComponent },
   { path: 'restablece-contrasenia', component: RestablecerContraseniaComponent },
   { path: 'terminos-condiciones', component: TerminosCondicionesComponent },
   { path: 'terminos-condiciones-e', component: TerminosCondicionesEComponent },
   { path: 'registro-empresa', component: RegistroEmpresaComponent },
   { path: 'registro-empresa-info', component: RegistroEmpresaInfoComponent },
   { path: 'actualizar-perfil', component: ActualizarUsuarioComponent },
   { path: 'empresa-home', component: EmpresaHomeComponent },
   { path: 'centro-empleo', component: CentroEmpleoComponent },
   { path: 'preguntas-frecuentes', component: PreguntasFrecuentesComponent },
   { path: 'trabaja-extranjero', component: TrabajaExtranjeroComponent },
   { path: 'aplicaciones', component: AplicacionesPortalComponent },
   { path: '**', component: PagoNotFoundComponent }
];

@NgModule({
   declarations: [
      AppComponent,
      TopBarComponent,
      BannerComponent,
      OfertasLaboralesComponent,
      ServiciosComponent,
      CursosVirtualesComponent,
      ContainerComponent,
      LoginComponent,
      LoginEmpresaComponent,
      NuevoUsuarioComponent,
      NuevaContraseniaComponent,
      RestablecerContraseniaComponent,
      TerminosCondicionesComponent,
      RegistroEmpresaComponent,
      RegistroEmpresaInfoComponent,
      ActualizarUsuarioComponent,
      EmpresaHomeComponent,
      CentroEmpleoComponent,
      PreguntasFrecuentesComponent,
      TrabajaExtranjeroComponent,
      AplicacionesPortalComponent
   ],
   imports: [
      RouterModule.forRoot(routesMTPE, { useHash: true }),
      FormsModule,
      NgbModule,
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      RecaptchaModule,
      RecaptchaFormsModule
   ],
   providers: [
      LaborumService,
      CookieService,
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
