<div class="container-ofertas" style="margin-left: -30px; margin-right: -30px; margin-top: 20px;">
    <h5 class="title-ofertas" style="margin-left: 30px;">Ofertas laborales</h5>
    <div class="row" *ngIf="existData">
        <div class="col-lg-4" *ngFor="let ofertaLaboral of ofertasLaborales">
            <div class="card card-MTPE">
                <div class="card-body">
                  <h5 class="accesibility card-title card-title-MTPE">{{ ofertaLaboral.companyName }}</h5>
                  <h6 class="accesibility-theme accesibility card-subtitle mb-2 card-subtitle-MTPE">{{
                    ofertaLaboral.positionTitle}}</h6>
                    <p *ngIf="ofertaLaboral.location" class="accesibility card-point-MTPE"><img src="./assets/images/icons/ubicacion/location_on-24px.png" 
                      alt=""> {{ ofertaLaboral.location.DEPARTMENT + ' ' + ofertaLaboral.location.PROVINCE + ' ' +
                      ofertaLaboral.location.DISTRICT }}
                  </p>
                  <span href="#" class="accesibility text-muted" style="font-size: 15px;font-weight: 500;">{{
                    ofertaLaboral.datePosted }}</span>
                  <a (click)="fngoOferta(ofertaLaboral.id)" href="#"
                    class="accesibility-theme accesibility card-link pull-right"
                    style="font-size: 15px;font-weight: 600;cursor: pointer;">Ver Oferta</a>
                </div>
            </div>
        </div>
        <div class="col-lg-12" style="text-align: right;">
            <a type="button" class="accesibility-theme accesibility btn btn-primary btn-ofertas" [href]="urlTodaOferta">Ver todas las ofertas
                <img class="accesibility-theme" src="./assets/images/icons/flecha-azul/arrow_back-24px.png" alt="">
            </a>
        </div>
    </div>
</div>
