<div class="accesibility-theme row margin-container container-responsive" style="margin-top: 70px;">
  <div class="col-lg-6">
    <p class="header-titel-mtpe header-container accesibility">Empleos Perú</p>
    <p style="margin-top: -10px;">
      <a href="https://www.facebook.com/MTPEPERU" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/FB.png" />
      </a>
      <a href="https://instagram.com/mtpe_peru?igshid=YmMyMTA2M2Y=" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IG.png" />
      </a>
      <a href="https://twitter.com/MTPE_Peru?t=ucOn7RjBVRUvDKO9hBqnKQ&s=08" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/TW.png" />
      </a>
      <a href="https://www.linkedin.com/company/mtpe-peru/" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IN.png" />
      </a>
      <a href="https://www.tiktok.com/@mtpeperu?lang=es" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/Tiktok.png" />
      </a>
    </p>
  </div>
  <div class="col-lg-6">
    <div class="row header-container">
      <div class="col-lg-12">
        <!--<img src="./assets/images/banner/central-numero.png" style="width: auto;" class="logo-telefono pull-right"
          alt="Comunícate con nosotros llamando al 0800 71707" />-->
      </div>
    </div>
  </div>
</div>
<div class="accesibility-theme row container-empresa" style="margin-bottom: 50px;">
  <p class="accesibility" style="font-size: 24px; font-weight: 600; color: #3d3d3d;">Accede a nuestros servicios para empresas en un clic</p>
</div>
<div class="accesibility-theme row container-empresa">
  <div class="col-lg-3"></div>
  <div class="col-lg-2" style="border: 1px #932222 dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoCul()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #932222; border: 1px #932222 solid;">
        <i class="fa fa-file-text-o icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility">Verifica en linea la autenticidad del <span style="color: #932222; font-weight: bold;">Certificado Único Laboral</span> de tus candidatos</p>
  </div>
  <div class="col-lg-2" style="border: 1px #CC0000 dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoLoginPortal()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #CC0000;border: 1px #CC0000 solid;">
        <i class="fas fa-users icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility">Publica tus <span style="color: #CC0000; font-weight: bold;">Ofertas Laborales</span> y encuentra el talento que necesitas</p>
  </div>
  <div class="col-lg-2" style="border: 1px #ff4b00 dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoCapacitacion()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #ff4b00;border: 1px #ff4b00 solid;">
        <i class="fas fa-chalkboard-teacher icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility"><span style="color: #ff4b00; font-weight: bold;">Capacita a tus trabajadores</span> y encuentra webinars de tu interés</p>
  </div>
  <div class="col-lg-3"></div>
</div>
<div class="accesibility-theme row container-empresa">
  <div class="col-lg-3"></div>
  <div class="col-lg-2" style="border: 1px #ef870d dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoCentroEmpleo()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #ef870d;border: 1px #ef870d solid;">
        <i class="fas fa-hands-helping icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility">Accede al <span style="color: #ef870d; font-weight: bold;">Directorio de Centros de Empleo</span> e infórmate de nuestros servicios</p>
  </div>
  <div class="col-lg-2" style="border: 1px #ecb000 dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoCitas()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #ecb000;border: 1px #ecb000 solid;">
        <i class="fas fa-laptop icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility"><span style="color: #ecb000; font-weight: bold;">Citas en Línea</span> del Servicio de Acercamiento Empresarial y más.
      Realiza la reserva en el horario de tu preferencia.</p>
  </div>
  <div class="col-lg-2" style="border: 1px #585757 dashed; padding: 15px; margin-right: 20px; margin-bottom: 40px; cursor: pointer;" (click)="fngoDiscapacidad()">
    <div style="text-align: center; margin-top: -45px; margin-bottom: 20px;">
      <div class="icon-div" style="background-color: #585757;border: 1px #585757 solid;">
        <i class="fas fa-wheelchair icon-plus" style="color: #ffffff"></i>
      </div>
    </div>
    <p class="accesibility">Encuentra información referente a contratación de <span style="color: #585757; font-weight: bold;">Personas con Discapacidad (PCD)</span></p>
  </div>
  <div class="col-lg-3"></div>
  <div class="container-chat">
    <div class="btn-chat pull-right">
      <label for="btn-chat" class="btn-chat-2">
        <!--<fa-icon [icon]="faHeadeset"></fa-icon>-->
        <img class="accesibility-theme" for="btn-chat" *ngIf="!verCerrar" (click)="clickHelpContact()"
          alt="Abrir las opciones del Contact Center de Empleos Perú" src="./assets/images/top-bar/icono-contact.png"
          style="width: 55px;" />
        <img class="accesibility-theme" for="btn-chat" *ngIf="verCerrar" (click)="clickHelpContact()"
          alt="Cerrar las opciones del Contact Center de Empleos Perú" src="./assets/images/top-bar/icono-x.png"
          style="width: 55px;" />
      </label>
    </div>
  </div>
</div>
<footer class="footer-principal" style="margin-top: 5px;">
  <!--<div class="footer-callcenter">
    <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
        src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
    </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
