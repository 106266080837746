import { EmpresaValidaResponse } from './../../../models/response/EmpresaValidaResponse';
import { EmpresaService } from './../../../services/empresa.service';
import { EmpresaValidaRequest } from './../../../models/request/EmpresaValidaRequest';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { IdentidadEmpresaValidaRequest } from 'src/app/models/request/IdentidadEmpresaValidaRequest';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-registro-empresa',
  templateUrl: './registro-empresa.component.html',
  styleUrls: ['./registro-empresa.component.css']
})
export class RegistroEmpresaComponent implements OnInit {
  maxWidth = '400px';
  isLoading = false;
  isError = false;
  isLogueado = false;
  isAuthenticated = false;
  UserName = '';
  tiposDocumento: any[];
  empresaModel: EmpresaValidaRequest = { sRuc: '', sCiiuPrincipal: '', sFechaRegistroSunat: '', sSid: '' };
  // tslint:disable-next-line: max-line-length
  empresaIdentidadModel: IdentidadEmpresaValidaRequest = { nInDocIdentidad: 0, sCiiuPrincipal: '', sNuDocIdentidad: '', sFechaCaducidad: '', bIndicadorNoCaducidad: false, sRuc: '', sFechaRegistroSunat: '' };
  empresaValidaResponse: EmpresaValidaResponse = { bExisteUsuario: false, empresa: null, token: null, infoContacto: null };
  // tslint:disable-next-line: max-line-length
  constructor(private helper: HelperService, private sessionService: SessionService, private empresaService: EmpresaService, private routes: Router) { }

  ngOnInit(): void {
    this.tiposDocumento = [
      {
        value: 0,
        descripcion: '-- Seleccione un tipo de documento --'
      },
      {
        value: 10080399,
        descripcion: 'DNI'
      },
      {
        value: 10080305,
        descripcion: 'CE'
      },
      {
        value: 10080306,
        descripcion: 'PTP'
      },
      {
        value: 10080307,
        descripcion: 'CPP'
      }
    ];
  }
  onSubmit(): void {
    if (this.isLogueado) {
      if (this.empresaModel.sFechaRegistroSunat === null || this.empresaModel.sFechaRegistroSunat === '') {
        Swal.fire({
          title: '¡Alerta!',
          text: 'Ingrese la fecha de inscripción en sunat.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.isLoading = false;
        });
      } else {
        const model: EmpresaValidaRequest = {
          sRuc: this.empresaModel.sRuc,
          sCiiuPrincipal: this.empresaModel.sCiiuPrincipal,
          sFechaRegistroSunat: this.empresaModel.sFechaRegistroSunat.split('-').join(''),
          sSid: this.sessionService.getCookie('SID')
        };
        this.isLoading = true;
        this.empresaService.postValidarEmpresa(model).subscribe(
          response => {
            if (response.succes) {
              Swal.fire(
                {
                  title: '¡Éxito!',
                  text: 'La verificación fue correcta.',
                  icon: 'success',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Continuar registro'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                  sessionStorage.clear();
                  this.empresaValidaResponse = response.data;
                  sessionStorage.setItem('tokenEmpresa', this.empresaValidaResponse.token.token);
                  sessionStorage.setItem('empresaModel', JSON.stringify(this.empresaValidaResponse.empresa));
                  sessionStorage.setItem('infoContactoModel', JSON.stringify(this.empresaValidaResponse.infoContacto));
                  this.routes.navigate(['/registro-empresa-info']);
                }
              });
            } else {
              Swal.fire(
                {
                  title: '¡Alerta!',
                  text: response.description,
                  icon: 'warning',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Aceptar'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                }
              });
              return false;
            }
          },
          error => {
            Swal.fire({
              title: '¡Error!',
              text: 'Inconvenientes al acceder a los servicios de verificación, intente nuevamente en unos minutos',
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
          }
        );
      }
    } else {
      this.empresaIdentidadModel.sFechaRegistroSunat = this.empresaModel.sFechaRegistroSunat;
      this.empresaIdentidadModel.sRuc = this.empresaModel.sRuc;
      if (this.empresaIdentidadModel.sFechaRegistroSunat === null || this.empresaIdentidadModel.sFechaRegistroSunat === '') {
        Swal.fire({
          title: '¡Alerta!',
          text: 'Ingrese la fecha de inscripción en sunat.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.isLoading = false;
        });
        return;
      }
      if (!this.empresaIdentidadModel.bIndicadorNoCaducidad) {
        if (this.empresaIdentidadModel.sFechaCaducidad === null || this.empresaIdentidadModel.sFechaCaducidad === '') {
          Swal.fire({
            title: '¡Alerta!',
            text: 'Ingrese la fecha de caducidad del documento.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
          });
          return;
        }
      }
      const model: IdentidadEmpresaValidaRequest = {
        nInDocIdentidad: +this.empresaIdentidadModel.nInDocIdentidad,
        sNuDocIdentidad: this.empresaIdentidadModel.sNuDocIdentidad,
        sFechaCaducidad: this.empresaIdentidadModel.sFechaCaducidad.split('-').join(''),
        bIndicadorNoCaducidad: this.empresaIdentidadModel.bIndicadorNoCaducidad,
        sCiiuPrincipal: '',
        sRuc: this.empresaIdentidadModel.sRuc,
        sFechaRegistroSunat: this.empresaIdentidadModel.sFechaRegistroSunat.split('-').join(''),
      };
      this.isLoading = true;
      this.empresaService.postValidarIdentidad_Empresa(model).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'La verificación fue correcta.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Continuar registro'
              }
            ).then((result) => {
              if (result.value) {
                this.isLoading = false;
                this.isError = false;
                sessionStorage.clear();
                this.empresaValidaResponse = response.data;
                sessionStorage.setItem('tokenEmpresa', this.empresaValidaResponse.token.token);
                sessionStorage.setItem('bExisteUsuario', this.empresaValidaResponse.bExisteUsuario.toString());
                sessionStorage.setItem('empresaModel', JSON.stringify(this.empresaValidaResponse.empresa));
                sessionStorage.setItem('infoContactoModel', JSON.stringify(this.empresaValidaResponse.infoContacto));
                this.routes.navigate(['/registro-empresa-info']);
              }
            });
          } else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            ).then((result) => {
              if (result.value) {
                this.isLoading = false;
                this.isError = false;
              }
            });
            return false;
          }
        },
        error => {
          Swal.fire({
            title: '¡Error!',
            text: 'Inconvenientes al acceder a los servicios de verificación, intente nuevamente en unos minutos',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            console.log(error);
          });
        }
      );
    }
  }
  notCaracteresEspeciales(event): boolean {
    let k;
    k = event.charCode;
    // tslint:disable-next-line: max-line-length
    return ((k >= 64 && k < 91) || (k > 96 && k <= 123) || k == 8 || k == 44 || k == 193 || k >= 200 && k <= 250 || (k >= 48 && k <= 57));
  }
}
