<h3 class="accesibility-theme title-nuevo-usuario">Bienvenido/a al registro de empresas</h3>
<div class="nuevo-usuario-MTPE">
  <form (ngSubmit)="onSubmit()" #nuevaEmpresaForm="ngForm">
    <div class="row">
      <div class="form-group">
        <p class="subtitulo-formulario"><span class="accesibility-theme circulo-amarillo">1</span> Información de
          Empresa</p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="sRUC" class="texto-bold label-formulario">RUC<span class="asterik-required">*</span></label>
        <input type="text" id="sRUC" name="sRUC" class="form-control" disabled [(ngModel)]="empresaModel.sRUC" required
          pattern="[0-9]{11}" placeholder="Ingrese su número de RUC" maxlength="11" #sRUC="ngModel">
      </div>
      <div class="form-group col-lg-8">
        <label for="sRazonSocial" class="texto-bold label-formulario">Razón Social<span
            class="asterik-required">*</span></label>
        <input type="text" id="sRazonSocial" name="sRazonSocial" class="form-control" disabled
          [(ngModel)]="empresaModel.sRazonSocial" required placeholder="Ingrese su número de RUC"
          #sRazonSocial="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="sCIIU" class="texto-bold label-formulario">CIIU<span class="asterik-required">*</span></label>
        <input type="text" id="sCIIU" name="sCIIU" class="form-control" disabled [(ngModel)]="empresaModel.sCIIU"
          required pattern="[0-9]{11}" maxlength="11" #sCIIU="ngModel">
      </div>
      <div class="form-group col-lg-8">
        <label for="sUbigeo" class="texto-bold label-formulario">Ubigeo<span class="asterik-required">*</span></label>
        <input type="text" id="sUbigeo" name="sUbigeo" class="form-control" disabled [(ngModel)]="empresaModel.sUbigeo"
          required #sUbigeo="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-2">
        <label for="sActivo" class="texto-bold label-formulario">Activo<span class="asterik-required">*</span></label>
        <input type="text" id="sActivo" name="sActivo" class="form-control" disabled [(ngModel)]="empresaModel.sActivo"
          required #sActivo="ngModel">
      </div>
      <div class="form-group col-lg-2">
        <label for="SHabido" class="texto-bold label-formulario">Habido<span class="asterik-required">*</span></label>
        <input type="text" id="SHabido" name="SHabido" class="form-control" disabled [(ngModel)]="empresaModel.SHabido"
          required #SHabido="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12">
        <label for="sDireccion" class="texto-bold label-formulario">Dirección<span
            class="accesibility-theme asterik-required">*</span></label>
        <input type="text" id="sDireccion" name="sDireccion" class="form-control" disabled
          [(ngModel)]="empresaModel.sDireccion" required placeholder="Ingrese su dirección" #sDireccion="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label for="sTelefono" class="texto-bold label-formulario">Teléfono de la empresa<span
            class="asterik-required">*</span></label>
        <input type="tel" id="sTelefono" name="sTelefono" class="form-control" [disabled]="isLoading"
          [(ngModel)]="empresaModel.sTelefono" required pattern="[0-9]{6,12}" placeholder="Ingrese su teléfono"
          #sTelefono="ngModel">
        <div *ngIf="!sTelefono.valid && sTelefono.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
          Por favor verifique que sea un teléfono válido.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <p class="subtitulo-formulario"><span class="accesibility-theme circulo-amarillo">2</span> Información de
          Contacto</p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="sNumeroDocumento" class="texto-bold label-formulario">DNI/CE/PTP<span
            class="asterik-required">*</span></label>
        <input type="text" id="sNumeroDocumento" name="sNumeroDocumento" class="form-control" disabled
          [(ngModel)]="empresaModel.InfoContacto.sNumeroDocumento" required #sNumeroDocumento="ngModel">
      </div>
      <div class="form-group col-lg-8">
        <label for="sNombreCompleto" class="texto-bold label-formulario">Nombre Completo<span
            class="asterik-required">*</span></label>
        <input type="text" id="sNombreCompleto" name="sNombreCompleto" class="form-control" disabled
          [(ngModel)]="empresaModel.InfoContacto.sNombreCompleto" required #sNombreCompleto="ngModel">
      </div>
    </div>
    <!--<div class="row">
      <div class="form-group col-lg-4">
        <label for="sCelular" class="texto-bold label-formulario">Celular<span class="asterik-required">*</span></label>
        <input type="text" id="sCelular" name="sCelular" class="form-control" disabled
          [(ngModel)]="empresaModel.InfoContacto.sCelular" required #sCelular="ngModel">
      </div>
      <div class="form-group col-lg-8">
        <label for="sCorreoPersona" class="texto-bold label-formulario">Correo Contacto<span
            class="asterik-required">*</span></label>
        <input type="text" id="sCorreoPersona" name="sCorreoPersona" class="form-control" disabled
          [(ngModel)]="empresaModel.InfoContacto.sCorreoPersona" required #sCorreoPersona="ngModel">
      </div>
    </div>-->
    <div class="row">
      <div class="form-group col-lg-8">
        <label for="sCorreoPersona">Correo Contacto <span class="asterik-required">* </span>
          <button type="button" class="btn btn-sm btn-outline" placement="top"
            ngbTooltip="Este correo estará vinculado a su cuenta de empresa.">
            <i class="fas fa-info-circle" style="font-size: 16px;color: #CC0000;"></i>
          </button>
        </label>
        <div class="input-group">
          <input type="email" name="sCorreoPersona" id="sCorreoPersona" required
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" [disabled]="isLoading"
            [(ngModel)]="empresaModel.InfoContacto.sCorreoPersona" class="form-control"
            placeholder="Ingrese su correo electrónico: ejemplo@ejemplo.com" #sCorreoPersona="ngModel"
            (keypress)="notCaracteresEspeciales($event)">
          <div class="input-group-append">
            <button class="btn btn-success" [disabled]="!sCorreoPersona.valid && sCorreoPersona.touched" type="button"
              (click)="sendEmail()">Enviar código</button>
          </div>
        </div>
        <div *ngIf="!sCorreoPersona.valid && sCorreoPersona.touched && !isLoading"
          class="alert alert-danger alert-danger-MTPE">
          Por favor verifique que sea un correo válido.
        </div>
      </div>
      <div class="form-group col-lg-4">
        <label for="sCodeCorreo" class="texto-bold label-formulario">Código Correo<span
            class="asterik-required">*</span></label>
        <input type="text" id="sCodeCorreo" name="sCodeCorreo" class="form-control" [disabled]="isLoading"
          [(ngModel)]="empresaModel.InfoContacto.sCodeCorreo" required placeholder="Ingrese su código" #sCodeCorreo="ngModel">
        <div *ngIf="!sCodeCorreo.valid && sCodeCorreo.touched && !isLoading"
          class="alert alert-danger alert-danger-MTPE">
          Por favor ingrese el código enviado a su correo.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-8">
        <label for="sCorreoEmpresa">Celular
          <button type="button" class="btn btn-sm btn-outline" placement="top"
            ngbTooltip="Este celular estará vinculado a su cuenta de empresa.">
            <i class="fas fa-info-circle" style="font-size: 16px;color: #CC0000;"></i>
          </button>
        </label>
        <div class="input-group">
          <input type="text" name="sCelular" id="sCelular" required
            pattern="[0-9]{6,12}" [disabled]="isLoading"
            [(ngModel)]="empresaModel.InfoContacto.sCelular" class="form-control"
            placeholder="Ingrese el celular de contacto empresa" #sCelular="ngModel"
            (keypress)="notCaracteresEspeciales($event)">
        </div>
      </div>
    </div>
    <div *ngIf="!existeUsuario" class="row">
      <div class="form-group col-lg-6">
        <label for="sPassword">Contraseña <span class="asterik-required">*</span></label>
        <input type="password" name="sPassword" id="sPassword" required minlength="6" [disabled]="isLoading"
          [(ngModel)]="empresaModel.InfoContacto.sPassword" class="form-control" #sPassword="ngModel">
        <div *ngIf="!sPassword.valid && sPassword.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
          Debe ingresar una contraseña, debe ser mínimo de 6 caracteres.
        </div>
      </div>
      <div class="form-group col-lg-6">
        <label for="sRetryPassword">Repetir contraseña <span class="asterik-required">*</span></label>
        <input type="password" name="sRetryPassword" id="sRetryPassword" required [disabled]="isLoading"
          [(ngModel)]="empresaModel.InfoContacto.sRetryPassword" (ngModelChange)="matchPassword($event)"
          class="form-control" #sRetryPassword="ngModel">
        <div *ngIf="!isMatchPassword && !isLoading" class="alert alert-danger alert-danger-MTPE">
          Las contraseñas no coinciden.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12">
        <a class="accesibility-theme" [routerLink]="['/terminos-condiciones-e']" (click)="fnsaveDatos()"
          style="font-size: 14px; font-weight: bold;">Ver términos y condiciones</a>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-check col-lg-12" style="margin-left: 15px;">
        <input type="checkbox" [(ngModel)]="isAcceptTerminos" class="form-check-input" id="isAcceptTerminos"
          name="isAcceptTerminos">
        <label class="form-check-label" for="sesionCheck">Acepto los términos y condiciones</label>
      </div>
    </div>
    <div style="text-align: center;margin-bottom: 80px;">
      <button type="submit" class="col-lg-3 btn btn-success"
        [disabled]="nuevaEmpresaForm.form.invalid || isLoading || !isAcceptTerminos" style="background-color: #219653;">
        Registrar Empresa
        <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
      </button>
    </div>
  </form>
</div>
<footer class="footer-principal footer-principal-verify">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
