import { UsuarioService } from './../../../services/usuario.service';
import { PersonaValidaRequest } from '../../../models/PersonaValidaRequest';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PersonaValidaResponse } from 'src/app/models/response/PersonaValidaResponse';
import { ResetPasswordValidarIdentidadResponse } from 'src/app/models/response/ResetPasswordValidarIdentidadResponse';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit {

  routeModel: { sToken: string };
  // tslint:disable-next-line: max-line-length
  usuarioModel: PersonaValidaRequest =
    {
      nInDocIdentidad: 10080399,
      sNuDocIdentidad: '',
      sFechaNacimiento: '',
      sFechaCaducidad: '',
      bIndicadorNoCaducidad: false,
      sUbigeo: ''
    };
  tiposDocumento: any[];
  usuarioValidaResponse: PersonaValidaResponse;
  resetPasswordValidaResponse: ResetPasswordValidarIdentidadResponse;
  mensajeError: string;
  isError = false;
  isLoading = false;
  isAcceptTerminos = false;
  isDni = true;
  isCaducidad = false;
  isNacimiento = false;
  isNro = false;
  isUbigeo = false;
  isExtranjero = false;
  maxWidth = '800px';

  isNullUndefinesOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  constructor(private usuarioService: UsuarioService, private routes: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeModel = {
      sToken: this.currentRoute.snapshot.params.sToken
    };
    this.currentRoute.params.subscribe(
      (params: Params) => {
        this.routeModel.sToken = params.sToken;
      }
    );

    const invitado = this.currentRoute.snapshot.queryParamMap.get('invitacion');
    const tokenEmpleador = this.currentRoute.snapshot.queryParamMap.get('token');
    sessionStorage.setItem('typeCode', invitado);
    sessionStorage.setItem('tokenInvitado', tokenEmpleador);
    /**
     * Función para cargar los datos al ver terminos y condiciones.
     */
    const usuarioModel = sessionStorage.getItem('usuarioModel');
    if (usuarioModel !== null && usuarioModel !== '' && usuarioModel !== undefined) {
      const objUser = JSON.parse(usuarioModel);
      this.usuarioModel.nInDocIdentidad = objUser.nInDocIdentidad;
      this.usuarioModel.sNuDocIdentidad = objUser.sNuDocIdentidad;
      this.usuarioModel.sFechaNacimiento = objUser.sFechaNacimiento;
      this.usuarioModel.sFechaCaducidad = objUser.sFechaCaducidad;
      this.usuarioModel.bIndicadorNoCaducidad = objUser.bIndicadorNoCaducidad;
      this.usuarioModel.sUbigeo = objUser.sUbigeo;
      sessionStorage.setItem('usuarioModel', '');
    }
    this.tiposDocumento = [
      {
        value: 0,
        descripcion: '-- Seleccione un tipo de documento --'
      },
      {
        value: 10080399,
        descripcion: 'DNI'
      },
      {
        value: 10080305,
        descripcion: 'CE'
      },
      {
        value: 10080306,
        descripcion: 'PTP'
      },
      {
        value: 10080307,
        descripcion: 'CPP'
      }
    ];

  }
  fnsaveDatos(): void {
    sessionStorage.setItem('usuarioModel', JSON.stringify(this.usuarioModel));
  }
  notCaracteresEspeciales(event): boolean {
    let k;
    k = event.charCode;
    // tslint:disable-next-line: max-line-length
    return ((k >= 64 && k < 91) || (k > 96 && k <= 123) || k === 8 || k === 44 || k === 193 || k >= 200 && k <= 250 || (k >= 48 && k <= 57));
  }
  onSelectedChangedTipoDocumento(tipoDocumento: number): void {
    if (+tipoDocumento !== 10080399 && +tipoDocumento !== 0) {
      this.isExtranjero = true;
      this.usuarioModel.sNuDocIdentidad = '';
      this.maxWidth = '400px';
    }
    else {
      this.isExtranjero = false;
      this.usuarioModel.sNuDocIdentidad = '';
      this.maxWidth = '800px';
    }
  }
  onSubmit(): void {
    if (!this.isNullUndefinesOrEmpty(this.routeModel.sToken)){
      if (this.routeModel.sToken !== sessionStorage.getItem('sTokenRP').toString()){
        Swal.fire({
          title: '¡Alerta!',
          text: 'Código de verificación inválido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.isLoading = false;
          this.routes.navigate(['']);
          return;
        });
      }
    }
    if (+this.usuarioModel.nInDocIdentidad === 0) {
      this.isError = true;
      this.mensajeError = 'Debe escoger un tipo de documento antes de registrarse.';
      return;
    }
    if (!this.isAcceptTerminos) {
      this.isError = true;
      this.mensajeError = 'Debe Aceptar los terminos y condiciones, antes de registrarse';
    }
    // tslint:disable-next-line: max-line-length
    else if (!this.usuarioModel.bIndicadorNoCaducidad && (this.usuarioModel.sFechaCaducidad === null || this.usuarioModel.sFechaCaducidad === '')) {
      Swal.fire({
        title: '¡Alerta!',
        text: 'Ingrese la fecha de caducidad.',
        icon: 'warning',
        confirmButtonColor: '#B0261C',
        confirmButtonText: 'Aceptar'
      }).then(() => {
        this.isLoading = false;
      });
    }
    else {
      const years = new Date().getFullYear() - new Date(this.usuarioModel.sFechaNacimiento).getFullYear();
      const yearsExpire = new Date(this.usuarioModel.sFechaCaducidad).getFullYear() - new Date().getFullYear();
      if (+this.usuarioModel.nInDocIdentidad === 10080399){
        /*if ((yearsExpire > 10 || yearsExpire < -2)) {
          Swal.fire({
            title: '¡Alerta!',
            text: 'Fecha de caducidad inválida.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
          });
          return;
        }*/
      }
      else{
        /*if (new Date(this.usuarioModel.sFechaCaducidad) < new Date()) {
          Swal.fire({
            title: '¡Alerta!',
            text: 'El documento tiene la fecha de caducidad vencida.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
          });
          return;
        }*/
      }
      if (years < 18 || years > 101) {
        Swal.fire({
          title: '¡Alerta!',
          text: 'Fecha de nacimiento no permitida.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.isLoading = false;
        });
      }
      else {
        const model: PersonaValidaRequest = {
          nInDocIdentidad: +this.usuarioModel.nInDocIdentidad,
          sNuDocIdentidad: this.usuarioModel.sNuDocIdentidad,
          sFechaNacimiento: this.usuarioModel.sFechaNacimiento.split('-').join(''),
          sFechaCaducidad: this.usuarioModel.bIndicadorNoCaducidad ? '' : this.usuarioModel.sFechaCaducidad.split('-').join(''),
          bIndicadorNoCaducidad: this.usuarioModel.bIndicadorNoCaducidad,
          sUbigeo: this.usuarioModel.sUbigeo
        };
        this.isLoading = true;
        if (!this.isNullUndefinesOrEmpty(this.routeModel.sToken)){
          this.usuarioService.postResetPasswordValidar(model, this.routeModel.sToken).subscribe(
            response => {
              if (response.succes) {
                Swal.fire(
                  {
                    title: '¡Éxito!',
                    text: 'La verificación fue correcta.',
                    icon: 'success',
                    confirmButtonColor: '#B0261C',
                    confirmButtonText: 'Continuar registro'
                  }
                ).then((result) => {
                  if (result.value) {
                    this.isLoading = false;
                    this.isError = false;
                    this.resetPasswordValidaResponse = response.data;
                    sessionStorage.setItem('sTokenRP', this.resetPasswordValidaResponse.token.token);
                    sessionStorage.setItem('numeroDocumento', this.resetPasswordValidaResponse.sNuDocIdentidad);
                    sessionStorage.setItem('tipoDocumento', this.resetPasswordValidaResponse.nInDocIdentidad.toString());
                    // tslint:disable-next-line: max-line-length
                    this.routes.navigate(['/nueva-contrasenia/' + sessionStorage.getItem('sTokenRP').toString()]);
                  }
                });
              }
              else {
                Swal.fire(
                  {
                    title: '¡Alerta!',
                    text: response.description,
                    icon: 'warning',
                    confirmButtonColor: '#B0261C',
                    confirmButtonText: 'Aceptar'
                  }
                ).then((result) => {
                  if (result.value) {
                    this.isLoading = false;
                    this.isError = false;
                  }
                });
                return false;
              }
            },
            error => {
              Swal.fire({
                title: '¡Error!',
                text: 'Inconvenientes al acceder a los servicios de verificación, intente nuevamente en unos minutos',
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }).then(() => {
                this.isLoading = false;
                console.log(error);
              });
            }
          );
        }
        else {
          this.usuarioService.postUsuarioValidarIdentidad(model).subscribe(
            response => {
                if (response.succes) {
                  Swal.fire(
                    {
                      title: '¡Éxito!',
                      text: 'La verificación fue correcta.',
                      icon: 'success',
                      confirmButtonColor: '#B0261C',
                      confirmButtonText: 'Continuar registro'
                    }
                  ).then((result) => {
                    if (result.value) {
                      this.isLoading = false;
                      this.isError = false;
                      this.usuarioValidaResponse = response.data;
                      sessionStorage.setItem('tokenIdentidad', this.usuarioValidaResponse.token);
                      sessionStorage.setItem('numeroDocumento', this.usuarioModel.sNuDocIdentidad);
                      sessionStorage.setItem('tipoDocumento', this.usuarioModel.nInDocIdentidad.toString());
                      this.routes.navigate(['/nueva-contrasenia']);
                    }
                  });
                }
                else {
                  Swal.fire(
                    {
                      title: '¡Alerta!',
                      text: response.description,
                      icon: 'warning',
                      confirmButtonColor: '#B0261C',
                      confirmButtonText: 'Aceptar'
                    }
                  ).then((result) => {
                    if (result.value) {
                      this.isLoading = false;
                      this.isError = false;
                    }
                  });
                  return false;
                }
            },
            error => {
              Swal.fire({
                title: '¡Error!',
                text: 'Inconvenientes al acceder a los servicios de verificación, intente nuevamente en unos minutos',
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }).then(() => {
                this.isLoading = false;
                console.log(error);
              });
            }
          );
        }
      }
    }

  }

  changedFotoDNI(): void {
    this.isDni = false;
    this.isNro = true;
    this.isCaducidad = false;
    this.isNacimiento = false;
    this.isUbigeo = false;
  }
  changedFotoUbigeo(): void {
    this.isDni = false;
    this.isNro = false;
    this.isCaducidad = false;
    this.isNacimiento = false;
    this.isUbigeo = true;
  }
  changedFotoCaducidad(): void {
    this.isDni = false;
    this.isNro = false;
    this.isCaducidad = true;
    this.isNacimiento = false;
    this.isUbigeo = false;
  }
  changedFotoNacimiento(): void {
    this.isDni = false;
    this.isNro = false;
    this.isCaducidad = false;
    this.isNacimiento = true;
    this.isUbigeo = false;
  }

  changedFoto(): void {
    this.isDni = true;
    this.isNro = false;
    this.isCaducidad = false;
    this.isNacimiento = false;
    this.isUbigeo = false;
  }
}
