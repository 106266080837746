<div class="row margin-container accesibility-theme">
  <div class="col-lg-6">
    <p class="header-titel-mtpe header-container">Empleos Perú</p>
    <p style="margin-top: -10px;">
      <a href="https://www.facebook.com/MTPEPERU" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/FB.png" />
      </a>
      <a href="https://instagram.com/mtpe_peru?igshid=YmMyMTA2M2Y=" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IG.png" />
      </a>
      <a href="https://twitter.com/MTPE_Peru?t=ucOn7RjBVRUvDKO9hBqnKQ&s=08" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/TW.png" />
      </a>
      <a href="https://www.linkedin.com/company/mtpe-peru/" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IN.png" />
      </a>
      <a href="https://www.tiktok.com/@mtpeperu?lang=es" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/Tiktok.png" />
      </a>

    </p>
  </div>
  <div class="col-lg-6">
    <div class="row container-responsive">
      <p class="col-sm-12 header-titel-mtpe accesibility" style="text-align: center;">Empleos Perú</p>
      <p class="col-sm-12 accesibility" style="color: #CC0000; font-weight: bold; text-align: center;font-size: 1.2em;">
        Encuentra tu
        próximo empleo</p>
      <p class="col-sm-12" style="color: #4b4b4b; font-weight: bold; text-align: center;font-size: 1.2em;">¡Regístrate!
      </p>
      <p class="col-sm-12 accesibility" style="text-align: center;">
        <a class="accesibility col-lg-5 btn btn-sm btn-danger"
          style="margin-bottom: 15px;background-color: #CC0000;color: #fff; padding: 10px; font-size: 16px;"
          (click)="fnclickPersonas()">
          Ver vacantes
        </a>
        <a class="accesibility col-lg-6 btn btn-sm btn-danger"
          style="background-color: #CC0000;color: #fff; padding: 10px;font-size: 16px;"
          [routerLink]="[ '/empresa-home']">
          Servicios para empresas
        </a>
      </p>
      <div class="col-sm-12" style="margin-top: 20px;">
        <div class="input-group">
          <input class="form-control py-2 border-right-0 border" id="querySearch" name="querySearch" type="search"
            [(ngModel)]="querySearch" placeholder="Buscar empleo">
          <span class="input-group-append">
            <a class="btn btn-outline border-left-0 border" style="background-color: white;" type="button">
              <i class="fa fa-search" style="color: #CC0000;"></i>
            </a>
          </span>
        </div>
        <div class="input-group">
          <input class="form-control py-2 border-right-0 border" id="querySearch" name="querySearch" type="search"
            [(ngModel)]="whereSearch" placeholder="Dónde">
          <span class="input-group-append">
            <a class="btn btn-outline border-left-0 border" style="background-color: white;" type="button">
              <i class="fas fa-map-marker-alt" style="color: #CC0000;"></i>
            </a>
          </span>
        </div>
        <div class="input-group">
          <span class="input-group-append" style="width: 100%;">
            <a class="accesibility btn btn-danger btn-sm col-sm-12"
              style="background-color: #CC0000; color: #fff; margin-top: 10px; padding-top: 5px;" type="button" (click)="fnBuscarOferta()">
              Buscar
            </a>
          </span>
        </div>
      </div>
    </div>
    <div class="row header-container">
      <div class="col-lg-12">
        <!--<img src="./assets/images/banner/central-numero.png" style="width: auto;" class="logo-telefono pull-right"
          alt="Comunícate con nosotros llamando al 0800 71707" />-->
      </div>
    </div>
    <div class="row header-container">
      <div class="col-lg-4">
        <button type="button" class="accesibility btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickBolsaTrabajo()">Bolsa de
          Trabajo</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="accesibility btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCul()">Certificado
          Único Laboral</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="accesibility btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCapacitate()">Capacítate</button>
      </div>
    </div>
  </div>
</div>
<section class="jumbotron-MTPE accesibility-theme header-container accesibility-theme"
  style="background-color: #fff !important;">
  <!--<div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 text-center" style="padding-left: 150px; padding-right: 150px;">
      <p style="color: #fff; font-size: 24px; font-weight: bold;background-color: #CC0000;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;">Regístrate</p>
    </div>
  </div>-->
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 text-center" style="padding-left: 50px; padding-right: 50px; padding-top: 80px;">
      <p class="accesibility" style="color: #CC0000; font-size: 50px; font-weight: bold;line-height: 60px;">¡Encuentra
        tu próximo empleo!</p>
      <p class="accesibility" style="color: #000; font-size: 25px; font-weight: bold;">Miles de vacantes disponibles</p>
      <p style="margin-top: 50px;">
        <a class="accesibility col-lg-5 btn btn-sm btn-danger"
          style="margin-right: 15px;background-color: #CC0000;color: #fff; padding: 10px; font-size: 16px; font-weight: bold;"
          (click)="fnclickPersonas()">
          Ver vacantes
        </a>
        <a class="accesibility col-lg-6 btn btn-sm btn-danger"
          style="background-color: #CC0000;color: #fff; padding: 10px;font-size: 16px; font-weight: bold;"
          [routerLink]="[ '/empresa-home']">
          Servicios para empresas
        </a>
      </p>
    </div>
  </div>
</section>
<div class="row header-container accesibility-theme" style="margin-top: 25px; margin-bottom: 40px;">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <div class="input-group">
      <input class="form-control py-2 border-right-0 border" id="querySearch" name="querySearch" type="search"
        [(ngModel)]="querySearch" placeholder="Buscar empleo">
      <span class="input-group-append" style="padding: 10px; border: 1px #e5e5e5 solid">
        <i class="fa fa-search" style="color: #CC0000;"></i>
      </span>
      <input class="form-control py-2 border-right-0 border" id="whereSearch" name="whereSearch" type="search"
        [(ngModel)]="whereSearch" placeholder="Dónde">
      <span class="input-group-append" style="padding: 10px; border: 1px #e5e5e5 solid">
        <i class="fas fa-map-marker-alt" style="color: #CC0000;"></i>
      </span>
      <span class="input-group-append">
        <a class="accesibility btn btn-danger btn-sm" style="background-color: #CC0000; color: #fff; padding-top: 7px;"
          type="button" (click)="fnBuscarOferta()">
          Buscar
        </a>
      </span>
    </div>
    <div class="text-center accesibility" style="margin-top: 20px;">
      <p style="color: #CC0000; font-size: 20px;">Explora las ofertas laborales que tenemos para ti</p>
    </div>
  </div>
  <div class="col-lg-3"></div>
</div>
<ng-template #modalCul let-modal>
  <div class="modal-body">
    <a href="#" style="border: none" target="_blank">
      <img src="./assets/images/banner/flyer03.png" style="width: 100%;" />
    </a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
<ng-template #modalBolsa let-modal>
  <div class="modal-body">
    <a href="#" style="border: none" target="_blank">
      <img src="./assets/images/banner/flyer37.png" style="width: 100%;" />
    </a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
