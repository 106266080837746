<div class="row margin-container container-responsive accesibility-theme" style="margin-top: 70px;">
  <div class="col-lg-6">
    <p class="header-titel-mtpe header-container">Empleos Perú</p>
    <p style="margin-top: -10px;">
      <a href="https://www.facebook.com/MTPEPERU" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/FB.png" />
      </a>
      <a href="https://instagram.com/mtpe_peru?igshid=YmMyMTA2M2Y=" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IG.png" />
      </a>
      <a href="https://twitter.com/MTPE_Peru?t=ucOn7RjBVRUvDKO9hBqnKQ&s=08" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/TW.png" />
      </a>
      <a href="https://www.linkedin.com/company/mtpe-peru/" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IN.png" />
      </a>
      <a href="https://www.tiktok.com/@mtpeperu?lang=es" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/Tiktok.png" />
      </a>
    </p>
  </div>
  <div class="col-lg-6">
    <div class="row header-container">
      <div class="col-lg-12">
        <!--<img src="./assets/images/banner/central-numero.png" style="width: auto;" class="logo-telefono pull-right"
          alt="Comunícate con nosotros llamando al 0800 71707" />-->
      </div>
    </div>
    <div class="row header-container">
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickBolsaTrabajo()">Bolsa de
          Trabajo</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCul()">Certificado
          Único Laboral</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCapacitate()">Capacítate</button>
      </div>
    </div>
  </div>
</div>
<div class="container-MTPE accesibility-theme" style="margin-top: 20px; background-color: #f3f3f3;">
  <p class="header-titel-mtpe header-container" style="font-size: 30px;">Preguntas Frecuentes</p>
</div>
<div class="container-MTPE accesibility-theme" style="margin-top: 20px; height: 1250px;">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Bolsa de Trabajo</a>
      <ng-template ngbNavContent>
        <div class="tabs">
          <div class="tab" style="margin-top: 30px;">
            <input type="checkbox" id="chck1">
            <label class="tab-label" for="chck1" style="margin-bottom: 0;">¿Cómo me registro en la Bolsa de
              Trabajo?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Tienes que darle clic a <b>"Crear cuenta" </b>y completar los datos de tu DNI, PTP o carnet de
                extranjería. Verifica tu identidad y da clic en “Continuar”. Ingresa tu celular y correo electrónico.
                Valida los códigos de confirmación en cada caso. Crea una contraseña de 8 caracteres
                segura y fácil de recordar y ¡listo! Recuerda que es muy importante que completes todo tu perfil para
                aumentar tus oportunidades laborales.
                Puedes ver el paso a paso del registro en el siguiente tutorial:
                <br><a style="cursor: pointer;" target="_blank"
                  href="https://youtu.be/lLYdsPB_4nM">https://youtu.be/lLYdsPB_4nM</a>
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck2">
            <label class="tab-label" for="chck2" style="margin-bottom: 0;">¿Cómo puedo recuperar mi usuario y/o
              contraseña?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                1. Haz clic en la opción “Ingresar” ubicada en la parte superior derecha del portal Empleos Perú.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                2. Haz clic en “¿Olvidaste tu contraseña?”.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                3. Selecciona el Tipo documento, ingresa el número de tu Documento de identidad registrado y haz clic en
                “Verificar
                datos”, aparecerá el mensaje “¡Éxito! Solicitud verificada con éxito”, seleccione una de las opciones
                para recuperar su
                contraseña”, haz clic en “Aceptar”.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                4. En “Escoja el medio a donde se le enviará el link para reestablecer su contraseña”, elige y marca la
                opción que desees: celular o correo.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                5. Haz clic en “Enviar Solicitud” y aparecerá el mensaje
                “¡Éxito! Solicitud enviada con éxito” haz clic en “Aceptar”.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                6. Revisa el mensaje que llegó a tu celular o correo electrónico y
                haz clic en el enlace para recuperar tu contraseña.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                7. En la sección “Contraseña”, coloca tu contraseña y repite la
                contraseña, haz clic en “Aceptar” y aparecerá el mensaje
                “¡Éxito! El cambio de contraseña fue correcto”, haz clic en
                “Aceptar” y aparecerá la ventana para iniciar sesión e ingresar
                a tu cuenta.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck3">
            <label class="tab-label" for="chck3" style="margin-bottom: 0;">¿Puedo postular a una oferta laboral si no he
              completado todo mi perfil?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Para poder postular a alguna oferta laboral de la Bolsa de Trabajo de Empleos Perú es necesario que
                completes los 5 campos principales del tu perfil: Información personal, Empleo deseado, Experiencia,
                Formación y Habilidades. Con esta información en tu perfil podrás postular a todas las ofertas laborales
                disponibles a nivel nacional. Asimismo, la Bolsa de Trabajo podrá sugerirte empleos que coincidan con tu
                experiencia, habilidades e intereses, reduciendo así tu tiempo de búsqueda.
                Puedes ver el paso a paso del cómo postular a una oferta laboral en el siguiente tutorial:
                <br><a style="cursor: pointer;" target="_blank"
                  href="https://www.youtube.com/watch?v=kxd-kSwi1SE&feature=emb_logo">https://www.youtube.com/watch?v=kxd-kSwi1SE&feature=emb_logo</a>
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck4">
            <label class="tab-label" for="chck4" style="margin-bottom: 0;">¿Hay oportunidades laborales para personas
              con discapacidad (PCD)?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí, para poder ver las ofertas laborales exclusivas para personas con discapacidad debes registrarte y
                completar tu perfil. En esta parte es muy importante que completes el "Registro de discapacidad" para
                que puedas acceder a todas las ofertas laborales exclusivas.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck5">
            <label class="tab-label" for="chck5" style="margin-bottom: 0;">¿Puedo postular si no tengo
              experiencia?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. La Bolsa de Trabajo con inteligencia artificial no solo considera tu experiencia, sino también tus
                habilidades e intereses. Por ello es muy importante que completes todo tu perfil, para que esta
                tecnología pueda recomendarte más ofertas laborales que coicidan contigo.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck6">
            <label class="tab-label" for="chck6" style="margin-bottom: 0;">¿Hay ofertas laborales para regiones?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. En el portal Empleos Perú encontrarás oportunidades laborales para todas regiones del país. Te
                recomendamos ingresar a la Bolsa de Trabajo y usar los filtros de búsqueda de acuerdo a tu región e
                intereses.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck7">
            <label class="tab-label" for="chck7" style="margin-bottom: 0;">¿Hay prácticas pre-profesionales y/o
              profesionales?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                No. En la Bolsa de Trabajo de Empleos Perú no se publican prácticas pre profesionales ni profesionales.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck8">
            <label class="tab-label" for="chck8" style="margin-bottom: 0;">¿Las convocatorias son todas privadas o
              también vamos a encontrar oportunidades del Estado?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                En la Bolsa de Trabajo de Empleos Perú podrás encontrar y postular a ofertas laborales de diversas
                empresas del sector privado. También difundimos ofertas laborales del sector público.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck9">
            <label class="tab-label" for="chck9" style="margin-bottom: 0;">¿Las empresas que están publicando son de
              todos los rubros o de alguno específico?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Las ofertas laborales son de todos los rubros o sectores de empresas e instituciones. En Empleos Perú
                contamos con oportunidades laborales para diversos perfiles profesionales. Te recomendamos usar los
                filtros de búsqueda de acuerdo a tus intereses.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck10">
            <label class="tab-label" for="chck10" style="margin-bottom: 0;">¿Qué debo hacer si tengo problemas para
              registrarme o usar mi perfil?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                <!--Comunícate a nuestra línea gratuita 0800 71 707 para darte el soporte que necesitas. También -->
                Puedes comunicarte vía chat con un asesor en línea, con nuestra asistente virtual "Carmen" o por videollamada,
                a través de nuestros nuevos canales de atención. Puedes acceder a ellos dando clic al ícono flotante
                rojo ubicado en la parte inferior derecha del portal Empleos Peú.
                Si requieres atención presencial, puedes visitar el Centro de Empleo de tu región, donde te
                proporcionarán diversos servicios de empleo y empleabilidad, además de los servicios virtuales del
                portal Empleos Perú.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck11">
            <label class="tab-label" for="chck11" style="margin-bottom: 0;">¿Cualquier empresa puede registrarse en el
              portal y publicar ofertas laborales?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. Todas las empresas pueden registrarse en Empleos Perú y publicar ofertas laborales de manera
                ilimitada y totalmente gratis.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck12">
            <label class="tab-label" for="chck12" style="margin-bottom: 0;">¿Cómo me registro como empresa en el
              portal?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Tienes que darle clic a "Ingresar como empresa" y luego a "Registrar empresa". Completa todos los datos
                de tu empresa y dale clic a "Validar información". Tu cuenta será aprobada en un día hábil.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck13">
            <label class="tab-label" for="chck13" style="margin-bottom: 0;">¿Puedo publicar ofertas laborales de manera
              ilimitada?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. Puedes publicar ofertas laborales de manera ilimitada y totalmente gratis.
                <br>Puedes ver el paso a paso de cómo publicar una oferta laboral en el siguiente tutorial:
                <br>
                <a style="cursor: pointer;" target="_blank"
                  href="https://youtu.be/yfgywhf6t5w">https://youtu.be/yfgywhf6t5w</a>
              </p>
            </div>
          </div>
          <div class="tab" style="margin-bottom: 20px;">
            <input type="checkbox" id="chck15">
            <label class="tab-label" for="chck15" style="margin-bottom: 0;">¿Cómo publico un aviso en el portal?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                1. Haz clic en la opción “Empresas”. Ingresa a tu cuenta o crea una cuenta con los datos de tu empresa.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                2. Ingresa a la sección "Mis avisos" y haz clic en "Nuevo puesto".
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                3. Completa todos los datos de la nueva vacante.
              </p>
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                4. Debes enviar el aviso para aprobación. Responderemos en un día hábil.
                <br>
                <br>
                Puedes ver el paso a paso de cómo publicar una oferta laboral en el siguiente tutorial:
                <br>
                <a style="cursor: pointer;" target="_blank"
                  href="https://youtu.be/yfgywhf6t5w">https://youtu.be/yfgywhf6t5w</a>
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Certificado Único Laboral</a>
      <ng-template ngbNavContent>
        <div class="tabs">
          <div class="tab" style="margin-top: 30px;">
            <input type="checkbox" id="chck1">
            <label class="tab-label" for="chck1" style="margin-bottom: 0;">¿Qué es el Certificado Único Laboral
              (CUL)?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Es un documento OFICIAL y GRATUITO que reúne la información que usualmente piden las empresas cuando
                postulas a un puesto de trabajo. Por ejemplo, tu identidad, antecedentes policiales, judiciales y
                penales, así como tu trayectoria educativa y experiencia laboral formal.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck2">
            <label class="tab-label" for="chck2" style="margin-bottom: 0;">¿La información que presenta el Certificado
              Único Laboral (CUL) es oficial? </label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. Toda la información que presenta el Certificado Único Laboral es extraída de la Plataforma de
                Interoperabilidad del Estado. Precisamente, el Artículo 8 del Decreto Supremo N° 083-2011-PCM establece
                que la información contenida en esta plataforma tiene carácter oficial.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck4">
            <label class="tab-label" for="chck4" style="margin-bottom: 0;">¿Qué información contiene el Certificado
              Único Laboral (CUL)?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                El Certificado Único Laboral contiene:
                <br>Datos de identidad (brindados por RENIEC): Número del Documento Nacional de Identidad (DNI)
                <br>Si registra o no antecedentes policiales (brindados por la PNP).
                <br>Si registra o no antecedentes penales (brindados por el Poder Judicial).
                <br>Si registra o no antecedentes judiciales (brindados por el INPE).
                <br>Trayectoria educativa:
                <br>Grados y/o títulos en formación universitaria (brindados por la SUNEDU).
                <br>Grados y/o títulos en educación superior pedagógica, tecnológica y artística (brindados por MINEDU y
                SUNEDU, cuando corresponda).
                <br>Títulos en educación técnico - productiva (brindado por MINEDU).
                <br>Nivel alcanzado en educación básica (brindado por MINEDU).
                <br>Trayectoria laboral formal (brindado por el MTPE a partir de la información del Sistema de Planillas
                Electrónicas).
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck5">
            <label class="tab-label" for="chck5" style="margin-bottom: 0;">¿Generar el Certificado Único Laboral (CUL)
              tiene algún costo?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                No. El trámite es TOTALMENTE GRATUITO y vía digital a través del portal empleosperu.gob.pe
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck6">
            <label class="tab-label" for="chck6" style="margin-bottom: 0;">¿Cuál es la vigencia del Certificado Único
              Laboral (CUL)?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Tres meses.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck7">
            <label class="tab-label" for="chck7" style="margin-bottom: 0;">¿Cuántas veces puedo generar el CUL?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                El Certificado único Laboral (CUL) puede generarse todas las veces que lo necesites, pero se recomienda
                generarlo cada tres meses. En el portal se archivarán el último CUL que haya sido descargado por lo que
                podrás visualizarlo y usarlo cuando lo requieras.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck8">
            <label class="tab-label" for="chck8" style="margin-bottom: 0;">¿Cómo puedo actualizar la información de mi
              Certificado Único Laboral (CUL)?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                La información se actualiza automáticamente en cada uno de los sistemas de las institiciones que
                proporcionan la información. Si necesitas ayuda, puedes comunicarte con nuestra línea gratuita 0800 717
                07. También puedes comunicarte vía chat con un asesor en línea, con nuestra asistente virtual "Carmen" o
                por videollamada a través de nuestros nuevos canales de atención. Puedes acceder a ellos dando clic al
                ícono flotante rojo ubicado en la parte inferior derecha del portal Empleos Peú.
              </p>
            </div>
          </div>
          <div class="tab" style="margin-bottom: 20px;">
            <input type="checkbox" id="chck9">
            <label class="tab-label" for="chck9" style="margin-bottom: 0;">¿Como empresa, puedo verificar la
              autenticidad de los certificados únicos laborales que presentan los postulantes?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí, las empresas pueden verificar la autenticidad del CUL que han emitido y presentado las personas a
                través del portal.
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Capacitación</a>
      <ng-template ngbNavContent>
        <div class="tabs">
          <div class="tab" style="margin-top: 30px;">
            <input type="checkbox" id="chck1">
            <label class="tab-label" for="chck1" style="margin-bottom: 0;">¿Cómo puedo acceder a los cursos de
              capacitación? </label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Regístrate en el portal Empleos Perú. Si ya te registraste, ingresa con tu usuario y contraseña,
                selecciona el curso de tu interés y dale clic a "Empezar curso". En caso el curso lo ofrezca alguna
                institución aliada, completa la información que te pidan, y listo.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck2">
            <label class="tab-label" for="chck2" style="margin-bottom: 0;">¿Todos los cursos son gratuitos?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí. Todos los cursos de capacitación disponibles en el portal Empleos Perú son TOTALMENTE GRATUITOS.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck3">
            <label class="tab-label" for="chck3" style="margin-bottom: 0;">Si ya terminé un curso, ¿cómo obtengo mi
              certificado?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Al finalizar y aprobar el curso, aparecerá una opción para obtener tu certificado de inmediato y
                gratuito.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck4">
            <label class="tab-label" for="chck4" style="margin-bottom: 0;">¿Qué son las rutas de aprendizaje?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Son una agrupación de cursos que nos permitirán desarrollar o fortalecer las competencias en una
                determinada ocupación laboral, o para la gestión de un negocio propio.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck5">
            <label class="tab-label" for="chck5" style="margin-bottom: 0;">¿Qué puedo hacer si tengo problemas para
              acceder a un curso?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                <!--Comunícate a nuestra línea gratuita 0800 71 707 para darte el soporte que necesitas. También -->
                Puedes comunicarte vía chat con un asesor en línea, con nuestra asistente virtual "Carmen" o por videollamada a
                través de nuestros nuevos canales de atención. Puedes acceder a ellos dando clic al ícono flotante rojo
                ubicado en la parte inferior derecha del portal Empleos Peú.
              </p>
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="chck6">
            <label class="tab-label" for="chck6" style="margin-bottom: 0;">¿Todos los webinars son gratuitos?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí,  los webinars están disponibles a toda la ciudadanía, con el objetivo de fortalecer sus competencias para la empleabilidad.
              </p>
            </div>
          </div>
          <div class="tab" style="margin-bottom: 20px;">
            <input type="checkbox" id="chck7">
            <label class="tab-label" for="chck7" style="margin-bottom: 0;">¿Las empresas pueden usar el servicio de capacitación laboral para sus postulantes o trabajadiores?</label>
            <div class="tab-content">
              <p style="color: #000; font-size: 16px; text-align: justify; margin-left: 30px; margin-right: 30px;">
                Sí, las empresas pueden usar el servicio de capacitación laboral virtual. Para poder usarlo cada persona debe registrarse en el portal y llevar los cursos de su interés.
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<footer class="footer-principal accesibility-theme" style="margin-top: 5px;">
  <!--<div class="footer-callcenter">
    <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
        src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
    </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
