import { LoginEmpresaRequest } from './../models/request/LoginEmpresaRequest';
import { Observable } from 'rxjs';
import { StatusResponse } from './../models/StatusResponse';
import { LoginResponse } from './../models/response/LoginResponse';
import { environment } from './../../environments/environment';
import { LoginRequest } from '../models/request/LoginRequest';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl = environment.baseUrl;
  loginUri = '/login';
  loginEmpresaUri = '/login/empresa';
  constructor(private http: HttpClient) { }

  postLoginEmpresa(request: LoginEmpresaRequest): Observable<StatusResponse<LoginResponse>>{
    return this.http.post<StatusResponse<LoginResponse>>(this.baseUrl + this.loginEmpresaUri, request);
  }
  postLogin(request: LoginRequest): Observable<StatusResponse<LoginResponse>>{
    return this.http.post<StatusResponse<LoginResponse>>(this.baseUrl + this.loginUri, request);
  }
}
