<nav class="accesibility-theme navbar navbar-expand-lg fixed-top navbar-dark nav-bar-MTPE">
  <div class="row">
    <div class="col-lg-12">
      <img class="accesibility-theme logo-gob-pe" src="./assets/images/top-bar/logo-gob-pe.png"
        alt="logo gob punto pe" />
      <span class="logo-gob-pe-text">gob.pe</span>
      <span class="separator-menu"></span>
      <a class="accesibility navbar-brand nav-bar-text-ministerio" href="#">
        Ministerio de Trabajo y Promoción del Empleo
      </a>
    </div>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ml-auto nav-bar-text-MTPE">
      <li class="nav-item" style="border-right: 1px solid #fff;">
        <div class="btn-group" style="margin-right: 5px;">
          <button type="button" class="accesibility btn btn-danger dropdown-toggle" style="background-color: #B0261C; border: none;"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Nosotros
          </button>
          <div class="dropdown-menu">
            <a class="accesibility dropdown-item" [routerLink]="[ '/centro-empleo']">Centro de Empleo</a>
            <a class="accesibility dropdown-item" [routerLink]="[ '/preguntas-frecuentes']">Preguntas Frecuentes</a>
          </div>
        </div>
      </li>
      <li class="nav-item active" style="border-right: 1px solid #fff; margin-left: 5px;" [hidden]="isAuthenticatedEmpresa">
        <a class="accesibility nav-link nav-link-MTPE" [routerLink]="['login-empresa']" style="margin-right: 5px;">Ingresar como empresa</a>
      </li>
      <li class="nav-item active" style="border-right: 1px solid #fff;" [hidden]="!isAuthenticatedEmpresa">
        <a style="cursor:pointer;" class="accesibility nav-link nav-link-MTPE" (click)="fnGoEmpresa()">Empresa</a>
      </li>
      <li class="nav-item active" style="color: #fff !important;">
        <a style="margin-right: 5px;" class="accesibility nav-link nav-link-MTPE" [hidden]="isAuthenticated"
          [routerLink]="[ '/nuevo-usuario']">Crear Cuenta</a>
      </li>
      <li class="nav-item">
        <a class="accesibility btn btn-md btn-light btn-ingresar" style="border-radius: 0%;" [hidden]="isAuthenticated"
          [routerLink]="[ '/login']">Ingresar</a>
      </li>
      <li class="nav-item">
        <div class="btn-group" [hidden]="!isAuthenticated">
          <button type="button" class="accesibility btn btn-danger dropdown-toggle" style="background-color: #B0261C;"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ UserName }}
          </button>
          <div class="dropdown-menu">
            <a class="accesibility dropdown-item" (click)="fnGoPerfil()">Perfil</a>
            <a class="accesibility dropdown-item" [routerLink]="[ '/actualizar-perfil']">Configuración</a>
            <a class="accesibility dropdown-item" (click)="cerrarSesion()">Cerrar Sesión</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="row container-subheader-responsive" style="font-weight: bold;margin-left: 0;">
  <span class="col-sm-4" (click)="fnclickBolsaTrabajo()">Bolsa de Trabajo</span>
  <span class="col-sm-4">|</span>
  <span class="col-sm-4" (click)="fnclickCul()">CUL</span>
  <span class="col-sm-4">|</span>
  <span class="col-sm-4" (click)="fnclickCapacitate()">Capacítate</span>
</div>
<div class="row container-subheader-responsive">
  <!--<img src="./assets/images/banner/central-numero.png" style="width: 100%;" class="logo-telefono"
  alt="Comunícate con nosotros llamando al 0800 71707" />-->
</div>
<div class="tools-accesibility row">
  <div class="col-md-4 col-lg-4 col-sm-12"></div>
  <div class="col-md-4 col-lg-4 col-sm-12" style="text-align: center;">
    <a id="btnPlayVoice" class="accesibility-theme btn btn-sm btn-danger" style="background-color: #CC0000; margin-right: 5px;">
      <i class="fas fa-volume-up" style="color: #fff;"></i>
    </a>
    <a id="btnStopVoice" class="accesibility-theme btn btn-sm btn-danger" style="background-color: #CC0000; margin-right: 5px;">
      <i class="fas fa-volume-mute" style="color: #fff;"></i>
    </a>
    <a id="btnPlusFont" class="accesibility-theme btn btn-sm btn-danger" style="background-color: #CC0000; margin-right: 5px;">
      <i class="fas fa-font" style="color: #fff;"></i>
      <i class="fas fa-plus" style="color: #fff; font-size: 8px;"></i>
    </a>
    <a id="btnMinusFont" class="accesibility-theme btn btn-sm btn-danger" style="background-color: #CC0000; margin-right: 5px;">
      <i class="fas fa-font" style="color: #fff;"></i>
      <i class="fas fa-minus" style="color: #fff; font-size: 8px;"></i>
    </a>
    <a id="btnThemeGray" class="accesibility-theme btn btn-sm btn-danger" style="background-color: #CC0000; margin-right: 5px;">
      <i class="fas fa-adjust" style="color: #fff;"></i>
    </a>
  </div>
  <div class="col-md-4 col-lg-4 col-sm-12"></div>
</div>
<ng-template #modalCul let-modal>
  <div class="modal-body">
    <a href="#" style="border: none" target="_blank">
      <img src="./assets/images/banner/flyercul.png" style="width: 100%;" />
    </a>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
