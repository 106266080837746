<ng-template #contenido let-modal>
  <div class="modal-body">
    <!--<a href="http://192.168.171.96/#/crm-mtpe/registro-participante/1" style="border: none">-->
      <img src="./assets/images/banner/comunicado0402.png" style="width: 100%;" />
    <!--</a>-->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
<ng-template #contenido let-modal>
  <div class="modal-body"
    style="background-image: url('./assets/images/banner/fondo.png'); background-repeat: no-repeat;background-size: cover;">
    <div>
      <p class="title-popup"
        style="color: #CC0000; font-size: 44px; font-weight: bold; text-align: center; margin-bottom: 10px;">¡Empleos
        Perú se renovó!
      </p>
      <p class="subtitle-popup"
        style="margin-left: 100px; margin-right: 100px; font-size: 28px; font-weight: bold; text-align: center;">
        Ya iniciamos la <span style="color: #CC0000">marcha blanca</span>
        de la nueva bolsa de trabajo con
        <span style="color: #CC0000">inteligencia artificial</span>
      </p>
    </div>
    <div class="row" style="margin-left: 10px; margin-right: 10px;">
      <div class="col-lg-7 col-sm-12">
        <p class="text-popup"
          style="font-size: 18px;font-weight: bold; font-family: 'Roboto Light'; margin: 20px 40px 0px 10px;">Encuentra
          empleos adecuados a tu perfil,
          habilidades e intereses.
        </p>
        <div class="row" style="margin: 0; padding: 0">
          <div class="col-lg-12" style="margin-top: 20px;">
            <a class="btn btn-danger btn-small textbutton-popup" style="color: #fff; background-color: #CC0000;"
              href="https://www.empleosperu.gob.pe/portal-mtpe/#/nuevo-usuario" target="_blank">
              Ingresa/regístrate y completa tu perfil
            </a>
          </div>
        </div>
        <p class="text-popup"
          style="font-size: 18px;font-weight: bold; font-family: 'Roboto Light'; margin: 20px 0px 0px 10px;">
          Y si eres un empleador tendrás más postulantes que se ajusten a tus vacantes
        </p>
        <div class="row" style="margin: 0; padding: 0">
          <div class="col-lg-12" style="margin-top: 20px;">
            <a class="btn btn-danger btn-small textbutton-popup" style="color: #fff; background-color: #CC0000;"
              href="https://www.empleosperu.gob.pe/portal-mtpe/#/registro-empresa" target="_blank">
              ¡Registra los datos de tu empresa!
            </a>
          </div>
        </div>
      </div>
      <div class="img-hide col-lg-5">
        <img src="./assets/images/banner/mano.png" style="width: 100%; image-rendering: -webkit-optimize-contrast;" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <img src="./assets/images/banner/footer.png" style="width: 100%; image-rendering: -webkit-optimize-contrast;" />
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>
<app-banner></app-banner>
<div class="container-MTPE accesibility-theme" style="background-color: #E5E5E5;margin-top: 20px;">
  <div class="row">
    <div class="col-lg-12">
      <app-servicios></app-servicios>
      <app-ofertas-laborales></app-ofertas-laborales>
      <div class="row">
        <div class="col-lg-12 pull-right">
          <p>Ingresar como <span style="color: #1565C0; cursor: pointer;" (click)="fngoConsultor()">Consultor de empleo</span></p>
        </div>
      </div>
      <div class="row" *ngIf="verAplicaciones">
        <div class="col-lg-12 pull-right">
          <a style="cursor: pointer;" [routerLink]="[ '/aplicaciones']">Ingresar a las Aplicaciones Disponibles</a>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-12 pull-right">
          <p><span style="color: #1565C0; cursor: pointer;" (click)="fnGoCRM()">Ingresar al CRM</span></p>
        </div>
      </div> -->
      <!--<div class="row">
        <div class="col-lg-12 pull-right">
          <p>Ingresar como <span style="color: #1565C0; cursor: pointer;" (click)="fnGoEmpleabilidad()">Consultor de Empleo (Empleabilidad)</span></p>
        </div>
      </div>-->
      <div>
        <h5 class="accesibility title-servicios-MTPE">Tutoriales</h5>
        <div class="row">
          <div class="col-lg-6"
            *ngFor="let item of videos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <iframe class="accesibility-theme col-lg-12" style="padding-left: 0; padding-right: 0;height: 200px;" [src]="item.url"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <ngb-pagination style="margin-top: 15px; margin-left: 14px; font-size: 12px;" [collectionSize]="videos.length"
        [(page)]="page" [pageSize]="pageSize" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
    </div>
    <!--<div class="col-lg-4">
      <div>
        <h5 class="accesibility title-servicios-MTPE">Videos publicitarios</h5>
        <div class="row">
          <div class="col-lg-12"
            *ngFor="let item of videospublicitarios | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <iframe class="accesibility-theme col-lg-12" style="padding-left: 0; padding-right: 0;height: 200px;" [src]="item.url"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
        <ngb-pagination class="accesibility-theme" style="margin-top: 15px; margin-left: 14px; font-size: 12px;"
          [collectionSize]="videospublicitarios.length" [(page)]="page" [pageSize]="pageSize" [rotate]="true"
          [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>-->
  </div>
  <div class="container-chat">
    <input type="checkbox" id="btn-chat" accesskey="c" alt="Opción de ayuda con asesor en línea." />
    <div class="option-chat">
      <a href="https://mtpe2.apps.sisenep-app.pe/clicktointeract/chat.aspx?oneContactInstance=mtpe2&motive=chatbot"
      class="asesor-chat" target="_blank" accesskey="b">
      <img class="accesibility-theme" src="./assets/images/top-bar/asist-virtual.png" style="width: 150px;" alt="Chat con asistente virtual, da clic o presiona Alt más B para ingresar"/>
    </a>
      <a href="https://mtpe2.apps.sisenep-app.pe/clicktointeract/chat.aspx?oneContactInstance=mtpe2&motive=Chat-SNE"
        class="asesor-chat" target="_blank" accesskey="h">
        <img class="accesibility-theme" src="./assets/images/top-bar/asesor-linea.png" style="width: 150px;"
          alt="Chat con asesor en linea, da clic o presiona Alt más H para ingresar" />
      </a>
    </div>
    <!--<div class="btn-chat pull-right">
      <label for="btn-chat" class="btn-chat-2">
        <img class="accesibility-theme" for="btn-chat" *ngIf="!verCerrar" (click)="clickHelpContact()"
          alt="Abrir las opciones del Contact Center de Empleos Perú" src="./assets/images/top-bar/Headset.png"
          style="width: 55px;" />
        <img class="accesibility-theme" for="btn-chat" *ngIf="verCerrar" (click)="clickHelpContact()"
          alt="Cerrar las opciones del Contact Center de Empleos Perú" src="./assets/images/top-bar/Cerrar.png"
          style="width: 55px;" />
      </label>
    </div>-->
  </div>
</div>
<footer class="footer-principal" style="margin-top: 10px;">
  <!--<div class="footer-callcenter">
    <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 15px;">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 footer-logos justify-content-center">
        <img class="accesibility-theme" src="./assets/images/banner/cintillo_web.png"
        alt="Logotipos">
      </div>
      <!--<div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme" style="height:100px" src="./assets/images/icons/logo/Pastilla-MTPE_2.png"
        alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
        <img class="accesibility-theme" style="height:100px" src="./assets/images/icons/logo/Bicentenario_2024_2.png"
        alt="Logo Bicentenario">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme footer-logo-rigth" style="height:100px"
        src="./assets/images/icons/logo/Punche_2024_2.png"
        alt="Logo Punche Perú">
      </div>-->
    </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
