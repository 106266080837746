import { environment } from './../../../../environments/environment';
import { LoginResponse } from './../../../models/response/LoginResponse';
import { SessionService } from '../../../services/session.service';
import { HelperService } from '../../../services/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginEmpresaRequest } from 'src/app/models/request/LoginEmpresaRequest';

@Component({
  selector: 'app-login-empresa',
  templateUrl: './login-empresa.component.html',
  styleUrls: ['./login-empresa.component.scss']
})
export class LoginEmpresaComponent implements OnInit {

  loginModel: LoginEmpresaRequest =
    {
      sRuc: '',
      sNumeroDocumentoIdentidad: '',
      password: '',
      nIdTipoDocumentoIdentidad: 10080399,
      params: {
        clientId: environment.clientId,
        redirectUri: environment.basePortal,
        responseType: '',
        scope: '',
        state: '',
        nonce: '',
        uiLocales: ''
      }
    };
  tiposDocumento: any[];
  loginresponse: LoginResponse;
  mensajeError: string;
  isLogueado = false;
  isAuthenticated = false;
  UserName = '';
  isError = false;
  isLoading = false;
  captcha: any;
  isNullUndefinesOrEmpty(value: any): boolean {
    return value == null || value === undefined || value === '';
  }
  resolved(captchaResponse: string): void {
    console.log(captchaResponse);
  }
  // tslint:disable-next-line: max-line-length
  constructor(private loginService: LoginService, private routes: Router, private helper: HelperService, private currentRoute: ActivatedRoute, private sessionService: SessionService) { }

  ngOnInit(): void {
    const cookie = this.sessionService.getCookie('SID');
    this.helper.isAuthenticated.subscribe(res => {
      this.isAuthenticated = res;
    });
    this.helper.UserName.subscribe(res => {
      this.UserName = res;
    });

    if (cookie !== null && cookie !== '' && cookie !== undefined) {
      this.isLogueado = true;
      Swal.fire({
        title: 'Alerta!',
        text: '¿Está seguro que desea cerrar sesión como ciudadano para acceder como empresa?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#B0261C',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if(result.isConfirmed){
          this.isLogueado = false;
          this.sessionService.getOperationSessionOauth(cookie, 'revoke').subscribe(
            response => {
              if (response.status === 'ok') {
                this.isLogueado = false;
                this.isAuthenticated = false;
                localStorage.setItem('userName', '');
                this.sessionService.setCookie('SID', '');
              }
            }, error => {
              console.log(error);
            }
          );
        }else{
          this.routes.navigate(['/']);
        }
      });
    }
    this.tiposDocumento = [
      {
        value: 0,
        descripcion: '-- Seleccione un tipo de documento --'
      },
      {
        value: 10080399,
        descripcion: 'DNI'
      },
      {
        value: 10080305,
        descripcion: 'CE'
      },
      {
        value: 10080306,
        descripcion: 'PTP'
      },
      {
        value: 10080307,
        descripcion: 'CPP'
      }
    ];
    if (!this.isNullUndefinesOrEmpty(this.currentRoute.snapshot.queryParamMap.get('client_id'))) {
      this.loginModel.params.responseType = this.currentRoute.snapshot.queryParamMap.get('response_type');
      this.loginModel.params.redirectUri = this.currentRoute.snapshot.queryParamMap.get('redirect_uri');
      this.loginModel.params.clientId = this.currentRoute.snapshot.queryParamMap.get('client_id');
      this.loginModel.params.scope = this.currentRoute.snapshot.queryParamMap.get('scope');
      this.loginModel.params.state = this.currentRoute.snapshot.queryParamMap.get('state');
      this.loginModel.params.nonce = this.currentRoute.snapshot.queryParamMap.get('nonce');
      this.loginModel.params.uiLocales = this.currentRoute.snapshot.queryParamMap.get('ui_locales');
    }

    const userIVR = this.currentRoute.snapshot.queryParamMap.get('ope');
    const crcIVR = this.currentRoute.snapshot.queryParamMap.get('crc');
    const sessionIdToken = this.currentRoute.snapshot.queryParamMap.get('user');
    if (!this.isNullUndefinesOrEmpty(sessionIdToken)) {
      if (!this.isNullUndefinesOrEmpty(userIVR) && !this.isNullUndefinesOrEmpty(crcIVR)) {
        /*const request = {
          sessionId: sessionIdToken,
          userIvr: userIVR,
          crc: crcIVR
        };
        console.log('request: ' + JSON.stringify(request));
        this.loginService.postVerifySessionId(request).subscribe(
          response => {
            if (response.data !== null){
              localStorage.setItem('foreign',response.data.datosPostulante.foreign);
              this.helper.UserName.next(response.data.datosPostulante.name);
              this.helper.isAuthenticated.next(true);
              this.sessionService.setSession('sessionId', sessionIdToken);
              this.routes.navigate(['']);
            }else{
              const session = this.sessionService.getSession('sessionId');
              if (!this.isNullUndefinesOrEmpty(session)){
                if (this.isNullUndefinesOrEmpty(this.urlCallBack) && this.isNullUndefinesOrEmpty(this.urlRedirect)){
                  this.routes.navigate(['']);
                }else{
                  if (this.isNullUndefinesOrEmpty(this.urlCallBack)){
                    window.location.href = this.urlRedirect;
                  }else{
                    window.location.href = this.urlCallBack + '?token=' + session + '&urlRedirect=' + this.urlRedirect;
                  }
                }
              }
            }
          }
        );*/
      }
    } else {
      const cookie = this.sessionService.getCookie('SIDe');
      if (!this.isNullUndefinesOrEmpty(cookie)){
        if(this.loginModel.params.clientId === 'portal-mtpe'){
          window.location.href = this.loginModel.params.redirectUri;
        }else{
          // tslint:disable-next-line: max-line-length
          this.sessionService.getOperationSessionOauthRedirect(cookie, 'check', this.loginModel.params.nonce, this.loginModel.params.clientId).subscribe(
            response => {
              if (response.status === 'valid'){
                window.location.href = this.loginModel.params.redirectUri + '?code='+response.code+'&nonce='+this.loginModel.params.nonce+'&state='+this.loginModel.params.state;
              }
            }
          );
        }
      }
    }

  }
  onSubmit(): void {
    this.isLoading = true;
    this.loginService.postLoginEmpresa(this.loginModel).subscribe(
      response => {
        if (response.succes) {
          this.isLoading = false;
          this.isError = false;
          this.loginresponse = response.data;
          this.sessionService.setCookie('SIDe', this.loginresponse.sSid.toString());
          Swal.fire(
            {
              title: '¡Bienvenido!',
              text: 'Inicio de sesión exitoso.',
              icon: 'success',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          ).then(() => {
            this.helper.isAuthenticatedEmpresa.next(this.loginresponse.bLogeado);
            this.sessionService.setCookie('SIDe', this.loginresponse.sSid.toString(), '/', environment.sidBaseUrl);
            if (this.loginModel.params.clientId === 'portal-mtpe'){
              window.location.href = environment.baseEmpresa;
            }else {
              window.location.href = this.loginresponse.urlRedirect;
            }
          });

        } else {
          this.isLoading = false;
          this.isError = false;
          Swal.fire(
            {
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          );
        }
      },
      error => {
        Swal.fire({
          title: '¡Error!',
          text: error,
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.value) {
            console.log(error);
            this.mensajeError = error;
            this.isLoading = false;
            this.isError = true;
          }
        });
      });
  }
}
