import { SessionService } from './../../../services/session.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  querySearch: string;
  whereSearch: string;
  @ViewChild('modalCul') private modalCul;
  @ViewChild('modalBolsa') private modalBolsa;

  isNullUndefinesOrEmpty(value: string): boolean {
    return value === null || value === undefined || value === '';
  }
  constructor(private sessionService: SessionService, private modal: NgbModal) { }

  ngOnInit(): void {
  }
  fnBuscarOferta(): void{
      if (this.whereSearch !== null && this.whereSearch !== undefined && this.whereSearch !== ''){
        window.open(environment.baseWcc + '/search-jobs?what=' + this.querySearch + '&where=' + this.whereSearch, '_blank');
      }
      else{
        window.open(environment.baseWcc + '/search-jobs?what=' + this.querySearch, '_blank');
      }
  }

  fnclickBolsaTrabajo(): void{
    window.location.href = environment.baseWcc;
    /*this.modal.open(this.modalBolsa, { size: 'lg'}).result.then(
      (result) => {
        window.location.href = environment.baseWcc;
      }
    );*/
  }

  fnclickCul(): void {

    const extranjero = sessionStorage.getItem('esExtranjero');
    if (extranjero === '1') {
      Swal.fire(
        {
          title: 'CERTIFICADO ÚNICO LABORAL',
          text: 'Temporalmente, hasta la implementación progresiva de los sistemas y bases de datos correspondientes, solo las personas extranjeras registradas en la plataforma Empleos Perú con Carnet de Extranjería podrán emitir su certificado.',
          icon: 'info',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      ).then(() => {

      });
    } else {
      window.location.href = environment.baseCUL + 'cul.html?i=' + this.sessionService.getCookie('SID');
      /*this.modal.open(this.modalCul, { size: 'lg'}).result.then(
        (result) => {
          window.location.href = environment.baseCUL + 'cul.html?i=' + this.sessionService.getCookie('SID');
        }
      );*/
    }
  }

  fnclickCapacitate(): void {
    window.location.href = 'https://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }

  fnclickPersonas(): void {
    window.location.href = environment.baseWcc + '/search-jobs';
  }
}
