import { SessionService } from './../../../services/session.service';
import { EmpresaRegistroRequest } from './../../../models/request/EmpresaRegistroRequest';
import { MailService } from './../../../services/mail.service';
import { MailRequest } from './../../../models/request/MailRequest';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EmpresaService } from 'src/app/services/empresa.service';
import { SmsRequest } from 'src/app/models/request/SmsRequest';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-registro-empresa-info',
  templateUrl: './registro-empresa-info.component.html',
  styleUrls: ['./registro-empresa-info.component.css']
})
export class RegistroEmpresaInfoComponent implements OnInit {
  maxWidth = '800px';
  isLoading = false;
  isError = false;
  isMatchPassword = true;
  isLogueado = false;
  existeUsuario = false;
  isAcceptTerminos = false;
  empresaModel = {
    sRUC: '',
    sRazonSocial: '',
    sCIIU: '',
    sUbigeo: '',
    sDireccion: '',
    sTelefono: '',
    sActivo: '',
    SHabido: '',
    InfoContacto: {
      sNumeroDocumento: '',
      sNombreCompleto: '',
      sCelular: '',
      sCodeCelular: '',
      sCorreoPersona: '',
      sCodeCorreo: '',
      sPassword: '',
      sRetryPassword: ''
    }
  };
  isNullUndefinesOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }
  constructor(private routes: Router,
    private mailService: MailService,
    private empresaService: EmpresaService,
    private sessionService: SessionService,
    private smsService: SmsService) { }

  ngOnInit(): void {
    const empresa = JSON.parse(sessionStorage.getItem('empresaModel'));
    const empresaDatos = JSON.parse(sessionStorage.getItem('empresaDatos'));
    const infoContacto = JSON.parse(sessionStorage.getItem('infoContactoModel'));
    const cookie = this.sessionService.getCookie('SID');
    this.existeUsuario = JSON.parse(sessionStorage.getItem('bExisteUsuario'));
    if (!this.isNullUndefinesOrEmpty(cookie)) {
      this.isLogueado = true;
    }
    if (empresa === null || empresa === '') {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'No se puede verificar la empresa.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      ).then((result) => {
        this.isLoading = false;
        this.isError = false;
        this.routes.navigate(['/registro-empresa']);
      });
    } else {
      this.mapEmpresaModel(empresa, infoContacto, empresaDatos);
    }
  }
  mapEmpresaModel(empresa: any, infoContacto: any, empresaDatos: any): void {
    this.empresaModel.sRUC = empresa.sRuc;
    this.empresaModel.sRazonSocial = empresa.sNombreLegal;
    this.empresaModel.sCIIU = empresa.sCiiu;
    this.empresaModel.sUbigeo = empresa.sDepartamentoDireccion + '-' + empresa.sProvinciaDireccion + '-' + empresa.sDistritoDireccion;
    this.empresaModel.sDireccion = empresa.sDireccion;
    this.empresaModel.sTelefono = empresaDatos === null ? '' : empresaDatos.sTelefono;
    this.empresaModel.sActivo = empresa.sEstadoActivo;
    this.empresaModel.SHabido = empresa.sEstadoHabido;
    this.empresaModel.InfoContacto = {
      sNumeroDocumento: infoContacto.sNumeroDocumento,
      sNombreCompleto: infoContacto.sNombreCompleto,
      sCelular: empresaDatos === null ? '' : empresaDatos.InfoContacto.sCelular,
      sCodeCelular: empresaDatos === null ? '' : empresaDatos.InfoContacto.sCodeCelular,
      sCorreoPersona: empresaDatos === null ? '' : empresaDatos.InfoContacto.sCorreoPersona,
      sCodeCorreo: empresaDatos === null ? '' : empresaDatos.InfoContacto.sCodeCorreo,
      sPassword: '',
      sRetryPassword: ''
    };
  }
  onSubmit(): void {
    if (this.isLogueado) {
      const request: EmpresaRegistroRequest = {
        sCodigoEmail: this.empresaModel.InfoContacto.sCodeCorreo,
        sDireccion: this.empresaModel.sDireccion,
        sEmail: this.empresaModel.InfoContacto.sCorreoPersona,
        sNuTelefono: this.empresaModel.sTelefono,
        sRuc: this.empresaModel.sRUC,
        sSid: this.sessionService.getCookie('SID')
      };
      this.empresaService.postRegistrarEmpresa(request).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'El registro fue correcto.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Continuar'
              }
            ).then((result) => {
              if (result.value) {
                this.isLoading = false;
                this.isError = false;
                this.routes.navigate(['login-empresa']);
              } else {
                this.isLoading = false;
                this.isError = false;
                this.routes.navigate(['login-empresa']);
              }
            });
          } else {
            Swal.fire({
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              this.isError = false;
            });
          }
        },
        error => {
          Swal.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error, por favor vuelva a intentarlo en unos minutos.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.value) {
              console.log(error);
              this.isLoading = false;
              this.isError = true;
            }
          });
        }
      );
    } else {
      const req = {
        bExisteUsuario: this.existeUsuario,
        sCodigoEmail: this.empresaModel.InfoContacto.sCodeCorreo,
        sCodigoNuTelefono: this.empresaModel.InfoContacto.sCodeCelular,
        sDireccion: this.empresaModel.sDireccion,
        sEmail: this.empresaModel.InfoContacto.sCorreoPersona,
        sEmailUsuario: this.empresaModel.InfoContacto.sCorreoPersona,
        sNuTelefono: this.empresaModel.sTelefono,
        sNuTelefonoUsuario: this.empresaModel.InfoContacto.sCelular,
        sPassword: this.empresaModel.InfoContacto.sPassword,
        sRePassword: this.empresaModel.InfoContacto.sRetryPassword,
        sRuc: this.empresaModel.sRUC
      }
      this.empresaService.postRegistrarEmpresaNL(req).subscribe(
        response => {
          if (response.succes) {
            if (req.bExisteUsuario) {
              Swal.fire(
                {
                  title: '¡Éxito!',
                  text: 'Ud. ya tiene una cuenta en el Portal Empleos Perú para acceder como Empresa, utilice la misma contraseña.',
                  icon: 'success',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Aceptar'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login-empresa']);
                } else {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login-empresa']);
                }
              });
            } else {
              Swal.fire(
                {
                  title: '¡Éxito!',
                  text: 'El registro fue correcto.',
                  icon: 'success',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Continuar'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login-empresa']);
                } else {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login-empresa']);
                }
              });
            }
          } else {
            Swal.fire({
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              this.isError = false;
            });
          }
        },
        error => {
          Swal.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error, por favor vuelva a intentarlo en unos minutos.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.value) {
              console.log(error);
              this.isLoading = false;
              this.isError = true;
            }
          });
        }
      );
    }

  }
  sendEmail(): void {
    if (this.isNullUndefinesOrEmpty(this.empresaModel.InfoContacto.sCorreoPersona)) {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'Debe ingresar un email válido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {
      const model: MailRequest = {
        nInMotivo: 0,
        sEmailTo: this.empresaModel.InfoContacto.sCorreoPersona
      };
      this.mailService.postMailEmpresaSend(model).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'Código enviado correctamente.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
          else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
        }, error => {
          Swal.fire(
            {
              title: '¡Error!',
              text: 'Ha ocurrido un error al enviar el mail, inténtelo nuevamente.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
        });
    }
  }
  sendSms(): void {
    if (this.isNullUndefinesOrEmpty(this.empresaModel.InfoContacto.sCelular)) {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'Debe ingresar un número de celular válido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {
      const smsModel: SmsRequest = {
        sNumeroCelular: this.empresaModel.InfoContacto.sCelular
      };
      console.log(smsModel);
      this.smsService.postSendSMSEmpresa(smsModel).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'Código enviado correctamente.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
          else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
        }, error => {
          Swal.fire(
            {
              title: '¡Error!',
              text: 'Ha ocurrido un error al enviar el sms, inténtelo nuevamente.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
        });
    }
  }

  fnsaveDatos(): void {
    sessionStorage.setItem('empresaDatos', JSON.stringify(this.empresaModel));
  }
  notCaracteresEspeciales(event): boolean {
    let k;
    k = event.charCode;
    // tslint:disable-next-line: max-line-length
    return ((k >= 64 && k < 91) || (k >= 95 && k <= 123) || k == 8 || k == 46 || k == 193 || k >= 200 && k <= 250 || (k >= 44 && k <= 57));
  }
  matchPassword(e): void {
    this.isMatchPassword = this.empresaModel.InfoContacto.sPassword === e;
  }
}
