<h3 class="accesibility-theme title-nueva-contrasenia">{{ titleForm }}</h3>
<div class="nueva-contrasenia-MTPE">
  <form (ngSubmit)="onSubmit()" #nuevaContraseniaForm="ngForm">
    <h6 class="subtitle-nueva-contrasenia">{{ subtitleForm }}</h6>
    <div class="form-group">
      <label for="typeDocument">Tipo de Documento</label>
      <input type="text" name="typeDocument" id="typeDocument" required
        [disabled]="isLoading || isRestablecePassword || isNewUSer" [value]="typeDocument" class="form-control"
        placeholder="Tipo de Documento">
    </div>
    <div class="form-group">
      <label for="sNuDocIdentidad">Documento de Identidad Registrado</label>
      <input type="text" name="sNuDocIdentidad" id="sNuDocIdentidad" required pattern="[0-9]{8}"
        [disabled]="isLoading || isRestablecePassword || isNewUSer" [(ngModel)]="usuarioModel.persona.sNuDocIdentidad"
        class="form-control" placeholder="Ingrese su DNI" #sNuDocIdentidad="ngModel">
    </div>
    <div class="form-group" *ngIf="isNewUSer || isIdentidadResetPassword">
      <label for="sNumeroCelular">Celular</label>
      <div class="input-group">
        <input type="tel" name="sNumeroCelular" id="sNumeroCelular" pattern="[0-9]{9}" maxlength="9"
          [disabled]="isLoading" [(ngModel)]="usuarioModel.persona.sNumeroCelular" class="form-control"
          placeholder="Ingrese su celular" #sNumeroCelular="ngModel" (keypress)="notCaracteresEspeciales($event)">
        <!--<div class="input-group-append">
          <button class="accesibility-theme btn btn-success"
            [disabled]="!sNumeroCelular.valid && sNumeroCelular.touched" type="button" (click)="sendSMS()">Enviar
            código</button>
        </div>-->
      </div>
      <!--<div *ngIf="!sNumeroCelular.valid && sNumeroCelular.touched && !isLoading"
        class="alert alert-danger alert-danger-MTPE">
        El celular es obligatorio y debe ser un número de 9 dígitos.
      </div>-->
    </div>
    <!--<div class="form-group" *ngIf="isNewUSer || isIdentidadResetPassword">
      <label for="sCodigoCelular">Código <span class="accesibility-theme asterik-required">*</span></label>
      <input type="text" name="sCodigoCelular" id="sCodigoCelular" [disabled]="isLoading" required
        [(ngModel)]="usuarioModel.persona.sCodigoCelular" maxlength="6" class="form-control"
        placeholder="Ingrese el código enviado a su número de celular" #sCodigoCelular="ngModel">
      <div *ngIf="!sCodigoCelular.valid && sCodigoCelular.touched && !isLoading"
        class="alert alert-danger alert-danger-MTPE">
        Por favor ingrese el código.
      </div>
    </div>-->
    <div class="form-group" *ngIf="isNewUSer || isIdentidadResetPassword">
      <label for="sEmail">Correo electrónico<span class="accesibility-theme asterik-required">*</span></label>
      <div class="input-group">
        <input type="email" name="sEmail" id="sEmail" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
          [disabled]="isLoading" [(ngModel)]="usuarioModel.persona.sEmail"
          class="form-control" placeholder="Ingrese su correo electrónico: ejemplo@ejemplo.com" #sEmail="ngModel"
          (keypress)="notCaracteresEspeciales($event)">
        <div class="input-group-append">
          <button class="accesibility-theme btn btn-success" [disabled]="!sEmail.valid && sEmail.touched" type="button"
            (click)="sendEmail()">Enviar código</button>
        </div>
      </div>
      <div *ngIf="!sEmail.valid && sEmail.touched && !isLoading && usuarioModel.persona.bIndicadorEmail"
        class="alert alert-danger alert-danger-MTPE">
        Por favor verifique que sea un correo válido.
      </div>
    </div>
    <div class="form-group" *ngIf="isNewUSer || isIdentidadResetPassword">
      <label for="sCodigoEmail">Código Email</label>
      <input type="text" name="sCodigoEmail" id="sCodigoEmail"
        [disabled]="isLoading || !usuarioModel.persona.bIndicadorEmail" [(ngModel)]="usuarioModel.persona.sCodigoEmail"
        maxlength="6" class="form-control" placeholder="Ingrese el código enviado a su correo electrónico"
        #sCodigoEmail="ngModel">
      <div *ngIf="!sCodigoEmail.valid && sCodigoEmail.touched && !isLoading && usuarioModel.persona.bIndicadorEmail"
        class="alert alert-danger alert-danger-MTPE">
        Por favor ingrese el código enviado a su email.
      </div>
    </div>
    <div class="form-group" *ngIf="isNewUSer && esConsultor">
      <label for="sEmail">Correo electrónico Consultor:</label>
      <div class="input-group">
        <input type="email" name="sEmailC" id="sEmailC" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
          [disabled]="isLoading || !usuarioModel.consultor.bIndicadorEmail" [(ngModel)]="usuarioModel.consultor.sEmail"
          class="form-control" placeholder="Ingrese un correo electrónico: ejemplo@ejemplo.com" #sEmailC="ngModel"
          (keypress)="notCaracteresEspeciales($event)">
        <div class="input-group-append">
          <button class="accesibility-theme btn btn-success" [disabled]="!sEmailC.valid && sEmailC.touched"
            type="button" (click)="sendEmailConsultor()">Enviar código</button>
        </div>
      </div>
      <div *ngIf="!sEmailC.valid && sEmailC.touched && !isLoading && usuarioModel.consultor.bIndicadorEmail"
        class="alert alert-danger alert-danger-MTPE">
        Por favor verifique que sea un correo válido.
      </div>
    </div>
    <div class="form-group" *ngIf="isNewUSer && esConsultor">
      <label for="sCodigoEmail">Código Email Consultor<span class="accesibility-theme asterik-required">*</span></label>
      <input type="text" name="sCodigoEmailC" id="sCodigoEmailC"
        [disabled]="isLoading || !usuarioModel.consultor.bIndicadorEmail" required
        [(ngModel)]="usuarioModel.consultor.sCodigoEmail" maxlength="6" class="form-control"
        placeholder="Ingrese el código enviado al correo electrónico como consultor" #sCodigoEmailC="ngModel">
      <div *ngIf="!sCodigoEmailC.valid && sCodigoEmailC.touched && !isLoading && usuarioModel.consultor.bIndicadorEmail"
        class="alert alert-danger alert-danger-MTPE">
        Por favor ingrese el código enviado a su email.
      </div>
    </div>
    <h4 style="text-align: center; font-weight: 600; font-size: 20px;">Contraseña</h4>
    <div class="form-group">
      <label for="sPassword">Contraseña <span class="accesibility-theme asterik-required">*</span></label>
      <input type="password" name="sPassword" id="sPassword" required minlength="6" [disabled]="isLoading"
        [(ngModel)]="usuarioModel.persona.sPassword" class="form-control" #sPassword="ngModel" 
        (ngModelChange)="validarContrasenia($event)">
      <div *ngIf="!sPassword.valid && sPassword.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
        Debe ingresar una contraseña, debe ser mínimo de 6 caracteres.
      </div> 

      <!--<div *ngIf="!validacionClave.minLength" class="alert alert-danger">
        Debe tener como mínimo 12 caracteres.
      </div>
      <div *ngIf="!validacionClave.tieneMayuscula" class="alert alert-danger">
        Debe contener al menos una letra mayúscula.
      </div>
      <div *ngIf="!validacionClave.tieneNumero" class="alert alert-danger">
        Debe contener al menos un número.
      </div>
      <div *ngIf="!validacionClave.tieneCaracterEspecial" class="alert alert-danger">
        Debe contener al menos un carácter especial.
      </div>-->

    </div>
    <div class="form-group">
      <label for="sRePassword">Repetir contraseña <span class="accesibility-theme asterik-required">*</span></label>
      <input type="password" name="sRePassword" id="sRePassword" required [disabled]="isLoading"
        [(ngModel)]="usuarioModel.persona.sRePassword" (ngModelChange)="matchPassword($event)" class="form-control"
        #sRePassword="ngModel">
      <div *ngIf="!isMatchPassword && !isLoading" class="alert alert-danger alert-danger-MTPE">
        Las contraseñas no coinciden.
      </div>
    </div>
    <div *ngIf="isError" class="alert alert-danger alert-danger-MTPE" style="font-size: 11px;" role="alert">
      {{ mensajeError }}
    </div>
    <button type="submit" [disabled]="nuevaContraseniaForm.form.invalid || isLoading || !isMatchPassword"
      class="accesibility-theme col-lg-12 btn btn-success" style="background-color: #219653;">
      Aceptar
      <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
    </button>
  </form>
</div>
<footer class="footer-principal" style="padding: 20px;background-color: white;">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
