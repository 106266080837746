import { StatusResponse } from './../models/StatusResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WccService {
  baseUrl = environment.baseUrl;
  ofertasUri = '/match-jobs?limit=';
  constructor(private http: HttpClient) { }

  getOfertasLaborales(limit: number): Observable<StatusResponse<any>> {
    return this.http.get<StatusResponse<any>>(this.baseUrl + this.ofertasUri + limit);
  }

}
