import { MailService } from './../../../services/mail.service';
import { MailRequest } from './../../../models/request/MailRequest';
import { UsuarioService } from './../../../services/usuario.service';
import { ResetPasswordRequest } from './../../../models/request/ResetPasswordRequest';
import { SmsService } from './../../../services/sms.service';
import { SmsRequest } from './../../../models/request/SmsRequest';
import { PersonaRegistroRequest } from './../../../models/request/PersonaRegistroRequest';
import { Component, OnInit } from '@angular/core';
import { LoginData } from '../../../models/LoginData';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nueva-contrasenia',
  templateUrl: './nueva-contrasenia.component.html',
  styleUrls: ['./nueva-contrasenia.component.css']
})
export class NuevaContraseniaComponent implements OnInit {
  routeModel: { sCodigo: string, sNuDocIdentidad: string, nInDocIdentidad: string, nIdAppvalIdentidad: string };
  usuarioModel: PersonaRegistroRequest = {
    persona: {
      bIndicadorEmail: true,
      nInDocIdentidad: 0,
      sCodigoCelular: '',
      sCodigoEmail: '',
      sEmail: '',
      sNuDocIdentidad: '',
      sNumeroCelular: '',
      sPassword: '',
      sRePassword: ''
    },
    consultor: {
      bIndicadorEmail: true,
      sCodigoEmail: '',
      sEmail: ''
    },
    consultorReq: false
  };
  typeDocument: string;
  smsModel: SmsRequest = { sNumeroCelular: '' };
  mailModel: MailRequest = { nInMotivo: 0, sEmailTo: '' };
  resetPasswordModel: ResetPasswordRequest = {
    nIdAppvalIdentidad: 0,
    nInDocIdentidad: 0,
    sCodigo: '',
    sNuDocIdentidad: '',
    sPassword: '',
    sRePassword: ''
  };
  loginresponse: LoginData;
  mensajeError: string;
  isError = false;
  isMatchPassword = true;
  isLoading = false;
  isNewUSer = false;
  isRestablecePassword = false;
  isIdentidadResetPassword = false;
  titleForm: string;
  subtitleForm: string;
  esConsultor = false;

  validacionClave = {
    minLength: true,
    tieneMayuscula: true,
    tieneNumero: true,
    tieneCaracterEspecial: true
  };

  contraseniaValida = false;

  isNullUndefinesOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }
  getTipoDocumento(value: number): string {
    switch (value) {
      case 10080399:
        return 'DNI';
      case 10080305:
        return 'CE';
      case 10080306:
        return 'PTP';
      case 10080307:
        return 'CPP';
      default:
        break;
    }
  }
  // tslint:disable-next-line: max-line-length
  constructor(private usuarioService: UsuarioService, private smsService: SmsService, private mailSerive: MailService, private routes: Router, private currentRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.esConsultor = JSON.parse((sessionStorage.getItem('consultor') === null || sessionStorage.getItem('consultor') === undefined) ? 'false' : sessionStorage.getItem('consultor'));
    this.routeModel = {
      sCodigo: this.currentRoute.snapshot.params.sCodigo,
      sNuDocIdentidad: this.currentRoute.snapshot.params.sNuDocIdentidad,
      nInDocIdentidad: this.currentRoute.snapshot.params.nInDocIdentidad,
      nIdAppvalIdentidad: this.currentRoute.snapshot.params.nIdAppvalIdentidad
    };
    this.currentRoute.params.subscribe(
      (params: Params) => {
        this.routeModel.sCodigo = params.sCodigo;
        this.routeModel.sNuDocIdentidad = params.sNuDocIdentidad;
        this.routeModel.nInDocIdentidad = params.nInDocIdentidad;
        this.routeModel.nIdAppvalIdentidad = params.nIdAppvalIdentidad;
      }
    );
    if (this.isNullUndefinesOrEmpty(this.routeModel.sCodigo)) {
      if (this.isNullUndefinesOrEmpty(sessionStorage.getItem('tokenIdentidad'))) {
        Swal.fire(
          {
            title: '¡Alerta!',
            text: 'Token no válido, verifique su identidad.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }
        ).then((result) => {
          if (result.value) {
            this.routes.navigate(['nuevo-usuario']);
          } else {
            this.routes.navigate(['nuevo-usuario']);
          }
        });
      } else {
        this.isNewUSer = true;
        this.usuarioModel.persona.sNuDocIdentidad = sessionStorage.getItem('numeroDocumento');
        this.usuarioModel.persona.nInDocIdentidad = +sessionStorage.getItem('tipoDocumento');
        this.typeDocument = this.getTipoDocumento(this.usuarioModel.persona.nInDocIdentidad);
        this.titleForm = 'Crear una cuenta';
        this.subtitleForm = 'Te damos la bienvenida al Portal de Empleos del Perú';
      }
    }
    else {
      this.isRestablecePassword = true;
      if (this.isNullUndefinesOrEmpty(this.routeModel.sNuDocIdentidad)) {
        this.isIdentidadResetPassword = true;
        this.usuarioModel.persona.sNuDocIdentidad = sessionStorage.getItem('numeroDocumento');
        this.usuarioModel.persona.nInDocIdentidad = +sessionStorage.getItem('tipoDocumento');
        this.typeDocument = this.getTipoDocumento(this.usuarioModel.persona.nInDocIdentidad);
        this.titleForm = 'Cambio de contraseña';
        this.subtitleForm = 'Ingrese su nueva contraseña';
      } else {
        this.usuarioModel.persona.sNuDocIdentidad = this.routeModel.sNuDocIdentidad;
        this.usuarioModel.persona.nInDocIdentidad = +this.routeModel.nInDocIdentidad;
        this.typeDocument = this.getTipoDocumento(+this.routeModel.nInDocIdentidad);
        this.titleForm = 'Cambio de contraseña';
        this.subtitleForm = 'Ingrese su nueva contraseña';
      }
    }
  }

  changedCheckBox() {
    this.usuarioModel.persona.bIndicadorEmail = !this.usuarioModel.persona.bIndicadorEmail
    if (!this.usuarioModel.persona.bIndicadorEmail) {
      this.usuarioModel.persona.sEmail = "";
    }
  }

  notCaracteresEspeciales(event): boolean {
    let k;
    k = event.charCode;
    // tslint:disable-next-line: max-line-length
    return ((k >= 64 && k < 91) || (k >= 95 && k <= 123) || k === 8 || k === 46 || k === 193 || k >= 200 && k <= 250 || (k >= 44 && k <= 57));
  }
  sendEmailConsultor(): void {
    if (this.isNullUndefinesOrEmpty(this.usuarioModel.consultor.sEmail)) {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'Debe ingresar un email válido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {
      this.mailModel.sEmailTo = this.usuarioModel.consultor.sEmail;
      this.mailModel.nInMotivo = 10140204;
      this.mailSerive.postMailSend(this.mailModel).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'Código enviado correctamente.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
          else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
        }, error => {
          Swal.fire(
            {
              title: '¡Error!',
              text: 'Ha ocurrido un error al enviar el mail, inténtelo nuevamente.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
        });
    }
  }
  sendEmail(): void {
    if (this.isNullUndefinesOrEmpty(this.usuarioModel.persona.sEmail)) {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'Debe ingresar un email válido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {
      this.mailModel.sEmailTo = this.usuarioModel.persona.sEmail;
      this.mailModel.nInMotivo = 10140202;
      this.mailSerive.postMailSend(this.mailModel).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'Código enviado correctamente.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
          else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
        }, error => {
          Swal.fire(
            {
              title: '¡Error!',
              text: 'Ha ocurrido un error al enviar el mail, inténtelo nuevamente.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
        });
    }
  }
  sendSMS(): void {
    if (this.isNullUndefinesOrEmpty(this.usuarioModel.persona.sNumeroCelular)) {
      Swal.fire(
        {
          title: '¡Alerta!',
          text: 'Debe ingresar un número de celular válido.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {
      this.smsModel.sNumeroCelular = this.usuarioModel.persona.sNumeroCelular;
      this.smsService.postSendSMS(this.smsModel).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'Código enviado correctamente.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
          else {
            Swal.fire(
              {
                title: '¡Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            );
          }
        }, error => {
          Swal.fire(
            {
              title: '¡Error!',
              text: 'Ha ocurrido un error al enviar el sms, inténtelo nuevamente.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              console.log(error);
            });
        });
    }
  }
  onSubmit(): void {
    this.isLoading = true;
    if (this.isRestablecePassword) {
      if (!this.isNullUndefinesOrEmpty(sessionStorage.getItem('sTokenRP'))) {
        if (this.usuarioModel.persona.bIndicadorEmail) {
          if (this.isNullUndefinesOrEmpty(this.usuarioModel.persona.sEmail)) {
            Swal.fire({
              title: '¡Alerta!',
              text: 'El correo electrónico es obligatorio para continuar con el registro.',
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              return;
            });
          }
        }
        const request = {
          bIndicadorEmail: this.usuarioModel.persona.bIndicadorEmail,
          nInDocIdentidad: this.usuarioModel.persona.nInDocIdentidad,
          sCodigoCelular: this.usuarioModel.persona.sCodigoCelular,
          sCodigoEmail: this.usuarioModel.persona.sCodigoEmail,
          sNuDocIdentidad: this.usuarioModel.persona.sNuDocIdentidad,
          sNumeroCelular: this.usuarioModel.persona.sNumeroCelular,
          sEmail: this.usuarioModel.persona.sEmail,
          sPassword: this.usuarioModel.persona.sPassword,
          sRePassword: this.usuarioModel.persona.sRePassword
        };
        this.usuarioService.postResetPasswordConfirmar(request, sessionStorage.getItem('sTokenRP').toString()).subscribe(
          response => {
            if (response.succes) {
              Swal.fire(
                {
                  title: '¡Éxito!',
                  text: 'El cambio de contraseña fue correcto.',
                  icon: 'success',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Aceptar'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login']);
                } else {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login']);
                }
              });
            } else {
              Swal.fire({
                title: 'Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                }
              });
            }
          },
          error => {
            Swal.fire({
              title: '¡Error!',
              text: 'Ha ocurrido un error al intentar actualizar la contraseña, intentelo nuevamente en unos minutos.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then((result) => {
              if (result.value) {
                console.log(error);
                this.mensajeError = error;
                this.isLoading = false;
                this.isError = true;
              } else {
                console.log(error);
                this.mensajeError = error;
                this.isLoading = false;
                this.isError = true;
              }
            });
          }
        );
      }
      else {
        this.resetPasswordModel.nIdAppvalIdentidad = +this.routeModel.nIdAppvalIdentidad;
        this.resetPasswordModel.nInDocIdentidad = this.usuarioModel.persona.nInDocIdentidad;
        this.resetPasswordModel.sCodigo = this.routeModel.sCodigo;
        this.resetPasswordModel.sNuDocIdentidad = this.usuarioModel.persona.sNuDocIdentidad;
        this.resetPasswordModel.sPassword = this.usuarioModel.persona.sPassword;
        this.resetPasswordModel.sRePassword = this.usuarioModel.persona.sRePassword;
        this.usuarioService.postResetPassword(this.resetPasswordModel).subscribe(
          response => {
            if (response.succes) {
              Swal.fire(
                {
                  title: '¡Éxito!',
                  text: 'El cambio de contraseña fue correcto.',
                  icon: 'success',
                  confirmButtonColor: '#B0261C',
                  confirmButtonText: 'Aceptar'
                }
              ).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login']);
                } else {
                  this.isLoading = false;
                  this.isError = false;
                  this.routes.navigate(['login']);
                }
              });
            } else {
              Swal.fire({
                title: 'Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }).then((result) => {
                if (result.value) {
                  this.isLoading = false;
                  this.isError = false;
                }
              });
            }
          },
          error => {
            Swal.fire({
              title: '¡Error!',
              text: 'Ha ocurrido un error al intentar actualizar la contraseña, intentelo nuevamente en unos minutos.',
              icon: 'error',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then((result) => {
              if (result.value) {
                console.log(error);
                this.mensajeError = error;
                this.isLoading = false;
                this.isError = true;
              } else {
                console.log(error);
                this.mensajeError = error;
                this.isLoading = false;
                this.isError = true;
              }
            });
          }
        );
      }
    } else {
      /*if (this.isNullUndefinesOrEmpty(this.usuarioModel.persona.sNumeroCelular)) {
        Swal.fire({
          title: '¡Alerta!',
          text: 'Debe ingresar un número de celular para continuar con el registro.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          this.isLoading = false;
          return;
        });
      }*/
      if (this.usuarioModel.persona.bIndicadorEmail) {
        if (this.isNullUndefinesOrEmpty(this.usuarioModel.persona.sEmail)) {
          Swal.fire({
            title: '¡Alerta!',
            text: 'El correo electrónico es obligatorio para continuar con el registro.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            return;
          });
        }
      }
      if (this.esConsultor) {
        this.usuarioModel.consultorReq = true;
      }
      if (this.esConsultor) {
        if (this.isNullUndefinesOrEmpty(this.usuarioModel.consultor.sEmail)) {
          Swal.fire({
            title: '¡Alerta!',
            text: 'El correo electrónico para consultor es obligatorio para continuar con el registro.',
            icon: 'warning',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.isLoading = false;
            return;
          });
        }
      }
      this.usuarioService.postRegistrarPersona(this.usuarioModel).subscribe(
        response => {
          if (response.succes) {
            Swal.fire(
              {
                title: '¡Éxito!',
                text: 'El registro fue correcto.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Continuar'
              }
            ).then((result) => {
              this.isLoading = false;
              this.isError = false;
              this.loginresponse = response.data;
              const invitado = sessionStorage.getItem('typeCode');
              const tokenEmpleador = sessionStorage.getItem('tokenInvitado');
              if (invitado === 'empleador') {
                window.location.href = environment.baseEmpresa + 'company/invitation/' + tokenEmpleador;
              } else {
                this.routes.navigate(['login']);
              }
            });
          } else {
            Swal.fire({
              title: '¡Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.isLoading = false;
              this.isError = false;
            });
          }
        },
        error => {
          Swal.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error, por favor vuelva a intentarlo en unos minutos.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.value) {
              console.log(error);
              this.mensajeError = error;
              this.isLoading = false;
              this.isError = true;
            }
          });
        }
      );
    }
  }
  matchPassword(e): void {
    this.isMatchPassword = this.usuarioModel.persona.sPassword === e;
  }

  validarContrasenia(password: string): void {
    this.validacionClave.minLength = password.length >= 12;
    this.validacionClave.tieneMayuscula = /[A-Z]/.test(password);
    this.validacionClave.tieneNumero = /\d/.test(password);
    this.validacionClave.tieneCaracterEspecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (this.validacionClave.minLength && this.validacionClave.tieneMayuscula 
      && this.validacionClave.tieneNumero && this.validacionClave.tieneCaracterEspecial) {
      this.contraseniaValida = true;
    }
  }

}
