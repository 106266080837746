<h3 class="title-nuevo-usuario">Configuración</h3>
<div [style.width]="maxWidth" class="nuevo-usuario-MTPE">
  <form (ngSubmit)="onSubmit()" #actualizarPerfilForm="ngForm">
    <div class="row">
        <div class="form-group col-lg-4">
          <label for="sTipoDocumento" class="texto-bold label-formulario">Tipo Documento<span
              class="asterik-required">*</span></label>
          <input type="text" id="sTipoDocumento" name="sTipoDocumento" class="form-control" disabled
            [(ngModel)]="usuarioModel.sTipoDocumento" #sTipoDocumento="ngModel">
        </div>
        <div class="form-group col-lg-8">
          <label for="sDocumentoIdentidad" class="texto-bold label-formulario">Documento Identidad<span
              class="asterik-required">*</span></label>
          <input type="text" id="sDocumentoIdentidad" name="sDocumentoIdentidad" class="form-control" disabled
            [(ngModel)]="usuarioModel.sDocumentoIdentidad" required #sDocumentoIdentidad="ngModel">
        </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12">
        <label for="sNombresApellidos" class="texto-bold label-formulario">Nombres y Apellidos<span
            class="asterik-required">*</span></label>
        <input type="text" id="sNombresApellidos" name="sNombresApellidos" class="form-control" disabled
          [(ngModel)]="usuarioModel.sNombresApellidos" required #sNombresApellidos="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-8">
        <label for="sCelular">Celular</label>
        <div class="input-group">
            <input type="tel" name="sCelular" id="sCelular" pattern="[0-9]{9}" maxlength="9" [disabled]="isLoading" [(ngModel)]="usuarioModel.sCelular" class="form-control" placeholder="Ingrese su celular" #sCelular="ngModel" (keypress)="notCaracteresEspeciales($event)">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-8">
        <label for="sCorreo">Correo electrónico </label>
        <div class="input-group">
          <input type="email" name="sCorreo" id="sCorreo" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" [disabled]="isLoading" [(ngModel)]="usuarioModel.sCorreo" class="form-control" placeholder="Ingrese su correo electrónico: ejemplo@ejemplo.com" #sCorreo="ngModel" (keypress)="notCaracteresEspeciales($event)">
          <div class="input-group-append">
              <button class="btn btn-success" [disabled]="!sCorreo.valid && sCorreo.touched" type="button" (click)="sendEmail()">Enviar código</button>
          </div>
        </div>
        <div *ngIf="!sCorreo.valid && sCorreo.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
            Por favor verifique que sea un correo válido.
        </div>
      </div>
      <div class="form-group col-lg-4">
        <label for="sCodeCorreo" class="texto-bold label-formulario">Código Correo</label>
        <input type="text" id="sCodeCorreo" name="sCodeCorreo" class="form-control" [disabled]="isLoading"
          [(ngModel)]="usuarioModel.sCodeCorreo" placeholder="Ingrese su código" #sCodeCorreo="ngModel">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12">
        <div class="form-group form-check">
          <input type="checkbox" [(ngModel)]="usuarioModel.sActualizaPassword" class="form-check-input" id="sActualizaPassword"
            name="sActualizaPassword" #sActualizaPassword="ngModel">
          <label class="form-check-label" for="sesionCheck">Cambiar Contraseña</label>
        </div>
        <label for="sContraseniaActual">Contraseña Actual<span class="asterik-required">*</span></label>
        <input type="password" name="sContraseniaActual" id="sContraseniaActual" minlength="6" [disabled]="isLoading || !usuarioModel.sActualizaPassword" [(ngModel)]="usuarioModel.sContraseniaActual" class="form-control" #sContraseniaActual="ngModel">
        <div *ngIf="!sContraseniaActual.valid && sContraseniaActual.touched && !isLoading && usuarioModel.sActualizaPassword" class="alert alert-danger alert-danger-MTPE">
            Debe ingresar una contraseña, debe ser mínimo de 6 caracteres.
        </div>
      </div>
      <div class="form-group col-lg-12">
        <label for="sContraseniaNueva">Contraseña Nueva<span class="asterik-required">*</span></label>
        <input type="password" name="sContraseniaNueva" id="sContraseniaNueva" minlength="6" [disabled]="isLoading || !usuarioModel.sActualizaPassword" [(ngModel)]="usuarioModel.sContraseniaNueva" class="form-control" #sContraseniaNueva="ngModel">
        <div *ngIf="!sContraseniaNueva.valid && sContraseniaNueva.touched && !isLoading && usuarioModel.sActualizaPassword" class="alert alert-danger alert-danger-MTPE">
            Debe ingresar una contraseña, debe ser mínimo de 6 caracteres.
        </div>
      </div>
      <div class="form-group col-lg-12">
        <label for="sContraseniaRepite">Repetir Contraseña<span class="asterik-required">*</span></label>
        <input type="password" name="sContraseniaRepite" id="sContraseniaRepite" minlength="6" [disabled]="isLoading || !usuarioModel.sActualizaPassword" [(ngModel)]="usuarioModel.sContraseniaRepite" class="form-control" #sContraseniaRepite="ngModel">
        <div *ngIf="!sContraseniaRepite.valid && sContraseniaRepite.touched && !isLoading && usuarioModel.sActualizaPassword" class="alert alert-danger alert-danger-MTPE">
            Debe ingresar una contraseña, debe ser mínimo de 6 caracteres.
        </div>
      </div>
    </div>
    <div style="text-align: center;margin-bottom: 80px;">
      <button type="submit" class="col-lg-3 btn btn-success"
      [disabled]="actualizarPerfilForm.form.invalid || isLoading"
      style="background-color: #219653;">
      Guardar Datos
      <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
    </button>
    </div>
  </form>
</div>
<footer class="footer-principal footer-principal-verify">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
