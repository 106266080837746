import { SessionService } from './../../../services/session.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HelperService } from './../../../services/helper.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
  culUrl: string;
  isForeign: boolean;
  @ViewChild('modalCul') private modalCul;

  isNullUndefinesOrEmpty(value: string): boolean {
    return value === null || value === undefined || value === '';
  }
  constructor(private modal: NgbModal, private sessionService: SessionService, private helper: HelperService) { }

  ngOnInit(): void {
    this.isForeign = localStorage.getItem('foreign') === 'true' ? true : false;
  }
  fngoCul(): void {
    const extranjero = sessionStorage.getItem('esExtranjero');
    if (extranjero === '1') {
      Swal.fire(
        {
          title: 'CERTIFICADO ÚNICO LABORAL',
          text: 'Temporalmente, hasta la implementación progresiva de los sistemas y bases de datos correspondientes, solo las personas extranjeras registradas en la plataforma Empleos Perú con Carnet de Extranjería podrán emitir su certificado.',
          icon: 'info',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      ).then(() => {

      });
    } else {
      window.location.href = environment.baseCUL + 'cul.html?i=' + this.sessionService.getCookie('SID');
      /*this.modal.open(this.modalCul, { size: 'lg'}).result.then(
        (result) => {
          //window.location.href = environment.baseCUL + 'cul.html?i=' + this.sessionService.getCookie('SID');
        }
      );*/
    }
  }

  fngoBeca(): void{
    window.location.href = 'https://micarrera.trabajo.gob.pe';
  }

  fngoTrabajaExtranjero(): void{
    window.location.href = environment.basePortal + 'trabaja-extranjero';
  }

  fngoVerifica(): void {
    window.location.href = environment.baseCUL + 'Verificacion.html';
  }
  fngoCapacitacion(): void {
    window.location.href = 'http://mtpe.trabajo.gob.pe/capacitacionlaboral/';
  }
  fngoOferta(): void {
      window.location.href = environment.baseWcc + '/search-jobs';
  }
  fngoDirectorio(): void {
    window.location.href = environment.baseDirectorio;
  }
  fngoDirectorioBusqueda(): void {
    window.location.href = environment.baseDirectorioBusqueda;
  }
}
