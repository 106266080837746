import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aplicaciones-portal',
  templateUrl: './aplicaciones-portal.component.html',
  styleUrls: ['./aplicaciones-portal.component.css']
})
export class AplicacionesPortalComponent implements OnInit {

  autorizaBolsa = false;
  autorizaCrm = false;
  autorizaRenupse = false;
  autorizaPj = false;

  constructor(private sessionService: SessionService) { }

  ngOnInit() {
    let stringaccesosU = localStorage.getItem('aU');
    console.log(stringaccesosU);
    if (stringaccesosU !== null && stringaccesosU !== '' && stringaccesosU !== undefined){
      let aU = JSON.parse(stringaccesosU);
      this.autorizaBolsa = aU.autorizaBolsa;
      this.autorizaCrm = aU.autorizaCrm;
      this.autorizaRenupse = aU.autorizaRenupse;
      this.autorizaPj = aU.autorizaPj;
    }
  }

  fnGoBolsa() {
    window.location.href = environment.baseOrientadores;
  }

  fnGoRenupse() {
    const cookie = this.sessionService.getCookie('SID');
    window.location.href = environment.base + 'renupse-front?var=' + cookie;
  }

  fnGoCRM() {
    const cookie = this.sessionService.getCookie('SID');
    window.location.href = environment.base + 'crm-mtpe/#/home?var=' + cookie;
  }

  fnGoPj() {
    const cookie = this.sessionService.getCookie('SID');
    window.location.href = environment.base + 'pj-mtpe/#/pj?var=' + cookie;
  }

}
