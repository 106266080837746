<h3 class="accesibility-theme title-nuevo-usuario">Bienvenido/a al registro de empresas</h3>
<div [style.width]="maxWidth" class="nuevo-usuario-MTPE">
  <form (ngSubmit)="onSubmit()" #nuevaEmpresaForm="ngForm">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="!isLogueado" class="form-group">
          <label for="cboTipoDocumento" class="texto-bold label-formulario">Tipo Documento<span
              class="asterik-required">*</span></label>
          <select id="cboTipoDocumento" class="form-control" [(ngModel)]="empresaIdentidadModel.nInDocIdentidad"
            [ngModelOptions]="{standalone: true}">
            <option *ngFor="let tipoDocumento of tiposDocumento" [value]="+(tipoDocumento.value)">
              {{tipoDocumento.descripcion}}</option>
          </select>
        </div>
        <div *ngIf="!isLogueado" class="form-group">
          <label for="documentNumber">Número Documento <span class="asterik-required">*</span></label>
          <input type="text" id="documentNumber" name="documentNumber" class="form-control"
            [disabled]="isLoading" [(ngModel)]="empresaIdentidadModel.sNuDocIdentidad" required pattern="[A-Za-z0-9]{6,9}"
            placeholder="Ingrese su número documento" maxlength="9" #sNuDocIdentidad="ngModel"
            (keypress)="notCaracteresEspeciales($event)">
          <div *ngIf="!sNuDocIdentidad.valid && sNuDocIdentidad.touched && !isLoading"
            class="alert alert-danger alert-danger-MTPE">
            Ingrese el número de documento de identidad.
          </div>
        </div>
        <div *ngIf="!isLogueado" class="form-group">
          <label for="sFechaCaducidad">Fecha de caducidad <span class="asterik-required">*</span></label>
          <input type="date" class="form-control" [(ngModel)]="empresaIdentidadModel.sFechaCaducidad" min="2015-01-01"
            max="2040-01-01" [disabled]="isLoading || empresaIdentidadModel.bIndicadorNoCaducidad" name="sFechaCaducidad" id="sFechaCaducidad"
            #sFechaCaducidad="ngModel">
            <div class="form-group form-check">
              <input type="checkbox" [(ngModel)]="empresaIdentidadModel.bIndicadorNoCaducidad" class="form-check-input" id="bIndicadorNoCaducidad"
                name="bIndicadorNoCaducidad" #bIndicadorNoCaducidad="ngModel">
              <label class="form-check-label" for="sesionCheck">No caduca</label>
            </div>
        </div>
        <div class="form-group">
          <label for="cboTipoDocumento" class="texto-bold label-formulario">Ingrese su RUC<span
              class="accesibility-theme asterik-required">*</span></label>
          <input type="text" id="sRuc" name="sRuc" class="form-control" [disabled]="isLoading"
            [(ngModel)]="empresaModel.sRuc" required pattern="[0-9]{11}" placeholder="Ingrese su número de RUC"
            maxlength="11" #sRuc="ngModel" (keypress)="notCaracteresEspeciales($event)">
          <div *ngIf="!sRuc.valid && sRuc.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
            El número de RUC es obligatorio, númerico de 11 caracteres.
          </div>
        </div>
        <!--<div class="form-group">
          <label for="sCiiuPrincipal">CIIU <span class="asterik-required">*</span></label>
          <input type="text" id="sCiiuPrincipal" name="sCiiuPrincipal" class="form-control" [disabled]="isLoading"
            [(ngModel)]="empresaModel.sCiiuPrincipal" pattern="[0-9]{2,11}" placeholder="Ingrese su código de CIIU"
            #sCiiuPrincipal="ngModel" (keypress)="notCaracteresEspeciales($event)">
          <div *ngIf="!sCiiuPrincipal.valid && sCiiuPrincipal.touched && !isLoading" class="alert alert-danger alert-danger-MTPE">
            El CIIU debe ser númerico.
          </div>
        </div>-->
        <div class="form-group">
          <label for="sFechaRegistroSunat">Fecha de Inscripción Sunat <span class="asterik-required">*</span></label>
          <input type="date" class="form-control" [(ngModel)]="empresaModel.sFechaRegistroSunat" min="1900-01-01"
            max="2030-01-01" required [disabled]="isLoading" name="sFechaRegistroSunat" id="sFechaRegistroSunat"
            #sFechaRegistroSunat="ngModel">
            <div *ngIf="!sFechaRegistroSunat.valid && sFechaRegistroSunat.touched && !isLoading"
            class="alert alert-danger alert-danger-MTPE">
            Por favor ingrese la fecha de registro en Sunat de su empresa.
          </div>
        </div>
        <div *ngIf="isError" class="alert alert-danger alert-danger-MTPE" style="font-size: 11px;" role="alert">
          {{ mensajeError }}
        </div>
        <button type="submit" class="accesibility-theme col-lg-12 btn btn-success"
          [disabled]="nuevaEmpresaForm.form.invalid || isLoading"
          style="background-color: #219653;">
          Validar Información
          <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isLoading"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<footer class="footer-principal footer-principal-verify">
  <!--<div class="footer-callcenter">
    <p>Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
          <img class="accesibility-theme" style="width: 50%;"
              src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
          <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
              src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
              alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
  </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
