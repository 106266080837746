import { StatusResponse } from './../models/StatusResponse';
import { SmsRequest } from './../models/request/SmsRequest';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SmsService {
  baseUrl = environment.baseUrl;
  sendSMSUri = '/sms/enviar';
  sendSMSEmpresaUri = '/sms/enviar-empresa';
  sendSMSSidUri = '/sms/enviar-sid';
  constructor(private http: HttpClient) { }

  postSendSMS(request: SmsRequest): Observable<StatusResponse<boolean>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: max-line-length
      'X-Validate-Identity': sessionStorage.getItem('tokenIdentidad') === null ? sessionStorage.getItem('sTokenRP') : sessionStorage.getItem('tokenIdentidad')
    });
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendSMSUri, request, { headers: reqHeader });
  }
  postSendSMSEmpresa(request: SmsRequest): Observable<StatusResponse<boolean>>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: max-line-length
      'X-Validate-Identity': sessionStorage.getItem('tokenIdentidad') === null ? sessionStorage.getItem('tokenEmpresa') : sessionStorage.getItem('tokenIdentidad')
    });
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendSMSEmpresaUri, request, { headers: reqHeader });
  }
  postSendSmsSid(request: any): Observable<StatusResponse<boolean>> {
    return this.http.post<StatusResponse<boolean>>(this.baseUrl + this.sendSMSSidUri, request);
  }
}
