import { ResetPasswordLinRequest } from './../../../models/request/ResetPasswordLinkRequest';
import { ResetPasswordVerificarResponse } from './../../../models/response/ResetPasswordVerificarResponse';
import { UsuarioService } from './../../../services/usuario.service';
import { ResetPasswordVerificarRequest } from './../../../models/request/ResetPasswordVerificarRequest';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from './../../../services/login.service';
import Swal from 'sweetalert2';
import { IpServiceService } from './../../../ip-service.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-restablecer-contrasenia',
  templateUrl: './restablecer-contrasenia.component.html',
  styleUrls: ['./restablecer-contrasenia.component.css']
})
export class RestablecerContraseniaComponent implements OnInit {
  reestablecerPasswordModel: ResetPasswordVerificarRequest = {
    sIdDocumento: 10080399,
    sNumeroDocumento: ''
  };
  isVerifyUsuario = false;
  verifyResetPasswordResponse: ResetPasswordVerificarResponse = {
    token: {},
    sEmail: '',
    sNumeroCelular: '',
    bExiste: false
  };
  resetPasswordLinkModel: ResetPasswordLinRequest = {
    nIdTipoContacto: 0,
    nInDocIdentidad: 0,
    sNuDocIdentidad: ''
  }
  tiposDocumento: any[];
  response: any;
  mensajeError: string;
  isError = false;
  isLoading = false;
  isExisteEmail = false;
  isExisteCelular = false;
  selectedCelular = false;
  selectedEmail = false;
  selectedNone = false;
  enviarValido = false;

  isNullUndefinesOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  constructor(private usuarioService: UsuarioService, private routes: Router, private ip: IpServiceService) { }

  ngOnInit(): void {
    this.tiposDocumento = [
      {
        value: 0,
        descripcion: '-- Seleccione un tipo de documento --'
      },
      {
        value: 10080399,
        descripcion: 'DNI'
      },
      {
        value: 10080305,
        descripcion: 'CE'
      },
      {
        value: 10080306,
        descripcion: 'PTP'
      },
      {
        value: 10080307,
        descripcion: 'CPP'
      }
    ];
  }
  changeTipoDocumento(): void{
    this.isVerifyUsuario = false;
  }
  getTipoContacto(): number{
    if (this.selectedCelular){
      return 10140301;
    }
    if (this.selectedEmail) {
      return 10140302;
    }
  }
  changeCheckBoxCelular(value: boolean): void {
    if (value){
      this.selectedEmail = !value;
      this.selectedNone = !value;
      this.enviarValido = true;
    }
    if (!this.selectedCelular && !this.selectedEmail && !this.selectedNone){
      this.enviarValido = false;
    }
  }
  changeCheckBoxEmail(value: boolean): void{
    if (value){
      this.selectedCelular = !value;
      this.selectedNone = !value;
      this.enviarValido = true;
    }
    if (!this.selectedCelular && !this.selectedEmail && !this.selectedNone){
      this.enviarValido = false;
    }
  }
  changeCheckBoxNone(value: boolean): void{
    if (value){
      this.selectedCelular = !value;
      this.selectedEmail = !value;
      this.enviarValido = true;
    }
    if (!this.selectedCelular && !this.selectedEmail && !this.selectedNone){
      this.enviarValido = false;
    }
  }
  enviarSolicitud(): void{
    if (!this.selectedEmail && !this.selectedNone){
      Swal.fire(
        {
          title: 'Alerta!',
          text: 'Debe escoger una de las opciones.',
          icon: 'warning',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }
      );
      return;
    }
    if (this.selectedNone){
      Swal.fire(
        {
          title: 'Éxito!',
          text: 'Para recuperar su contraseña tenga a la mano su documento de identidad. ¿Desea continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#B0261C',
          cancelButtonColor: '#000',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
        }
      ).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.setItem('sTokenRP', this.verifyResetPasswordResponse.token.token);
          this.routes.navigate(['/nuevo-usuario/' + sessionStorage.getItem('sTokenRP').toString()]);
        }
      });
    }else {
      this.resetPasswordLinkModel.nIdTipoContacto = this.getTipoContacto();
      this.resetPasswordLinkModel.nInDocIdentidad = this.reestablecerPasswordModel.sIdDocumento;
      this.resetPasswordLinkModel.sNuDocIdentidad = this.reestablecerPasswordModel.sNumeroDocumento;
      this.usuarioService.postGenerarLinkResetPassword(this.resetPasswordLinkModel, this.verifyResetPasswordResponse.token).subscribe(
        response => {
          if (response.succes){
            console.log(response);
            Swal.fire(
              {
                title: 'Éxito!',
                text: 'Solicitud enviada con éxito.',
                icon: 'success',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            ).then(() => {
                this.isLoading = false;
                this.isError = false;
                this.routes.navigate(['']);
            });
          }else {
            Swal.fire(
              {
                title: 'Alerta!',
                text: response.description,
                icon: 'warning',
                confirmButtonColor: '#B0261C',
                confirmButtonText: 'Aceptar'
              }
            ).then(() => {
                this.isLoading = false;
                this.isError = false;
            });
          }
        },
        error => {
          Swal.fire({
            title: 'Error!',
            text: 'Ha ocurrido un error, vuelva a intentarlo en unos minutos.',
            icon: 'error',
            confirmButtonColor: '#B0261C',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.value){
              this.isLoading = false;
              this.isError = false;
            }
          });
        }
      );
    }
  }
  onSubmit(): void {
    this.isLoading = true;
    this.usuarioService.getVerifyResetPassword(this.reestablecerPasswordModel).subscribe(
      response => {
        if (response.succes) {
          this.isLoading = false;
          this.isError = false;
          this.verifyResetPasswordResponse = response.data;
          this.isVerifyUsuario = this.verifyResetPasswordResponse.bExiste;
          if (!this.isNullUndefinesOrEmpty(this.verifyResetPasswordResponse.sEmail)) {
            this.isExisteEmail = true;
          }
          if (!this.isNullUndefinesOrEmpty(this.verifyResetPasswordResponse.sNumeroCelular)) {
            console.log(this.verifyResetPasswordResponse.sNumeroCelular);
            this.isExisteCelular = true;
          }
          this.selectedCelular = false;
          this.selectedEmail = false;
          this.selectedNone = false;
          Swal.fire(
            {
              title: 'Éxito!',
              text: 'Solicitud verificada con éxito, seleccione una de las opciones para recuperar su contraseña.',
              icon: 'success',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          ).then(() => {
              this.isLoading = false;
              this.isError = false;
          });
        } else {
          Swal.fire(
            {
              title: 'Alerta!',
              text: response.description,
              icon: 'warning',
              confirmButtonColor: '#B0261C',
              confirmButtonText: 'Aceptar'
            }
          ).then(() => {
              this.isLoading = false;
              this.isError = false;
          });
        }
      },
      error => {
        Swal.fire({
          title: 'Error!',
          text: 'Ha ocurrido un error, vuelva a intentarlo en unos minutos.',
          icon: 'error',
          confirmButtonColor: '#B0261C',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.value){
            console.log(error);
            this.isLoading = false;
            this.isError = false;
          }
        });
      });
  }
}
