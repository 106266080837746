<div class="row margin-container container-responsive accesibility-theme" style="margin-top: 70px;">
  <div class="col-lg-6">
    <p class="header-titel-mtpe header-container">Empleos Perú</p>
    <p style="margin-top: -10px;">
      <a href="https://www.facebook.com/MTPEPERU" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/FB.png" />
      </a>
      <a href="https://instagram.com/mtpe_peru?igshid=YmMyMTA2M2Y=" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IG.png" />
      </a>
      <a href="https://twitter.com/MTPE_Peru?t=ucOn7RjBVRUvDKO9hBqnKQ&s=08" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/TW.png" />
      </a>
      <a href="https://www.linkedin.com/company/mtpe-peru/" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/IN.png" />
      </a>
      <a href="https://www.tiktok.com/@mtpeperu?lang=es" target="_blank">
        <img style="width: 25px; margin-right: 5px;" src="./assets/images/icons/redes/Tiktok.png" />
      </a>
    </p>
  </div>
  <div class="col-lg-6">
    <div class="row header-container">
      <div class="col-lg-12">
        <!--<img src="./assets/images/banner/central-numero.png" style="width: auto;" class="logo-telefono pull-right"
          alt="Comunícate con nosotros llamando al 0800 71707" />-->
      </div>
    </div>
    <div class="row header-container">
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickBolsaTrabajo()">Bolsa de
          Trabajo</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCul()">Certificado
          Único Laboral</button>
      </div>
      <div class="col-lg-4">
        <button type="button" class="btn btn-sm btn-danger col-sm-12"
          style="background-color: #CC0000; font-weight: bold;" (click)="fnclickCapacitate()">Capacítate</button>
      </div>
    </div>
  </div>
</div>
<div class="row" style="margin-top: 50px; margin-right: 15px; margin-left: 15px;">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <div class="row" style="padding: 20px;">
      <div class="col-lg-5">
        <p style="color: #CC0000; font-weight: 600; font-size: 26px;">Oportunidades laborales en Quebec</p>
        <p>
          El Ministerio de Trabajo y Promoción del Empleo en coordinación con el Gobierno de Quebec,
          presentan a la ciudadanía la convocatoria a las ofertas laborales vigentes en Quebec, que se
          encuentran a disposición hasta el 26 de setiembre del 2022.
        </p>
      </div>
      <div class="col-lg-7">
        <a href="https://www.quebecentete.com/es/trabajar/misiones-de-reclutamiento/jqameriquelatine-2022/"
          style="border: none" target="_blank">
          <img src="./assets/images/banner/exterior_2.png" alt="" style="width: 100%;">
        </a>
      </div>
    </div>
  </div>
  <div class="col-lg-3"></div>
</div>
<div class="row" style="margin-right: 15px; margin-left: 15px;">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <div class="row" style="padding: 20px;">
      <div class="col-lg-12">
        <p>
          Se cuenta con vacantes disponibles en los sectores de: ingeniería, manufactura, salud y servicios
          sociales, tecnología de la información y transformación de alimentos.
        </p>
        <p style="color: #CC0000; font-weight: 600;">
          Requisitos generales:
        </p>
        <ul>
          <li style="color: #CC0000;"><span style="color: #000;">Diploma técnico o universitario en el área de la vacante.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">Experiencia profesional relacionada con los requisitos de la oferta de empleo.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">Nivel de francés: de principiante a avanzado, dependiendo del cargo.</span></li>
        </ul>
        <p style="color: #CC0000; font-weight: 600;">
          Consideraciones:
        </p>
        <ul>
          <li style="color: #CC0000;"><span style="color: #000;">La participación es completamente gratuita y sin límite de edad.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">Sólo los candidatos seleccionados serán contactados para entrevista.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">El candidato seleccionado será guiado por el empleador en el proceso de inmigración.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">Las condiciones laborales de los trabajadores extranjeros son las mismas que las de los
            canadienses.</span></li>
          <li style="color: #CC0000;"><span style="color: #000;">Las vacantes de empleo son temporales, se debe revisar con detalle cada una de las
            vacantes de interés.</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-lg-3"></div>
</div>
<div class="row" style="margin-right: 15px; margin-left: 15px; margin-bottom: 30px;">
  <div class="col-lg-4"></div>
  <div class="col-lg-4" style="text-align: center;">
    <button class="btn btn-sm btn-danger" (click)="fngoTrabajExtranjero()">Postula aquí</button>
  </div>
  <div class="col-lg-4"></div>
</div>
<footer class="footer-principal" style="margin-top: 5px;">
  <!--<div class="footer-callcenter">
    <p class="accesibility">Canal de atención y consultas: 0800 71 707</p>
  </div>-->
  <div class="container" style="margin-bottom: 10px;">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme" style="width: 50%;" src="./assets/images/icons/logo/Pastilla-MTPE@3x.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos text-center">
        <img class="accesibility-theme" style="width: 50%;"
          src="./assets/images/icons/logo/logo_gobierno_del_peru.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 footer-logos">
        <img class="accesibility-theme footer-logo-rigth" style="width: 50%;"
          src="./assets/images/icons/logo/BICENTENARIO-DEL-PERU_LOGO.png"
          alt="Logo Ministerio de Trabajo y Promoción del Empleo">
      </div>
    </div>
    <!--<img class="col-sm-12 col-md-4 col-lg-2 pull-right img-hide" src="/assets/images/icons/logo/EL-PERU-PRIMERO-LOGOTIPO@3x.png" alt="El peru primero">-->
  </div>
</footer>
